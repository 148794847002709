import React, { useState, useContext } from "react";
import {
  Button,
  Checkbox,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  useToast,
  Link,
} from "@chakra-ui/react";
import { Helmet } from "react-helmet-async";
import "./MarketplaceRegister.css";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { Clientauthcontext } from "./context/Clientauthcontext";

function MarketplaceRegister() {
  const { registerclient } = useContext(Clientauthcontext);

  const [show, setShow] = useState(false);

  const [rshow, rsetShow] = useState(false);

  const [isChecked, setIsChecked] = useState(true);

  const [isLoading, setisLoading] = useState(false);

  const toast = useToast();

  const navigate = useNavigate();

  const [inputs, setInputs] = useState({
    client_email: "",
    client_password: "",
    client_repeat_password: "",
    client_name: "",
  });

  const handlechange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handle_signup = async (e) => {
    e.preventDefault();
    setisLoading(true);
    try {
      if (
        inputs.client_email === "" ||
        inputs.client_password === "" ||
        inputs.client_name === ""
      ) {
        toast({
          title: "Skriv fejl",
          description: "E-mail- eller adgangskodefeltet er tomt",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
        setisLoading(false);
      } else if (inputs.client_password !== inputs.client_repeat_password) {
        toast({
          title: "Skriv fejl",
          description: "confirmed password wrong",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
        setisLoading(false);
      } else {
        const Data = {
          client_name: inputs.client_name,
          client_email: inputs.client_email,
          client_password: inputs.client_password,
        };
        const res = await registerclient(Data);

        if (res.status === 200) {
          navigate("/opret-konto/otp", {
            state: { client_user_id: res.data.client_user_id },
          });
          setisLoading(false);
        }
      }
    } catch (err) {
      if (err.response.status === 404) {
        toast({
          title: "Login fejl",
          description: err.response.data.message,
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      } else if (err.response.status === 500) {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et øjeblik",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      setisLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Bookinghero - Tilmeld</title>
        <meta
          name="description"
          content="Opret en konto på Bookinghero Marketplace og få adgang til hundreder af skønheds- og velværevirksomheder og behandlinger."
        />
        <link rel="canonical" href="/opret-konto" />
      </Helmet>
      <div className="RegisterClient">
        <div className="Left">
          <Link href={"/"} className="logo_container">
            <img src="../assets/Logo/logo.png" alt="" className="logo"></img>
          </Link>
          <div className="Header">Opret en gratis profil</div>
          <div className="Subheader">
            Indtast venligst dine personlige oplysninger
          </div>
          <div className="Form">
            <div>
              <div className="Label">E-mail</div>
              <Input
                name="client_email"
                required
                variant="filled"
                size="lg"
                className="Input"
                type="email"
                onChange={handlechange}
              />
            </div>
            <div>
              <div className="Label">Navn</div>
              <Input
                name="client_name"
                required
                variant="filled"
                size="lg"
                className="Input"
                type="text"
                onChange={handlechange}
              />
            </div>
            <div>
              <div className="Label">Adgangskode</div>
              <InputGroup className="inputGroup">
                <Input
                  name="client_password"
                  required
                  variant="filled"
                  size="lg"
                  className="Input"
                  type={show ? "text" : "password"}
                  onChange={handlechange}
                />
                <InputRightElement width="4.5rem">
                  <Button
                    h="fit-content"
                    size="sm"
                    className="showHide"
                    onClick={() => setShow(!show)}
                  >
                    <Icon
                      className="iconVise"
                      as={show ? AiFillEye : AiFillEyeInvisible}
                      h={5}
                      w={5}
                    />
                  </Button>
                </InputRightElement>
              </InputGroup>
            </div>
            <div>
              <div className="Label">Gentag adgangskode</div>
              <InputGroup className="inputGroup">
                <Input
                  name="client_repeat_password"
                  required
                  variant="filled"
                  size="lg"
                  className="Input"
                  type={rshow ? "text" : "password"}
                  onChange={handlechange}
                />
                <InputRightElement width="4.5rem">
                  <Button
                    h="fit-content"
                    size="sm"
                    className="showHide"
                    onClick={() => rsetShow(!rshow)}
                  >
                    <Icon
                      className="iconVise"
                      as={rshow ? AiFillEye : AiFillEyeInvisible}
                      h={5}
                      w={5}
                    />
                  </Button>
                </InputRightElement>
              </InputGroup>
            </div>
          </div>
          <div className="termsConditions">
            <Checkbox
              isChecked={isChecked}
              onChange={() => {
                setIsChecked(!isChecked);
              }}
              className="checkBox"
            >
              <span className="terms_conditions_text">
                Jeg har læst og accepterer{" "}
                <Link href={"/vilkar"} target="_blank" className="Link">
                  vilkår og betingelser <ExternalLinkIcon className="Icon" />
                </Link>
              </span>
            </Checkbox>
          </div>
          {isChecked ? (
            <>
              {!isLoading ? (
                <>
                  <Button className="btnSubmit" onClick={handle_signup}>
                    Opret min profil
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    className="btnSubmit"
                    isLoading
                    loadingText="Indlæser"
                  >
                    Opret min profil
                  </Button>
                </>
              )}
            </>
          ) : (
            <>
              <Button className="btnSubmit" disabled>
                Opret min profil
              </Button>
            </>
          )}
          <div className="loginText">
            Har du allerede en profil?
            <Link href={"/log-paa"} className="Link">
              Log på
            </Link>
          </div>
        </div>
        <div className="Right">
          <div className="Imagecontainer">
            <div className="Headerscontainer">
              <div className="Header">DIN GENVEJ TIL VELVÆRE</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MarketplaceRegister;
