import React from "react";
import "./MarketplaceForgotPass.css";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Link,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function MarketplaceForgotPass() {
  const [isLoading, setisLoading] = useState(false);

  const navigate = useNavigate();

  const toast = useToast();

  const [inputs, setInputs] = useState({
    client_email: "",
  });

  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handle_submit = async (e) => {
    e.preventDefault();
    setisLoading(true);
    const data = {
      client_email: inputs.client_email,
    };
    try {
      // Check if inputs are empty
      if (inputs.client_email === "") {
        toast({
          title: "Skriv fejl",
          description: "Du mangler at udfylde et område",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
        setisLoading(false);
      } else {
        const res = await axios.post("client/web/forgot-password", data);

        if (res.status === 200 || res.status === 201) {
          navigate("/logind/glemt-kodeord/otp", {
            state: { client_user_id: res.data.client_user_id },
          });
          setisLoading(false);
        }
      }
    } catch (err) {
      if (err.response.status === 404) {
        toast({
          title: "Submit fejl",
          description: err.response.data.message,
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      } else if (err.response.status === 500) {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et øjeblik",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      setisLoading(false);
    }
  };

  return (
    <>
      <div className="forgot-pass">
        <div className="Left">
          <Link href={"/"} className="logo_container">
            <img src="../assets/Logo/logo.png" alt="" className="logo"></img>
          </Link>
          <div className="Header">Har du glemt dit kodeord?</div>
          <div className="Subheader">
            Indtast venlist din e-mail, så vi kan sende dig en ny pinkode.
          </div>
          <FormControl className="emailInput">
            <FormLabel>E-mail</FormLabel>
            <Input
              name="client_email"
              type="email"
              size="lg"
              variant="filled"
              onChange={handleChange}
            />
          </FormControl>
          {!isLoading ? (
            <>
              <Button className="Submitbtn" onClick={handle_submit}>
                Bekræft venligst
              </Button>
            </>
          ) : (
            <>
              <Button className="Submitbtn" isLoading loadingText="Indlæser">
                Bekræft venligst
              </Button>
            </>
          )}
        </div>
        <div className="Right">
          <div className="Imagecontainer">
            <div className="Headerscontainer">
              <div className="Header">DIN GENVEJ TIL VELVÆRE</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MarketplaceForgotPass;
