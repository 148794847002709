import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Box, Flex, IconButton, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { MdPlaylistRemove } from "react-icons/md";
import { ThreeDots } from "react-loader-spinner";

function WeekPicker({
  appointment,
  setAppointment,
  slots,
  currentDate,
  setCurrentDate,
  refresh,
  setRefresh,
  getPrevious,
  isLoading,
}) {
  const [days, setDays] = useState();
  const [year, setYear] = useState();
  const [month, setMonth] = useState();
  const [lastDate, setLastDate] = useState();
  const [firstDate, setFirstDate] = useState();
  const [times, setTimes] = useState();
  const [currentDayInText, setCurrentDayInText] = useState("");
  const [selecteTimeslotIndex, setSelectedTimeslotIndex] = useState(null);

  const getCurrentDate = (d) => {
    const currentDatee = new Date(Object.keys(d)[0]);
    const year = currentDatee.getFullYear();
    const month = String(currentDatee.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(currentDatee.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };
  const calculateEndTime = (startTime, duration) => {
    const [startHour, startMinute] = startTime.split(":").map(Number);

    // Calculate endTime by adding duration (in minutes)
    const endMinute = (startHour * 60 + startMinute + duration) % 1440;
    const endHour = Math.floor(endMinute / 60);
    const formattedEndMinute = (endMinute % 60).toString().padStart(2, "0");

    return `${endHour.toString().padStart(2, "0")}:${formattedEndMinute}`;
  };
  useEffect(() => {
    let days_ = [];
    for (let i = 0; i < 7; i++) {
      const dateObject = new Date(Object.keys(slots[i])[0]);
      if (i === 0) {
        setFirstDate(getCurrentDate(slots[i]));
        setMonth(dateObject.toLocaleDateString("da-DK", { month: "long" }));
        setYear(dateObject.getFullYear());
        setTimes(slots[i][Object.keys(slots[i])[0]]);
        setCurrentDayInText(
          dateObject.toLocaleDateString("da-DK", { weekday: "long" })
        );
      }
      if (i === 6) {
        setLastDate(getCurrentDate(slots[i]));
      }
      const dateInText = dateObject.toLocaleDateString("da-DK", {
        weekday: "long",
      });

      const capitalizedDateInText =
        dateInText.charAt(0).toUpperCase() + dateInText.slice(1);

      const day = dateObject.toLocaleDateString("en-US", { day: "numeric" });
      days_ = [
        ...days_,
        {
          id: i + 1,
          date: day,
          day: capitalizedDateInText.slice(0, 3),
          active: i === 0,
          available: slots[i][Object.keys(slots[i])[0]].length > 0,
          year: dateObject.getFullYear(),
          month: dateObject.toLocaleDateString("en-US", { month: "long" }),
        },
      ];
    }
    setDays(days_);
  }, [refresh, currentDate]);

  return (
    <>
      <Box width={"100%"} height={"fit-content"}>
        <Flex
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          marginBottom={"15px"}
        >
          <Box>
            <Text fontWeight={"600"} fontSize={"lg"}>
              {month && month} {year && year}
            </Text>
          </Box>
          <Flex flexDirection={"row"} gap={"10px"} alignItems={"center"}>
            <IconButton
              aria-label="next week"
              icon={<ChevronLeftIcon boxSize={"25px"} />}
              bg={"transparent"}
              onClick={() => {
                getPrevious(firstDate);
              }}
            />
            <IconButton
              aria-label="next week"
              icon={<ChevronRightIcon boxSize={"25px"} />}
              bg={"transparent"}
              onClick={() => {
                setCurrentDate(lastDate);
              }}
            />
          </Flex>
        </Flex>
        <Flex
          flexDirection={"row"}
          gap={"30px"}
          alignItems={"center"}
          marginBottom={"30px"}
        >
          {days &&
            days.map((day, index) => (
              <Box
                textAlign={"center"}
                key={index}
                onClick={() =>
                  setCurrentDate(`${day.year}-${day.month}-${day.date}`)
                }
              >
                <Flex
                  borderRadius={"50%"}
                  border={"1px solid lightgray"}
                  width={"70px"}
                  height={"70px"}
                  cursor={"pointer"}
                  _hover={{
                    backgroundColor: !day.available ? "" : "lightgrey",
                  }}
                  mb={"5px"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  textDecoration={day.available ? "" : "line-through"}
                  textDecorationThickness={"2px"}
                  color={day.active ? "white" : day.available ? "" : "gray"}
                  backgroundColor={day.active && "#23a994"}
                >
                  <Text fontWeight={"bold"} fontSize={"2xl"}>
                    {day.date}
                  </Text>
                </Flex>
                <Box>
                  <Text fontWeight={"500"} fontSize={"lg"}>
                    {day.day}
                  </Text>
                </Box>
              </Box>
            ))}
        </Flex>
        {isLoading ? (
          <Flex height={"30vh"} justifyContent={"center"} alignItems={"center"}>
            <ThreeDots
              visible={true}
              height="70"
              width="80"
              color="#23a994"
              radius="9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </Flex>
        ) : times && times.length > 0 ? (
          times.map((time, index) => {
            return (
              <Box
                width={"100%"}
                height={"fit-content"}
                key={index}
                onClick={() => {
                  setSelectedTimeslotIndex(index);
                  setAppointment((prevAppointment) => ({
                    ...prevAppointment,
                    schedule: {
                      ...prevAppointment.schedule,
                      startTime: time.Time.slice(0, 5),
                      endTime: calculateEndTime(
                        time.Time.slice(0, 5),
                        parseInt(time.Duration.slice(1, 3)) * 60 +
                          parseInt(time.Duration.slice(3, 5), 10)
                      ),
                      year: year,
                      month: month,
                      dayInText: currentDayInText,
                      day:
                        currentDate.slice(-2)[0] === "-"
                          ? `0${currentDate.slice(-1)}`
                          : currentDate.slice(-2),
                    },
                  }));
                }}
              >
                <Box
                  width={"95%"}
                  height={"fit-content"}
                  border={
                    selecteTimeslotIndex === index
                      ? "3px solid #23a994"
                      : "1px solid lightgray"
                  }
                  borderRadius={"12px"}
                  paddingX={"25px"}
                  paddingY={"18px"}
                  _hover={{ backgroundColor: "whitesmoke" }}
                  cursor={"pointer"}
                  marginBottom={"15px"}
                >
                  <Text fontSize={"lg"} fontWeight={"500"}>
                    {time.Time.slice(0, 5)}
                  </Text>
                </Box>
              </Box>
            );
          })
        ) : (
          <Flex
            justifyContent={"center"}
            alignItems={"center"}
            direction={"column"}
            opacity={"0.5"}
            marginRight={"20%"}
            marginTop={"10%"}
          >
            <Box fontSize={"5xl"}>
              <MdPlaylistRemove />
            </Box>
            <Box fontSize={"lg"} fontWeight={"bold"} marginTop={"20px"}>
              Ingen ledige tidsrum for denne dato
            </Box>
          </Flex>
        )}
      </Box>
    </>
  );
}

export default WeekPicker;
