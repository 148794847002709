import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Flex,
  Text,
  IconButton,
  Stack,
  Collapse,
  Icon,
  Link,
  VStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useDisclosure,
  Image,
  Menu,
  MenuButton,
  Avatar,
  MenuList,
  MenuItem,
  MenuDivider,
  useToast,
} from "@chakra-ui/react";

import {
  HamburgerIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
// Cookies config
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
import { Clientauthcontext } from "./context/Clientauthcontext";
import { useNavigate } from "react-router-dom";
import { MdAccountCircle, MdLogin, MdLogout, MdSettings } from "react-icons/md";
import { BiSupport } from "react-icons/bi";
import { TiUserAdd } from "react-icons/ti";
import "./MarketplaceNavbar.css";
import axios from "axios";
import { AiOutlineHeart } from "react-icons/ai";
import { FaCalendarAlt } from "react-icons/fa";

export default function MarketplaceNavbar() {
  const { logoutclient } = useContext(Clientauthcontext);
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onToggle } = useDisclosure();
  const [auth, set_auth] = useState(false);
  const [refresh_counter, set_refresh_counter] = useState(0);

  useEffect(() => {
    if (Cookies.get("user_id") && Cookies.get("auth_token")) {
      const auth_token = getDecryptedCookie();
      const client_id = Cookies.get("user_id");
      if (auth_token === client_id) {
        set_auth(true);
      } else {
        set_auth(false);
      }
    } else {
      set_auth(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh_counter]);

  const getDecryptedCookie = () => {
    const encryptedValue = Cookies.get("auth_token");
    const decryptedValue = decryptCookieValue(encryptedValue); // Replace with your decryption logic
    return decryptedValue;
  };

  const decryptCookieValue = (encryptedValue) => {
    const secretKey = "bookinghero";
    const bytes = CryptoJS.AES.decrypt(encryptedValue, secretKey);
    const decryptedValue = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedValue;
  };

  const handle_logout = async (e) => {
    e.preventDefault();
    try {
      const res = await logoutclient();
      if (res.status === 200) {
        set_refresh_counter(refresh_counter + 1);
        navigate("/");
      }
    } catch (err) {
      toast({
        title: "Server Fejl",
        description: "prøv venligst igen om nogle minutter",
        position: "top-right",
        isClosable: true,
        status: "error",
      });
    }
  };
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      setIsScrolled(scrollTop > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [client_name, set_client_name] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`/client/web/info/`);
        if (res.status === 200) {
          // Fill client data
          set_client_name(res.data.client_name);
        }
      } catch (err) {
        if (err.response.status === 404) {
          toast({
            title: "Login fejl",
            description: err.response.data.message,
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        } else if (err.response.status === 500) {
          toast({
            title: "Server Fejl",
            description: "Prøv venligst igen om et øjeblik",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        }
        setisLoading(false);
      }
    };
    if (Cookies.get("user_id") && Cookies.get("auth_token")) {
      fetchData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={isScrolled ? "isScrolled" : "navbar"}>
      <Flex
        // bg={useColorModeValue("white", "gray.800")}
        bg={"transparent"}
        color={useColorModeValue("gray.600", "white")}
        minH={"90px"}
        maxH={"90px"}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderColor={useColorModeValue("gray.200", "gray.900")}
        align={"center"}
        flexDirection={{ base: "row-reverse", md: "row" }}
      >
        <Flex
          flex={{ base: 1, md: "auto" }}
          ml={{ base: -2 }}
          display={{ base: "flex", md: "none" }}
          flexDirection={"row-reverse"}
          position={"relative"}
          zIndex={80}
        >
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<HamburgerIcon />}
              variant="outline"
            />
            <MenuList>
              {auth ? (
                <>
                  <Link
                    href={"/profil"}
                    _hover={{
                      textDecoration: "none",
                    }}
                  >
                    <MenuItem icon={<MdAccountCircle />}>Profil</MenuItem>
                  </Link>
                  <Link
                    href={"/favoritter"}
                    _hover={{
                      textDecoration: "none",
                    }}
                  >
                    <MenuItem icon={<AiOutlineHeart />}>Favoritter</MenuItem>
                  </Link>
                  <Link
                    href={"/aftaler"}
                    _hover={{
                      textDecoration: "none",
                    }}
                  >
                    <MenuItem icon={<FaCalendarAlt />}>Aftaler</MenuItem>
                  </Link>
                  <Link
                    href={"/adgangskode"}
                    _hover={{
                      textDecoration: "none",
                    }}
                  >
                    <MenuItem icon={<MdSettings />}>Adgangskode</MenuItem>
                  </Link>
                  {/*   <Link
                    href={"/support"}
                    _hover={{
                      textDecoration: "none",
                    }}
                  >
                    <MenuItem icon={<BiSupport />}>Support</MenuItem>
                  </Link>*/}
                  <MenuDivider />
                  <MenuItem
                    icon={<MdLogout />}
                    color={"red.500"}
                    onClick={handle_logout}
                  >
                    Log ud
                  </MenuItem>
                </>
              ) : (
                <>
                  <Link
                    href={"/log-paa"}
                    _hover={{
                      textDecoration: "none",
                    }}
                  >
                    <MenuItem icon={<MdLogin />}>Log på</MenuItem>
                  </Link>
                  <Link
                    href={"/opret-konto"}
                    _hover={{
                      textDecoration: "none",
                    }}
                  >
                    <MenuItem icon={<TiUserAdd />}>Tilmeld</MenuItem>
                  </Link>
                  {/*  <Link
                    href={"/support"}
                    _hover={{
                      textDecoration: "none",
                    }}
                  >
                    <MenuItem icon={<BiSupport />}>Support</MenuItem>
                  </Link>*/}
                </>
              )}
            </MenuList>
          </Menu>
        </Flex>
        <Flex flex={{ base: 1 }} justify={{ base: "center", md: "start" }}>
          <Link href={"/"}>
            <Image
              src={process.env.PUBLIC_URL + "/assets/Logo/logo.webp"}
              alt="Logo"
              maxW="200px"
              minW="100px"
            />
          </Link>
          <Flex
            display={{ base: "none", lg: "flex" }}
            ml={10}
            mt={2}
            alignItems="center"
          >
            {auth ? (
              <>
                <AuthDesktopNav />
              </>
            ) : (
              <>
                <DesktopNav />
              </>
            )}
          </Flex>
        </Flex>
        {auth ? (
          <>
            <Stack
              flex={{ base: 1, md: 0 }}
              justify={"flex-end"}
              direction={"row"}
              spacing={6}
              mt={2}
            >
              <Menu>
                <MenuButton
                  px={2}
                  py={3}
                  rounded={"full"}
                  transition="all 0.2s"
                  borderRadius="30px"
                  borderWidth="2px"
                  marginRight={"50px"}
                  minW={"max-content"}
                  display={{ base: "none", md: "inline-flex" }}
                  fontSize={"md"}
                  fontWeight={600}
                  color={"black"}
                  borderColor={"#23a99584"}
                  _hover={{ bg: "gray.100" }}
                  _expanded={{ bg: "transparent" }}
                  _focus={{ boxShadow: "transparent" }}
                  background={"white"}
                >
                  <Flex alignItems={"center"}>
                    <Avatar
                      name={client_name}
                      src="https://bit.ly/tioluwani-kolawole"
                      marginRight={"10px"}
                      size="sm"
                      bg={"gray.200"}
                      color={"gray.700"}
                    />
                    <Text>{client_name}</Text>
                    <ChevronDownIcon marginLeft={2} />
                  </Flex>
                </MenuButton>
                <MenuList px={2} color={"black"}>
                  <Link
                    href={"/profil"}
                    _hover={{
                      textDecoration: "none",
                    }}
                  >
                    <MenuItem icon={<MdAccountCircle />}>Profil</MenuItem>
                  </Link>
                  <Link
                    href={"/favoritter"}
                    _hover={{
                      textDecoration: "none",
                    }}
                  >
                    <MenuItem icon={<AiOutlineHeart />}>Favoritter</MenuItem>
                  </Link>
                  <Link
                    href={"/aftaler"}
                    _hover={{
                      textDecoration: "none",
                    }}
                  >
                    <MenuItem icon={<FaCalendarAlt />}>Aftaler</MenuItem>
                  </Link>
                  <Link
                    href={"/adgangskode"}
                    _hover={{
                      textDecoration: "none",
                    }}
                  >
                    <MenuItem icon={<MdSettings />}>Adgangskode</MenuItem>
                  </Link>
                  <MenuDivider />
                  <MenuItem
                    icon={<MdLogout />}
                    color={"red.500"}
                    onClick={handle_logout}
                  >
                    Log ud
                  </MenuItem>
                </MenuList>
              </Menu>
            </Stack>
          </>
        ) : (
          <>
            <Stack
              flex={{ base: 1, md: 0 }}
              justify={"flex-end"}
              direction={"row"}
              spacing={6}
              mt={2}
            >
              <Menu>
                <MenuButton
                  px={1}
                  py={3}
                  rounded={"full"}
                  transition="all 0.2s"
                  borderRadius="30px"
                  borderWidth="2px"
                  marginRight={"50px"}
                  minW="120px"
                  display={{ base: "none", md: "inline-flex" }}
                  fontSize={"md"}
                  fontWeight={600}
                  color={"black"}
                  borderColor={"#23a99584"}
                  _hover={{ bg: "gray.100" }}
                  _expanded={{ bg: "transparent" }}
                  _focus={{ boxShadow: "transparent" }}
                  background={"white"}
                >
                  Menu <ChevronDownIcon marginLeft={2} />
                </MenuButton>
                <MenuList px={2} color={"black"}>
                  <Link
                    href={"/log-paa"}
                    _hover={{
                      textDecoration: "none",
                    }}
                  >
                    <MenuItem icon={<MdLogin />}>Log på</MenuItem>
                  </Link>
                  <Link
                    href={"/opret-konto"}
                    _hover={{
                      textDecoration: "none",
                    }}
                  >
                    <MenuItem icon={<TiUserAdd />}>Tilmeld</MenuItem>
                  </Link>
                </MenuList>
              </Menu>
            </Stack>
          </>
        )}
      </Flex>
    </Box>
  );
}

const DesktopNav = () => {
  const linkColor = useColorModeValue("gray.900", "gray.900");
  const linkHoverColor = useColorModeValue("gray.900", "gray.900");
  const popoverContentBgColor = useColorModeValue("white", "gray.900");

  return (
    <Stack direction={"row"} spacing={4}>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={"hover"} placement={"bottom-start"}>
            <PopoverTrigger>
              <Link
                p={2}
                href={navItem.href ?? "#"}
                fontSize={"md"}
                fontWeight={500}
                color={linkColor}
                _hover={{
                  textDecoration: "none",
                  color: linkHoverColor,
                }}
              >
                {navItem.label}
              </Link>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={"xl"}
                bg={popoverContentBgColor}
                p={4}
                rounded={"xl"}
                minW={"sm"}
              >
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const AuthDesktopNav = () => {
  const linkColor = useColorModeValue("gray.900", "gray.900");
  const linkHoverColor = useColorModeValue("gray.900", "gray.900");
  const popoverContentBgColor = useColorModeValue("white", "gray.900");

  return (
    <Stack direction={"row"} spacing={4}>
      {AUTH_NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={"hover"} placement={"bottom-start"}>
            <PopoverTrigger>
              <Link
                p={2}
                href={navItem.href ?? "#"}
                fontSize={"md"}
                fontWeight={500}
                color={linkColor}
                _hover={{
                  textDecoration: "none",
                  color: linkHoverColor,
                }}
              >
                {navItem.label}
              </Link>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={"xl"}
                bg={popoverContentBgColor}
                p={4}
                rounded={"xl"}
                minW={"sm"}
              >
                <Stack>
                  {navItem.children.map((child) => (
                    <AuthDesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const DesktopSubNav = ({ label, href, subLabel }: NavItem) => {
  return (
    <Link
      href={href}
      role={"group"}
      display={"block"}
      p={2}
      rounded={"md"}
      _hover={{ bg: useColorModeValue("pink.50", "gray.900") }}
    >
      <Stack direction={"row"} align={"center"}>
        <Box>
          <Text
            transition={"all .3s ease"}
            _groupHover={{ color: "gray.900" }}
            fontWeight={500}
          >
            {label}
          </Text>
          <Text fontSize={"sm"}>{subLabel}</Text>
        </Box>
        <Flex
          transition={"all .3s ease"}
          transform={"translateX(-10px)"}
          opacity={0}
          _groupHover={{ opacity: "100%", transform: "translateX(0)" }}
          justify={"flex-end"}
          align={"center"}
          flex={1}
        >
          <Icon color={"gray.900"} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Link>
  );
};

const AuthDesktopSubNav = ({ label, href, subLabel }: NavItem) => {
  return (
    <Link
      href={href}
      role={"group"}
      display={"block"}
      p={2}
      rounded={"md"}
      _hover={{ bg: useColorModeValue("pink.50", "gray.900") }}
    >
      <Stack direction={"row"} align={"center"}>
        <Box>
          <Text
            transition={"all .3s ease"}
            _groupHover={{ color: "gray.900" }}
            fontWeight={500}
          >
            {label}
          </Text>
          <Text fontSize={"sm"}>{subLabel}</Text>
        </Box>
        <Flex
          transition={"all .3s ease"}
          transform={"translateX(-10px)"}
          opacity={0}
          _groupHover={{ opacity: "100%", transform: "translateX(0)" }}
          justify={"flex-end"}
          align={"center"}
          flex={1}
        >
          <Icon color={"gray.900"} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Link>
  );
};

const MobileNav = () => {
  return (
    <Stack
      bg={useColorModeValue("white", "gray.800")}
      p={4}
      display={{ md: "none" }}
    >
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
      <VStack spacing={2} align="stretch">
        <MobileNavLink icon={<MdLogin />} label="Log på" link="#" />
        <MobileNavLink icon={<TiUserAdd />} label="Tilmeld" link="#" />
        <MobileNavLink icon={<BiSupport />} label="Support" link="#" />
      </VStack>
    </Stack>
  );
};
const MobileNavLink = ({ icon, label, link }) => {
  return (
    <Link
      href={link}
      fontSize="1rem"
      color={useColorModeValue("gray.600", "white")}
    >
      <Flex align="center">
        <Box mr={2} fontSize="1.5rem">
          {icon}
        </Box>

        {label}
      </Flex>
    </Link>
  );
};
const AuthMobileNav = () => {
  return (
    <Stack
      bg={useColorModeValue("white", "gray.800")}
      p={4}
      display={{ md: "none" }}
    >
      {AUTH_NAV_ITEMS.map((navItem) => (
        <AuthMobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href }: NavItem) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        as={Link}
        href={href ?? "#"}
        justify={"space-between"}
        align={"center"}
        _hover={{
          textDecoration: "none",
        }}
      >
        <Text
          fontWeight={600}
          color={useColorModeValue("gray.600", "gray.200")}
        >
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.700")}
          align={"start"}
        >
          {children &&
            children.map((child) => (
              <Link key={child.label} py={2} href={child.href}>
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

const AuthMobileNavItem = ({ label, children, href }: NavItem) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        as={Link}
        href={href ?? "#"}
        justify={"space-between"}
        align={"center"}
        _hover={{
          textDecoration: "none",
        }}
      >
        <Text
          fontWeight={600}
          color={useColorModeValue("gray.600", "gray.200")}
        >
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.700")}
          align={"start"}
        >
          {children &&
            children.map((child) => (
              <Link key={child.label} py={2} href={child.href}>
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

interface NavItem {
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
}

const NAV_ITEMS: Array<NavItem> = [];

const AUTH_NAV_ITEMS: Array<NavItem> = [];
