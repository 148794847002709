import { Box, Flex, Heading, Link, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import MarketplaceNavbar from "../components/MarketplaceNavbar.tsx";
import MarketplaceFooter from "../components/MarketplaceFooter.tsx";
import {
  Account,
  How_it_works,
  Payments,
  Cancelations,
} from "./constants/SupportText.js";
import { useParams } from "react-router-dom";

function SubSupport() {
  const { SupportId } = useParams();

  const [SupportData, setSupportData] = useState(
    SupportId === "account"
      ? Account
      : SupportId === "how-it-works"
      ? How_it_works
      : SupportId === "payments"
      ? Payments
      : SupportId === "cancel-refund"
      ? Cancelations
      : How_it_works
  );

  return (
    <>
      <Box position="sticky" top={0} zIndex={3}>
        <MarketplaceNavbar />
      </Box>
      <Flex
        minH={"80vh"}
        paddingY={"5rem"}
        width={"100%"}
        alignItems={"center"}
        flexDirection={"column"}
      >
        <Flex
          flexDirection={"column"}
          alignSelf={"center"}
          width={"fit-content"}
          alignItems={"flex-start"}
          gap={"3rem"}
        >
          <Heading size={"2xl"} color={"#23a994"}>
            {SupportData.title}
          </Heading>
          {SupportData.links.map((link, index) => (
            <>
              <Link key={index} href={link.link}>
                <Text>{link.title}</Text>
              </Link>
            </>
          ))}
        </Flex>
      </Flex>
      <MarketplaceFooter />
    </>
  );
}

export default SubSupport;
