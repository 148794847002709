import {
  Avatar,
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  Text,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { StarIcon } from "@chakra-ui/icons";

function BookingPageStaff({ appointment, setAppointment }) {
  const [employees, setEmployees] = useState();
  const toast = useToast();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedEmployeeIndex, setSelectedEmployeeIndex] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.post(
          "direct_salon/web/employees",
          {
            salon_slug: appointment.SalonSlug,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setEmployees(response.data.salon_employees);
        // Handle different status codes
        switch (response.status) {
          case 500:
            toast({
              title: "Server Fejl",
              description: "Prøv venligst igen om et øjeblik",
              position: "top-right",
              isClosable: true,
              status: "error",
            });
            navigate("/");
            break;
          case 404:
            toast({
              title: "Session over",
              description: "Prøv venligst igen om et øjeblik",
              position: "top-right",
              isClosable: true,
              status: "error",
            });
            navigate("/");
            break;
          case 405:
            toast({
              title: "Salon account is inactive",
              description: "Prøv venligst igen om et øjeblik",
              position: "top-right",
              isClosable: true,
              status: "error",
            });
            navigate("/");
            break;
          case 406:
            toast({
              title: "Salon account not connected",
              description: "Prøv venligst igen om et øjeblik",
              position: "top-right",
              isClosable: true,
              status: "error",
            });
            navigate("/");
            break;
          case 407:
            toast({
              title: "Salon profile incomplete",
              description: "Prøv venligst igen om et øjeblik",
              position: "top-right",
              isClosable: true,
              status: "error",
            });
            navigate("/");
            break;
          default:
            break;
        }
        setIsLoading(false);
      } catch (error) {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et øjeblik",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
        navigate("/");
      }
    };
    fetchData();
  }, []);
  return (
    <>
      <Box width={"100%"} height={"fit-content"}>
        <Box width={"100%"} height={"fit-content"} mb={"35px"}>
          <Heading fontWeight={"600"}>Vælg Medarbejdere</Heading>
        </Box>
        <Box width={"100%"} height={"fit-content"}>
          {isLoading ? (
            <Flex
              height={"30vh"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <ThreeDots
                visible={true}
                height="70"
                width="80"
                color="#23a994"
                radius="9"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </Flex>
          ) : (
            <Grid
              templateColumns={{
                sm: "repeat(1, 1fr)",
                md: "repeat(2, 1fr)",
                lg: "repeat(3, 1fr)",
              }}
              gap={6}
            >
              {employees &&
                employees.map(
                  (employee, index) =>
                    employee["Online"] && (
                      <GridItem
                        onClick={() => {
                          setSelectedEmployeeIndex(employee["No"]);
                          setAppointment((prevAppointment) => ({
                            ...prevAppointment,
                            employee: {
                              ...prevAppointment.employee,
                              Name: employee["NameID"],
                              Image: employee["Image"],
                              ID: employee["No"],
                              Speciality: employee["Job"],
                            },
                          }));
                        }}
                      >
                        <Flex
                          flexDirection={"column"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          border={
                            selectedEmployeeIndex === employee["No"]
                              ? "3px solid #23a994"
                              : "1px solid lightgray"
                          }
                          paddingX={"15px"}
                          paddingY={"30px"}
                          borderRadius={"12px"}
                          cursor={"pointer"}
                          _hover={{
                            bg: "whitesmoke",
                          }}
                          style={{
                            transition: "border-color 0.3s ease",
                          }}
                          position={"relative"}
                        >
                          <Avatar
                            size="lg"
                            name={employee["NameID"].substring(0, 1)}
                            src={employee["Image"]}
                            marginBottom={"15px"}
                            zIndex={0}
                          />
                          <Box>
                            <Text fontWeight={"500"}>{employee["NameID"]}</Text>
                          </Box>
                          <Box>
                            <Text fontWeight={"400"} color={"gray"}>
                              {employee["Job"]}
                            </Text>
                          </Box>
                          <Flex
                            position={"absolute"}
                            zIndex={1}
                            top={"50%"}
                            left={"38%"}
                            backgroundColor={"#fff"}
                            border={"1px solid lightgray"}
                            width={"fit-content"}
                            height={"fit-content"}
                            paddingX={"8px"}
                            paddingY={"2px"}
                            borderRadius={"15px"}
                            textAlign={"center"}
                            alignItems={"center"}
                            gap={"5px"}
                          >
                            <Text fontSize={"sm"} fontWeight={"500"}>
                              5.0
                            </Text>
                            <StarIcon
                              boxSize={3}
                              mb={"3px"}
                              color={"#ECC94B"}
                            />
                          </Flex>
                        </Flex>
                      </GridItem>
                    )
                )}
            </Grid>
          )}
        </Box>
      </Box>
    </>
  );
}

export default BookingPageStaff;
