export const getStarted = {
  title: "HOW TO GET STARTED WITH BOOKINGHERO",
  paragraphs: [
    {
      title: "Introduction:",
      text: "Welcome to Bookinghero, your all-in-one destination for hassle-free online booking of wellness and beauty salon appointments. Whether you're seeking a relaxing spa day or a stunning makeover, Bookinghero connects you with top-tier salons and professionals right at your fingertips. Read on to discover how simple it is to embark on your journey to ultimate self-care.",
    },
    {
      title: "Step 1: Download the Bookinghero App",
      text: "To begin your Bookinghero experience, head to the App Store (for iOS) or Google Play Store (for Android) and download our user-friendly mobile app. The app offers a seamless interface designed to make your booking process smooth and efficient.",
    },
    {
      title: "Step 2: Create Your Account",
      text: "Upon launching the app, click on the (Sign Up) button to create your personal Bookinghero account. Fill in your details, including your name, email, and a secure password. This account will serve as your gateway to a world of wellness and beauty.",
    },
    {
      title: "Step 3: Explore Salons and Services",
      text: "Once you're logged in, browse through our curated list of premium wellness and beauty salons in your area. Discover a wide array of services, from massages and facials to hair styling and nail treatments. Use our intuitive search filters to find the perfect match for your preferences.",
    },
    {
      title: "Step 4: Choose Your Preferred Service and Professional",
      text: "Found a service that catches your eye? Great! Select your preferred date and time, and Bookinghero will display a list of available professionals specializing in your chosen service. Explore their profiles, read reviews from other customers, and make an informed decision.",
    },
    {
      title: "Step 5: Confirm and Book",
      text: "After selecting your preferred professional, review the booking details and confirm your appointment. You'll receive a confirmation email and an app notification, ensuring you're always in the loop about your upcoming self-care session.",
    },
    {
      title: "Step 6: Enjoy Your Appointment",
      text: "On the day of your appointment, arrive at the salon ready to unwind and rejuvenate. Show your Bookinghero confirmation to the salon staff, and they'll take care of the rest.",
    },
    {
      title: "Conclusion:",
      text: "With Bookinghero, taking care of your wellness and beauty needs has never been easier. Say goodbye to phone calls and waiting times—embrace the convenience of online booking and embark on a journey to pampering and self-care excellence. Download the Bookinghero app today and experience a new era of salon appointments.",
    },
  ],
  links: [
    {
      id: 1,
      title: "How to get started with Bookinghero ?",
      link: "/support/account/get-started",
    },
    {
      id: 2,
      title: "I have forgotten my password - How do I reset it ?",
      link: "/support/account/reset-password",
    },
    {
      id: 3,
      title: "How do I manage my account information ?",
      link: "/support/account/account-information",
    },
    {
      id: 4,
      title: "How secure is my data ?",
      link: "/support/account/security",
    },
    {
      id: 5,
      title: "How my data can make my experience better ?",
      link: "/support/account/data",
    },
    {
      id: 6,
      title: "How can I desactivate my account ?",
      link: "/support/account/cancel",
    },
  ],
};

export const forgotPassword = {
  title: "FORGOT YOUR BOOKINGHERO PASSWORD? HERE'S HOW TO RESET IT",
  paragraphs: [
    {
      title: "Introduction:",
      text: "At Bookinghero, we understand that forgetting passwords happens to the best of us. No worries! Resetting your password is a straightforward process that ensures your account's security and convenience. Follow these simple steps to regain access to your Bookinghero account.",
    },
    {
      title: "Step 1: Visit the 'Forgot Your Password' Page",
      text: "If you can't recall your password, don't panic. Start by visiting the 'Forgot Your Password' page on the Bookinghero platform. This page is designed specifically to guide you through the password reset procedure.",
    },
    {
      title: "Step 2: Provide Your Email Address",
      text: "On the 'Forgot Your Password' page, enter the email address associated with your Bookinghero account. Make sure it's the same email you used during the account setup.",
    },
    {
      title: "Step 3: Receive Your Secret 4-Digit PIN Code",
      text: "After submitting your email address, check your inbox. You'll receive a prompt email from Bookinghero containing a unique 4-digit PIN code. This PIN code serves as a secure key to reset your password.",
    },
    {
      title: "Step 4: Reset Your Password",
      text: "Return to the Bookinghero platform and enter the 4-digit PIN code you received in your email. This step ensures that only you, the account owner, can proceed with the password reset. Once you've entered the PIN code, you'll be prompted to create a new password.",
    },
    {
      title: "Step 5: Set Your New Password",
      text: "Craft a strong, secure password that combines letters, numbers, and symbols. This new password will safeguard your Bookinghero account, ensuring your privacy and protection.",
    },
    {
      title: "Step 6: Access Your Account",
      text: "Congratulations! You've successfully reset your Bookinghero password. Use your new password to log in and access all the incredible features and services our platform has to offer.",
    },
    {
      title: "Conclusion:",
      text: "We've made password recovery a breeze at Bookinghero. Our secure and efficient process ensures that you can quickly regain access to your account without any hassle. Follow these steps, and you'll be back to booking wellness and beauty appointments in no time.",
    },
  ],
  links: [
    {
      id: 1,
      title: "How to get started with Bookinghero ?",
      link: "/support/account/get-started",
    },
    {
      id: 2,
      title: "I have forgotten my password - How do I reset it ?",
      link: "/support/account/reset-password",
    },
    {
      id: 3,
      title: "How do I manage my account information ?",
      link: "/support/account/account-information",
    },
    {
      id: 4,
      title: "How secure is my data ?",
      link: "/support/account/security",
    },
    {
      id: 5,
      title: "How my data can make my experience better ?",
      link: "/support/account/data",
    },
    {
      id: 6,
      title: "How can I desactivate my account ?",
      link: "/support/account/cancel",
    },
  ],
};

export const manageAccount = {
  title:
    "MANAGING YOUR ACCOUNT INFORMATION ON BOOKINGEHRO - A STEP-BY-STEP GUIDE",
  paragraphs: [
    {
      title: "Introduction:",
      text: "Your Bookinghero account puts you in control of your wellness and beauty journey. Managing your account information is a simple process that empowers you to keep your details up-to-date and accurate. Follow these easy steps to ensure your profile information reflects your preferences and needs.",
    },
    {
      title: "Step 1: Log In to Your Bookinghero Account",
      text: "Begin by logging in to your Bookinghero account. This personalized portal is where you can access and modify your account information according to your preferences.",
    },
    {
      title: "Step 2: Access Your Profile Page",
      text: "Once you're logged in, navigate to the top menu and find the 'Profile' option. Click on it to access your dedicated profile page, where you can review and update your account details.",
    },
    {
      title: "Step 3: Edit Your Information",
      text: "On the profile page, you'll see your current account information, including your name, email, and contact details. To make changes, simply click on the 'Edit' button or the pencil icon next to the relevant section.",
    },
    {
      title: "Step 4: Update Your Details",
      text: "Make the necessary changes to your account information. Whether it's updating your contact number, modifying your email address, or refining your personal preferences, this is your chance to ensure your profile accurately represents you.",
    },
    {
      title: "Step 5: Save Your Changes",
      text: "After making the desired updates, don't forget to save your changes. Locate the 'Update' button at the bottom of the page and click on it. Your modifications will be instantly applied to your Bookinghero account.",
    },
    {
      title: "Step 6: Confirm the Changes",
      text: "Once you've saved your changes, take a moment to review the updated information on your profile page. This will help you confirm that everything is accurate and as you want it to be.",
    },
    {
      title: "Conclusion:",
      text: "At Bookinghero, managing your account information is effortless and empowers you to tailor your experience to your liking. Keep your details current, so you never miss a beat when it comes to discovering and booking wellness and beauty appointments.",
    },
  ],
  links: [
    {
      id: 1,
      title: "How to get started with Bookinghero ?",
      link: "/support/account/get-started",
    },
    {
      id: 2,
      title: "I have forgotten my password - How do I reset it ?",
      link: "/support/account/reset-password",
    },
    {
      id: 3,
      title: "How do I manage my account information ?",
      link: "/support/account/account-information",
    },
    {
      id: 4,
      title: "How secure is my data ?",
      link: "/support/account/security",
    },
    {
      id: 5,
      title: "How my data can make my experience better ?",
      link: "/support/account/data",
    },
    {
      id: 6,
      title: "How can I desactivate my account ?",
      link: "/support/account/cancel",
    },
  ],
};

export const dataSecurity = {
  title: "DATA SECURITY AT BOOKINGHERO - YOUR PRIVACY IS OUR TOP PRIORITY",
  paragraphs: [
    {
      title: "Introduction:",
      text: "At Bookinghero, we understand that your personal data is precious. We take the security and privacy of your information seriously, implementing robust measures to ensure your peace of mind. Read on to discover how we safeguard your data and prioritize your online safety.",
    },
    {
      title: "Cutting-edge Encryption:",
      text: "When you interact with Bookinghero, your data is encrypted using state-of-the-art technology. This means that your personal information, including passwords and payment details, is transformed into unreadable code during transmission, making it extremely difficult for unauthorized parties to access or decipher.",
    },
    {
      title: "Secure Servers and Hosting:",
      text: "Your data is stored on secure servers hosted in trusted data centers. These facilities adhere to industry-leading security standards, including physical security measures and advanced firewalls, to protect your information from external threats.",
    },
    {
      title: "Privacy Controls:",
      text: "Bookinghero provides you with control over the information you share. You can adjust your privacy settings and choose what data you'd like to share publicly or keep private. We respect your choices and ensure that your preferences are always upheld.",
    },
    {
      title: "Regular Security Audits:",
      text: "We conduct routine security audits and assessments to identify potential vulnerabilities in our systems. This proactive approach allows us to promptly address any security concerns and implement enhancements to safeguard your data.",
    },
    {
      title: "Strict Access Controls:",
      text: "Access to your data is limited to authorized personnel who require it to provide you with seamless service. We implement strict access controls, ensuring that only those with the appropriate permissions can access sensitive information.",
    },
    {
      title: "Transparent Policies:",
      text: "Our Privacy Policy outlines in detail how your data is collected, used, and protected. We're committed to transparency, and our policy provides you with a clear understanding of how we handle your information.",
    },
    {
      title: "Continuous Monitoring:",
      text: "Our security team continuously monitors our systems for any unusual activity or potential breaches. This vigilance allows us to detect and respond to security threats swiftly, ensuring the integrity of your data.",
    },
    {
      title: "Conclusion:",
      text: "When it comes to data security, Bookinghero goes above and beyond to protect your information. We are dedicated to creating a secure environment where you can confidently explore wellness and beauty services without compromising your privacy.",
    },
  ],
  links: [
    {
      id: 1,
      title: "How to get started with Bookinghero ?",
      link: "/support/account/get-started",
    },
    {
      id: 2,
      title: "I have forgotten my password - How do I reset it ?",
      link: "/support/account/reset-password",
    },
    {
      id: 3,
      title: "How do I manage my account information ?",
      link: "/support/account/account-information",
    },
    {
      id: 4,
      title: "How secure is my data ?",
      link: "/support/account/security",
    },
    {
      id: 5,
      title: "How my data can make my experience better ?",
      link: "/support/account/data",
    },
    {
      id: 6,
      title: "How can I desactivate my account ?",
      link: "/support/account/cancel",
    },
  ],
};

export const dataExperience = {
  title: "ELEVATE YOUR EXPERIENCE WITH PERSONALIZED SERVICES ON BOOKINGHERO",
  paragraphs: [
    {
      title: "Introduction:",
      text: "At Bookinghero, we believe in making every aspect of your wellness and beauty journey exceptional. Your data plays a pivotal role in creating a tailored experience that caters to your preferences and needs. Read on to discover how we leverage your data to provide you with a truly personalized and convenient platform.",
    },
    {
      title: "GeoLocation for Nearby Venues:",
      text: "When you grant Bookinghero access to your location, our platform utilizes GeoLocation technology to present you with the best and nearest wellness and beauty venues. This means you can explore salons, spas, and professionals in your vicinity, saving you time and ensuring you have easy access to top-notch services.",
    },
    {
      title: "Preference-Based Recommendations:",
      text: "We understand that every individual's preferences are unique. By analyzing your past bookings and interactions, Bookinghero stores your preferences to curate tailored recommendations. This way, when you search for a specific service or treatment, we can suggest venues that align closely with your preferences, ensuring your satisfaction.",
    },
    {
      title: "Effortless Search and Booking:",
      text: "With your data, Bookinghero streamlines the search and booking process. The recommendations provided are specifically aligned with your tastes and location, eliminating the need to sift through irrelevant options. This saves you time and makes your entire experience smoother and more efficient.",
    },
    {
      title: "Personalized Offers and Deals:",
      text: "As we learn more about your preferences and booking history, we can also present you with exclusive offers and deals that resonate with your interests. This way, you not only enjoy personalized services but also access potential cost savings.",
    },
    {
      title: "Complete Control Over Data Sharing:",
      text: "Bookinghero ensures that you have complete control over your data sharing. You can modify your privacy settings and accept or decline which data you're comfortable sharing, putting you in the driver's seat when it comes to your personal information.",
    },
    {
      title: "Conclusion:",
      text: "Your data empowers us to elevate your Bookinghero experience to new heights. From recommending the perfect wellness venues to ensuring you receive tailored offers, our platform leverages your data to provide you with a personalized journey that's both convenient and satisfying.",
    },
  ],
  links: [
    {
      id: 1,
      title: "How to get started with Bookinghero ?",
      link: "/support/account/get-started",
    },
    {
      id: 2,
      title: "I have forgotten my password - How do I reset it ?",
      link: "/support/account/reset-password",
    },
    {
      id: 3,
      title: "How do I manage my account information ?",
      link: "/support/account/account-information",
    },
    {
      id: 4,
      title: "How secure is my data ?",
      link: "/support/account/security",
    },
    {
      id: 5,
      title: "How my data can make my experience better ?",
      link: "/support/account/data",
    },
    {
      id: 6,
      title: "How can I desactivate my account ?",
      link: "/support/account/cancel",
    },
  ],
};

export const cancelAccount = {
  title: "HOW TO DEACTIVATE YOUR BOOKINGHERO ACCOUNT - A STEP-BY-STEP GUIDE",
  paragraphs: [
    {
      title: "Introduction:",
      text: "If you ever decide to take a break from your Bookinghero journey, we're here to guide you through the account deactivation process. While we'll be sad to see you go, we want to ensure that your experience with us is as hassle-free as possible. Read on to learn how you can deactivate your Bookinghero account.",
    },
    {
      title: "Option 1: Deactivate via Your Profile Page:",
      text: "<b>Log In:</b> Start by logging in to your Bookinghero account.\n\n <b>Access Your Profile:</b> Once logged in, navigate to the top menu and locate the 'Profile' option. Click on it to access your profile page. \n\n <b>Account Settings:</b> Within your profile, look for the 'Cancel Subscription' or 'Deactivate Account' section. \n\n <b>Initiate Deactivation:</b> Click on the option to deactivate your account. You may be prompted to provide a reason for your decision. \n\n <b>Confirm Deactivation:</b> Follow the on-screen prompts to confirm your account deactivation. Please note that this action is irreversible and your data will be deleted.",
    },
    {
      title: "Option 2: Contact Support for Deactivation:",
      text: "<b>Visit the Support Page:</b> If you prefer, you can navigate to the Bookinghero support page.\n\n <b>Request Deactivation:</b> Reach out to our dedicated support team and express your intention to deactivate your account.\n\n <b>Confirmation Process:</b> Our support team will guide you through the confirmation process. Be prepared to verify your identity to ensure the security of your account.",
    },
    {
      title: "Your Data and Privacy:",
      text: "Upon account deactivation, your personal data will be handled according to our privacy policy. Any relevant information will be securely deleted to protect your privacy.",
    },
    {
      title: "Conclusion:",
      text: "While we'll be sorry to see you go, we understand that circumstances change. The account deactivation process on Bookinghero is designed to be straightforward, ensuring that you have control over your online experience. If you have any questions or need assistance, our dedicated support team is here to help.",
    },
  ],
  links: [
    {
      id: 1,
      title: "How to get started with Bookinghero ?",
      link: "/support/account/get-started",
    },
    {
      id: 2,
      title: "I have forgotten my password - How do I reset it ?",
      link: "/support/account/reset-password",
    },
    {
      id: 3,
      title: "How do I manage my account information ?",
      link: "/support/account/account-information",
    },
    {
      id: 4,
      title: "How secure is my data ?",
      link: "/support/account/security",
    },
    {
      id: 5,
      title: "How my data can make my experience better ?",
      link: "/support/account/data",
    },
    {
      id: 6,
      title: "How can I desactivate my account ?",
      link: "/support/account/cancel",
    },
  ],
};

export const marketplaceHow = {
  title:
    "NAVIGUATING THE BOOKINGHERO MARKETPLACE - YOUR GUIDE TO SEAMLESS BOOKING",
  paragraphs: [
    {
      title: "Introduction:",
      text: "Welcome to the Bookinghero marketplace, your gateway to a world of personalized beauty and wellness services. In this guide, we'll walk you through the key features that empower you to discover, bookmark, and book appointments with your favorite venues. Embark on your journey to a more convenient, enjoyable booking experience.",
    },
    {
      title: "Explore Categories and Venues",
      text: "Start by browsing through our diverse range of categories, including beauty, wellness, and barbershops. Simply search for the type of service you're looking for, and a curated list of top-tier venues will be at your fingertips. Explore their profiles, services, and reviews to find the perfect fit.",
    },
    {
      title: "Bookmark and Share",
      text: "Found a venue you love? You can bookmark it for easy access later. Plus, if you're excited to share your discovery with friends and followers, use our built-in social media sharing feature to spread the word about your favorite spots.",
    },
    {
      title: "Book Appointments",
      text: "Ready to treat yourself? Booking appointments is a breeze. Select your desired service, preferred date and time, and choose from available professionals. Our user-friendly interface ensures you have all the information you need to make informed decisions.",
    },
    {
      title: "Manage Your Appointments",
      text: "Once you've booked, your appointments are conveniently stored in your Bookinghero account. Easily manage your schedule, review upcoming appointments, and receive reminders to keep you on track.",
    },
    {
      title: "Accessing the Marketplace: Website and Mobile App",
      text: "Whether you're on your computer or on the go, our Bookinghero marketplace is accessible through both our website and our mobile app, available for iOS and Android. Seamlessly transition between platforms for a consistent and convenient experience.",
    },
    {
      title: "Conclusion:",
      text: "The Bookinghero marketplace transforms the way you discover and book beauty and wellness services. From finding your favorites and sharing them with the world to booking appointments and managing your schedule, our platform empowers you to indulge in self-care with ease.",
    },
  ],
  links: [
    {
      id: 1,
      title: "How to use the Bookinghero marketplace ?",
      link: "/support/how-it-works/marketplace",
    },
    {
      id: 2,
      title: "How can I save my favorit venues ?",
      link: "/support/how-it-works/favorites",
    },
    {
      id: 3,
      title: "How do I manage appointments ?",
      link: "/support/how-it-works/appointments",
    },
    {
      id: 4,
      title: "Which areas are supported by Bookinghero marketplace ?",
      link: "/support/how-it-works/supported-areas",
    },
  ],
};

export const favorits = {
  title: "SAVING YOUR FAVORITE VENUES ON BOOKINGHERO",
  paragraphs: [
    {
      title: "Introduction:",
      text: "At Bookinghero, we're all about making your beauty and wellness journey more convenient and tailored to your preferences. Discovering and saving your favorite venues is a simple process that ensures you have easy access to the places that resonate with you the most. Read on to learn how you can save and manage your favorite salons hassle-free.",
    },
    {
      title: "Step 1: Explore Venue Pages",
      text: "Start by logging into your Bookinghero account. Once you're authenticated, explore the profiles of various salons that catch your interest. Dive into their services, photos, reviews, and everything they offer.",
    },
    {
      title: "Step 2: Mark as Favorite",
      text: "While on the salon's page, you'll notice a heart-shaped icon—this is your 'Save as Favorite' button. Click on the heart icon to mark the salon as one of your favorites. The heart will fill in, indicating that the venue has been successfully saved.",
    },
    {
      title: "Step 3: Access Your Favorites",
      text: "To access your saved favorites, simply log in to your Bookinghero account and visit the 'Favorites' page. Here, you'll find a curated list of all the venues you've marked as favorites. It's your personalized collection of salons that cater to your style and preferences.",
    },
    {
      title: "Step 4: Manage Your Favorites",
      text: "On the 'Favorites' page, you have the power to manage your saved venues. You can add or remove favorites as your preferences evolve. This page is your hub for quick and easy access to the places that make your beauty and wellness journey extraordinary.",
    },
    {
      title: "Conclusion:",
      text: "Saving your favorite venues on Bookinghero is your ticket to a more personalized experience. With just a click of a heart icon, you can build a collection of salons that resonate with you. Whether it's for future bookings or simply to keep track of the places you love, our platform empowers you to curate your beauty and wellness journey with ease.",
    },
  ],
  links: [
    {
      id: 1,
      title: "How to use the Bookinghero marketplace ?",
      link: "/support/how-it-works/marketplace",
    },
    {
      id: 2,
      title: "How can I save my favorit venues ?",
      link: "/support/how-it-works/favorites",
    },
    {
      id: 3,
      title: "How do I manage appointments ?",
      link: "/support/how-it-works/appointments",
    },
    {
      id: 4,
      title: "Which areas are supported by Bookinghero marketplace ?",
      link: "/support/how-it-works/supported-areas",
    },
  ],
};

export const manageAppointments = {
  title: "MANAGING APPOINTMENTS ON BOOKINGHERO",
  paragraphs: [
    {
      title: "Introduction:",
      text: "Managing your appointments has never been easier with Bookinghero. Our user-friendly platform empowers you to effortlessly keep track of your beauty and wellness schedules. Read on to discover how you can manage, track, and modify your appointments with just a few clicks.",
    },
    {
      title: "Step 1: Log In to Your Client Account",
      text: "Start by logging in to your Bookinghero client account. This personalized portal gives you access to all your appointments and provides you with the tools to manage them effectively.",
    },
    {
      title: "Step 2: Navigate to the Appointments Page",
      text: "Once logged in, navigate to the 'Appointments' page. Here, you'll find a comprehensive overview of all your upcoming and past appointments, making it easy to stay organized.",
    },
    {
      title: "Step 3: Check Appointment Status",
      text: "On the 'Appointments' page, you can quickly check the status of your upcoming appointments. Whether it's 'Confirmed,' 'Pending,' or 'Completed,' this information ensures you're always in the know.",
    },
    {
      title: "Step 4: Book Again or Reschedule",
      text: "After a satisfying appointment, you have the option to book another session with the same venue or professional. This streamlined process makes rebooking a breeze. Additionally, if you need to reschedule an appointment, you can do so directly from the 'Appointments' page.",
    },
    {
      title: "Step 5: Cancel Appointments",
      text: "Life can be unpredictable, and we understand that plans change. If you need to cancel an appointment, you can initiate the process from the 'Appointments' page. Please note that individual salons may have specific cancellation policies.",
    },
    {
      title: "Conclusion:",
      text: "Bookinghero's appointment management features put you in control of your beauty and wellness journey. From tracking appointment statuses to rebooking, rescheduling, and cancellations, our platform ensures that your scheduling experience is seamless and stress-free.",
    },
  ],
  links: [
    {
      id: 1,
      title: "How to use the Bookinghero marketplace ?",
      link: "/support/how-it-works/marketplace",
    },
    {
      id: 2,
      title: "How can I save my favorit venues ?",
      link: "/support/how-it-works/favorites",
    },
    {
      id: 3,
      title: "How do I manage appointments ?",
      link: "/support/how-it-works/appointments",
    },
    {
      id: 4,
      title: "Which areas are supported by Bookinghero marketplace ?",
      link: "/support/how-it-works/supported-areas",
    },
  ],
};

export const marketCovarage = {
  title: "BOOKINGHERO MARKETPLACE COVERAGE",
  paragraphs: [
    {
      title: "Introduction:",
      text: "Bookinghero is your trusted partner for beauty and wellness services in Denmark. While we currently serve the vibrant city of Copenhagen, we're excited to share our plans for expansion to cover the entire country in the near future. Read on to learn about our current coverage and our vision for the future.",
    },
    {
      title: "Current Coverage: Copenhagen and Beyond",
      text: "Presently, Bookinghero proudly serves the city of Copenhagen, connecting users with exceptional beauty salons, wellness centers, and barbershops in this vibrant Danish metropolis.",
    },
    {
      title: "Future Expansion: Denmark's Beauty Awaits",
      text: "We have ambitious plans to expand our coverage across Denmark. In the near future, Bookinghero will be your go-to platform for discovering and booking beauty and wellness services in various cities and regions throughout the country.",
    },
    {
      title: "Your Localized Beauty and Wellness Experience:",
      text: "Whether you're currently enjoying our services in Copenhagen or eagerly awaiting our expansion, Bookinghero is committed to providing a localized and tailored experience that matches the beauty and wellness needs of each area.",
    },
    {
      title: "Conclusion:",
      text: "While we're currently based in Copenhagen, Bookinghero's roadmap includes reaching every corner of Denmark. We're dedicated to offering a comprehensive and user-friendly platform that connects you with top-tier beauty and wellness services across the country.",
    },
  ],
  links: [
    {
      id: 1,
      title: "How to use the Bookinghero marketplace ?",
      link: "/support/how-it-works/marketplace",
    },
    {
      id: 2,
      title: "How can I save my favorit venues ?",
      link: "/support/how-it-works/favorites",
    },
    {
      id: 3,
      title: "How do I manage appointments ?",
      link: "/support/how-it-works/appointments",
    },
    {
      id: 4,
      title: "Which areas are supported by Bookinghero marketplace ?",
      link: "/support/how-it-works/supported-areas",
    },
  ],
};

export const paymentSystem = {
  title:
    "UNDERSTANDING THE BOOKINGHERO PAYMENT SYSTEM - SECURE AND SEAMLESS TRANSATIONS",
  paragraphs: [
    {
      title: "Introduction:",
      text: "Bookinghero is committed to making your beauty and wellness experience as convenient and hassle-free as possible. Our payment system is designed to ensure smooth transactions while prioritizing the security of your financial information. Read on to learn how our payment process works.",
    },
    {
      title: "Booking Your Services:",
      text: "Once you've found the perfect beauty or wellness service on Bookinghero, you can proceed to book your appointment. Select your desired date, time, and service provider, then move on to the payment stage.",
    },
    {
      title: "Secure Payment Processing:",
      text: "Bookinghero employs industry-standard encryption and security measures to protect your payment details. When you provide your payment information, it's transmitted securely to ensure the confidentiality and integrity of your financial data.",
    },
    {
      title: "Payment Authorization:",
      text: "Upon submitting your payment information, our system processes the transaction in real-time. If the payment is successful, you'll receive a confirmation of your booking along with all the necessary details.",
    },
    {
      title: "Convenient Payment Methods:",
      text: "Bookinghero supports a variety of payment methods, making it easy for you to choose the one that suits you best. From credit and debit cards to digital wallets, we aim to provide flexibility in payment options.",
    },
    {
      title: "Transparent Pricing:",
      text: "Throughout the booking process, you'll have full visibility into the pricing of the services you're booking. There are no hidden fees, ensuring transparency in every transaction.",
    },
    {
      title: "Conclusion:",
      text: "Bookinghero's payment system is designed with your security and convenience in mind. With a straightforward process, secure payment processing, and a range of payment options, we ensure that your financial transactions are seamless as you indulge in beauty and wellness services.",
    },
  ],
  links: [
    {
      id: 1,
      title: "How does the payment system work ?",
      link: "/support/payments/system",
    },
    {
      id: 2,
      title: "How do I get a receipt ?",
      link: "/support/payments/receipts",
    },
    {
      id: 3,
      title: "What are the supported payment methods ?",
      link: "/support/payments/methods",
    },
    {
      id: 4,
      title: "Can I use coupons ?",
      link: "/support/payments/coupons",
    },
  ],
};

export const paymentReceipts = {
  title: "OBTAINING A RECEIPT ON BOOKINGHERO - KEEPING YOUR RECORDS IN CHECK",
  paragraphs: [
    {
      title: "Introduction:",
      text: "At Bookinghero, we understand the importance of keeping track of your beauty and wellness expenses. Obtaining a receipt for your bookings is a straightforward process that ensures you have the necessary documentation for your records. Read on to learn how you can easily obtain a receipt on Bookinghero.",
    },
    {
      title: "Step 1: Log In to Your Account",
      text: "Start by logging in to your Bookinghero account. This personalized access allows you to retrieve the information you need for your receipt.",
    },
    {
      title: "Step 2: Access Your Booking History",
      text: "Once logged in, navigate to the 'Booking History' or 'My Bookings' section. Here, you'll find a comprehensive list of all the appointments you've made through Bookinghero.",
    },
    {
      title: "Step 3: Retrieve Your Receipt",
      text: "Find the specific booking for which you need a receipt. Next to the booking details, you'll typically see an option to 'View Receipt' or 'Get Receipt.' Click on this option to generate and view your receipt.",
    },
    {
      title: "Step 4: Save or Print",
      text: "Once your receipt is displayed, you can choose to save it as a PDF file or print it for your records. This ensures you have a digital or hard copy of the receipt for future reference.",
    },
    {
      title: "Step 5: Receipt Details",
      text: "Your receipt will contain essential information, including the service details, date and time of the appointment, and the amount paid. This documentation can be valuable for personal or business purposes.",
    },
    {
      title: "Conclusion:",
      text: "Bookinghero provides you with a convenient way to access and obtain receipts for your beauty and wellness appointments. By following these simple steps, you'll be able to maintain accurate records of your expenses and appointments.",
    },
  ],
  links: [
    {
      id: 1,
      title: "How does the payment system work ?",
      link: "/support/payments/system",
    },
    {
      id: 2,
      title: "How do I get a receipt ?",
      link: "/support/payments/receipts",
    },
    {
      id: 3,
      title: "What are the supported payment methods ?",
      link: "/support/payments/methods",
    },
    {
      id: 4,
      title: "Can I use coupons ?",
      link: "/support/payments/coupons",
    },
  ],
};

export const paymentMethods = {
  title: "DISCORVERING SUPPORTEDPAYMENT METHODS ON BOOKINGHERO",
  paragraphs: [
    {
      title: "Introduction:",
      text: "Bookinghero is dedicated to providing you with flexible and convenient payment options to enhance your beauty and wellness experience. We offer a range of supported payment methods, ensuring that you can choose the one that best suits your preferences. Read on to explore the payment methods available on Bookinghero.",
    },
    {
      title: "PayPal: Secure Digital Payments",
      text: "Bookinghero supports PayPal, a globally recognized digital payment platform. With PayPal, you can complete transactions securely and swiftly using your linked bank account or credit card. This option offers a seamless and trusted way to pay for your beauty and wellness services.",
    },
    {
      title: "Visa Cards: Widely Accepted and Reliable",
      text: "Visa cards are widely accepted and recognized for their reliability. Whether you're booking a spa treatment, haircut, or any other service, you can conveniently use your Visa card for a hassle-free payment process.",
    },
    {
      title: "Cash on Arrival: Flexibility for Your Convenience",
      text: "In addition to digital payment methods, Bookinghero also supports cash payments upon arrival at the venue. This option provides you with the flexibility to pay in person for your services, ensuring a seamless experience from booking to treatment.",
    },
    {
      title: "Your Choice, Your Payment Experience:",
      text: "Bookinghero believes in empowering you to choose the payment method that aligns with your preferences and comfort level. Whether you prefer the ease of digital transactions or the familiarity of paying with cash, our platform accommodates your needs.",
    },
    {
      title: "Conclusion:",
      text: "Bookinghero's supported payment methods provide you with a spectrum of choices, ensuring that your beauty and wellness journey is convenient and tailored to your preferences. With options like PayPal, Visa cards, and cash on arrival, you have the flexibility to complete transactions in a way that suits you best.",
    },
  ],
  links: [
    {
      id: 1,
      title: "How does the payment system work ?",
      link: "/support/payments/system",
    },
    {
      id: 2,
      title: "How do I get a receipt ?",
      link: "/support/payments/receipts",
    },
    {
      id: 3,
      title: "What are the supported payment methods ?",
      link: "/support/payments/methods",
    },
    {
      id: 4,
      title: "Can I use coupons ?",
      link: "/support/payments/coupons",
    },
  ],
};

export const paymentCoupons = {
  title:
    "EXPLORING COUPONS ON BOOKINGHERO - UNLOCKING SAVINGS FOR YOUR BEAUTY AND WELLNESS EXPERIENCE",
  paragraphs: [
    {
      title: "Introduction:",
      text: "At Bookinghero, we believe in enhancing your beauty and wellness journey in every way possible. Our platform includes a feature that allows salons to offer coupons to their clients, enabling you to enjoy exclusive savings on your favorite services. Read on to learn more about how you can utilize coupons to elevate your Bookinghero experience.",
    },
    {
      title: "Salon Coupons: Your Path to Savings",
      text: "Bookinghero's salon partners have the opportunity to create and offer coupons to their valued clients. These coupons are designed to provide you with special discounts, promotions, or exclusive offers on a range of beauty and wellness services.",
    },
    {
      title: "Using Coupons: A Simple Process",
      text: "When you're browsing through salon profiles on Bookinghero, keep an eye out for any available coupons. These may be highlighted or mentioned in the salon's description. Once you find a salon offering a coupon that interests you, the process is simple.",
    },
    {
      title: "Redeeming Coupons: Applying Your Savings",
      text: "To redeem a coupon, follow the instructions provided by the salon. This may involve entering a unique code during the booking process or presenting the coupon at the time of your appointment. Once the coupon is validated, you'll enjoy the benefits of your exclusive offer.",
    },
    {
      title: "Finding Coupons: Enhancing Your Experience",
      text: "Exploring and using coupons not only allows you to access savings but also enhances your beauty and wellness experience. It's an opportunity to discover new salons, services, and treatments that align with your preferences.",
    },
    {
      title: "Conclusion:",
      text: "Bookinghero's coupon feature brings an extra layer of value to your beauty and wellness journey. From exclusive discounts to special promotions, coupons empower you to save while indulging in the services you love. Keep an eye out for participating salons, and take advantage of these offers to enhance your Bookinghero experience.",
    },
  ],
  links: [
    {
      id: 1,
      title: "How does the payment system work ?",
      link: "/support/payments/system",
    },
    {
      id: 2,
      title: "How do I get a receipt ?",
      link: "/support/payments/receipts",
    },
    {
      id: 3,
      title: "What are the supported payment methods ?",
      link: "/support/payments/methods",
    },
    {
      id: 4,
      title: "Can I use coupons ?",
      link: "/support/payments/coupons",
    },
  ],
};

export const cancelAppointment = {
  title: "CANCELLING YOUR APPOINTMENT ON BOOKINGHERO",
  paragraphs: [
    {
      title: "Introduction:",
      text: "Life can be unpredictable, and we understand that plans may change. If you find yourself needing to cancel an appointment booked through Bookinghero, our platform provides a straightforward process to make adjustments. Read on to learn how you can easily cancel your appointment with ease.",
    },
    {
      title: "Step 1: Log In to Your Account",
      text: "Start by logging in to your Bookinghero account. This personalized access allows you to manage your appointments efficiently.",
    },
    {
      title: "Step 2: Navigate to the Appointments Page",
      text: "Once logged in, head to the 'Appointments' page. Here, you'll find a comprehensive list of all your upcoming appointments.",
    },
    {
      title: "Step 3: Access the Appointment Details",
      text: "Locate the appointment you wish to cancel and click on it. This will open the appointment details, where you'll find all the information you need about the booking.",
    },
    {
      title: "Step 4: Cancel Your Appointment",
      text: "If the time and date of the appointment are still in the future, you'll see a red 'Cancel Appointment' button. Click on this button to initiate the cancellation process.",
    },
    {
      title: "Step 5: Confirm Cancellation",
      text: "Follow any on-screen prompts to confirm your cancellation. Please note that you can only cancel appointments before the scheduled time and date.",
    },
    {
      title: "Conclusion:",
      text: "Bookinghero's appointment cancellation process is designed to be simple and hassle-free. Whether plans change unexpectedly or you need to reschedule, our platform empowers you with the tools to manage your appointments seamlessly.",
    },
  ],
  links: [
    {
      id: 1,
      title: "I want to cancel my appointment, what should I do ?",
      link: "/support/cancel-refund/steps",
    },
    {
      id: 2,
      title: "The saloon canceled my appointment, why is that ?",
      link: "/support/cancel-refund/saloon-cancel",
    },
    {
      id: 3,
      title: "Can I cancel a appointment 5 minutes before it starts ?",
      link: "/support/cancel-refund/timing",
    },
    {
      id: 4,
      title: "Can I withdraw my cancelation request ?",
      link: "/support/cancel-refund/withdraw",
    },
    {
      id: 5,
      title:
        "What happens when I cancel a appointment and how does that affect me ?",
      link: "/support/cancel-refund/client-status",
    },
  ],
};

export const cancelReason = {
  title: "UNDERSTANDING APPOINTMENT CANCELLATIONS BY SALON ON BOOKINGHERO",
  paragraphs: [
    {
      title: "Introduction:",
      text: "At Bookinghero, we strive to provide a seamless booking experience that connects you with top-tier salons for your beauty and wellness needs. In some cases, a salon might need to cancel an appointment due to unforeseen circumstances. We're committed to transparency and ensuring that you have the information you need. Read on to learn more about the reasons behind salon-initiated appointment cancellations.",
    },
    {
      title: "Unforeseen Circumstances:",
      text: "Salons are dedicated to providing exceptional services, but there are situations beyond their control that might lead to appointment cancellations. These could include unexpected staff availability issues, facility concerns, or emergencies.",
    },
    {
      title: "Communication is Key:",
      text: "In the event that a salon needs to cancel an appointment, they will typically communicate the reasons to you as promptly as possible. This ensures that you're informed and aware of any changes to your scheduled appointment.",
    },
    {
      title: "Review Salon Policies:",
      text: "When booking through Bookinghero, it's also a good practice to review the individual salon's cancellation policies. Understanding these policies can provide insight into how appointments are managed and what to expect in the rare case of a cancellation.",
    },
    {
      title: "Conclusion:",
      text: "While rare, appointment cancellations by salons can happen due to unforeseen circumstances. Bookinghero is committed to maintaining transparency and communication throughout the process. Our platform aims to make the experience as seamless as possible, offering alternative options and ensuring you remain informed every step of the way.",
    },
  ],
  links: [
    {
      id: 1,
      title: "I want to cancel my appointment, what should I do ?",
      link: "/support/cancel-refund/steps",
    },
    {
      id: 2,
      title: "The saloon canceled my appointment, why is that ?",
      link: "/support/cancel-refund/saloon-cancel",
    },
    {
      id: 3,
      title: "Can I cancel a appointment 5 minutes before it starts ?",
      link: "/support/cancel-refund/timing",
    },
    {
      id: 4,
      title: "Can I withdraw my cancelation request ?",
      link: "/support/cancel-refund/withdraw",
    },
    {
      id: 5,
      title:
        "What happens when I cancel a appointment and how does that affect me ?",
      link: "/support/cancel-refund/client-status",
    },
  ],
};

export const cancelBefore = {
  title: "UNDERSTANDING LAST-MINUTE APPOINTMENT CANCELLATIONS ON BOOKINGHERO",
  paragraphs: [
    {
      title: "Introduction:",
      text: "At Bookinghero, we recognize that life can sometimes throw unexpected changes your way. We value your flexibility and understand that you might need to make last-minute adjustments to your appointments. Read on to learn about the flexibility offered by Bookinghero's appointment cancellation policy.",
    },
    {
      title: "Last-Minute Cancellations: Your Choice, Your Convenience",
      text: "Bookinghero understands that situations arise that may require you to cancel an appointment shortly before its scheduled start time. Our platform supports your needs by allowing appointment cancellations up to 5 minutes before the appointment is set to begin.",
    },
    {
      title: "Empowering Your Control:",
      text: "We believe in empowering you to manage your appointments in a way that aligns with your lifestyle. Whether your plans change due to unforeseen circumstances or personal preferences, Bookinghero's last-minute cancellation policy gives you the flexibility you need.",
    },
    {
      title: "Cancellations in the Nick of Time:",
      text: "To cancel an appointment within the 5-minute window before it starts, simply log in to your Bookinghero account and navigate to the 'Appointments' page. Locate the appointment and follow the cancellation process provided. Please note that this policy is subject to individual salon terms and conditions.",
    },
    {
      title: "Supporting Your Choices:",
      text: "Bookinghero's last-minute cancellation policy ensures that you have control over your beauty and wellness schedule. We understand that life can be dynamic, and we're here to support your choices every step of the way.",
    },
    {
      title: "Conclusion:",
      text: "Bookinghero's last-minute appointment cancellation policy reflects our commitment to your convenience and flexibility. By allowing cancellations up to 5 minutes before an appointment starts, we aim to empower you to manage your appointments with ease.",
    },
  ],
  links: [
    {
      id: 1,
      title: "I want to cancel my appointment, what should I do ?",
      link: "/support/cancel-refund/steps",
    },
    {
      id: 2,
      title: "The saloon canceled my appointment, why is that ?",
      link: "/support/cancel-refund/saloon-cancel",
    },
    {
      id: 3,
      title: "Can I cancel a appointment 5 minutes before it starts ?",
      link: "/support/cancel-refund/timing",
    },
    {
      id: 4,
      title: "Can I withdraw my cancelation request ?",
      link: "/support/cancel-refund/withdraw",
    },
    {
      id: 5,
      title:
        "What happens when I cancel a appointment and how does that affect me ?",
      link: "/support/cancel-refund/client-status",
    },
  ],
};

export const withdrawCancel = {
  title: "MANAGING CANCELATION REQUESTS ON BOOKINGHERO",
  paragraphs: [
    {
      title: "Introduction:",
      text: "Once you've initiated a cancellation request for an appointment on Bookinghero, please note that the action is final. Unfortunately, we don't offer the option to withdraw or reverse cancellation requests once they've been made.",
    },
    {
      title: "Commitment to Policy:",
      text: "Our platform's policy ensures consistency and clarity in appointment management. We recommend reviewing your decision thoroughly before proceeding with a cancellation request.",
    },
    {
      title: "Conclusion:",
      text: "While cancellation requests cannot be withdrawn, Bookinghero is committed to providing a transparent and user-focused experience. Please feel free to get in touch if you need any assistance.",
    },
  ],
  links: [
    {
      id: 1,
      title: "I want to cancel my appointment, what should I do ?",
      link: "/support/cancel-refund/steps",
    },
    {
      id: 2,
      title: "The saloon canceled my appointment, why is that ?",
      link: "/support/cancel-refund/saloon-cancel",
    },
    {
      id: 3,
      title: "Can I cancel a appointment 5 minutes before it starts ?",
      link: "/support/cancel-refund/timing",
    },
    {
      id: 4,
      title: "Can I withdraw my cancelation request ?",
      link: "/support/cancel-refund/withdraw",
    },
    {
      id: 5,
      title:
        "What happens when I cancel a appointment and how does that affect me ?",
      link: "/support/cancel-refund/client-status",
    },
  ],
};

export const cancelEffect = {
  title: "IMPACT OF APPOINTMENT CANCELATION ON BOOKINGHERO",
  paragraphs: [
    {
      title: "Introduction:",
      text: "At Bookinghero, we believe in providing transparency and clarity throughout your beauty and wellness journey. If you're considering canceling an appointment, it's important to understand the implications of this action on your account. Read on to learn about what happens when you cancel an appointment and how it may affect you.",
    },
    {
      title: "Cancellation Process: A Swift Update",
      text: "When you cancel an appointment through Bookinghero, the system registers the change promptly. The appointment status will be updated accordingly to reflect the cancellation.",
    },
    {
      title: "Account Impact: Understanding the Consequences",
      text: "Cancelling appointments may have several impacts on your account. These can include the potential loss of any discounts or promotions associated with the appointment. Additionally, the salon's cancellation policy, such as fees or penalties, may apply.",
    },
    {
      title: "Communication and Consideration:",
      text: "We recommend reviewing the specific salon's cancellation policy before initiating a cancellation. This will help you understand any potential charges or consequences associated with the cancellation.",
    },
    {
      title: "Managing Your Experience:",
      text: "Bookinghero aims to empower you with control over your appointments. While cancellations can have effects on your account, our platform ensures that you're informed and aware of the potential outcomes.",
    },
    {
      title: "Conclusion:",
      text: "Cancelling appointments on Bookinghero can result in various impacts, including potential loss of discounts and adherence to individual salon policies. We encourage you to make decisions that align with your needs while considering the information provided by both Bookinghero and the participating salons.",
    },
  ],
  links: [
    {
      id: 1,
      title: "I want to cancel my appointment, what should I do ?",
      link: "/support/cancel-refund/steps",
    },
    {
      id: 2,
      title: "The saloon canceled my appointment, why is that ?",
      link: "/support/cancel-refund/saloon-cancel",
    },
    {
      id: 3,
      title: "Can I cancel a appointment 5 minutes before it starts ?",
      link: "/support/cancel-refund/timing",
    },
    {
      id: 4,
      title: "Can I withdraw my cancelation request ?",
      link: "/support/cancel-refund/withdraw",
    },
    {
      id: 5,
      title:
        "What happens when I cancel a appointment and how does that affect me ?",
      link: "/support/cancel-refund/client-status",
    },
  ],
};
