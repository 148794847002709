import {
  Box,
  Divider,
  Flex,
  Heading,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import MarketplaceNavbar from "../components/MarketplaceNavbar.tsx";
import MarketplaceFooter from "../components/MarketplaceFooter.tsx";
import { MdOutlineAlternateEmail, MdOutlineMyLocation } from "react-icons/md";
import { BsTelephoneFill } from "react-icons/bs";
import { motion } from "framer-motion";

function MainSupport() {
  const [isFlexVisible, setIsFlexVisible] = useState(false);

  const toggleFlex = () => {
    setIsFlexVisible(!isFlexVisible);
  };

  return (
    <>
      <Box position="sticky" top={0} zIndex={3}>
        <MarketplaceNavbar />
      </Box>
      <Flex
        flexDirection={"column"}
        minH={"80vh"}
        paddingY={"5rem"}
        gap={"3rem"}
        alignItems={"center"}
      >
        <Heading size={"2xl"} textAlign={"center"} marginBottom={"5rem"}>
          WE ARE HERE TO HELP !
        </Heading>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={8}>
          <Box
            border={"1px solid grey"}
            borderRadius={"35px"}
            w={"350px"}
            paddingY={"18px"}
            cursor={"pointer"}
            _hover={{
              background: "#23a994",
              color: "white",
              borderColor: "#23a994",
            }}
            as={"a"}
            href={"/support/account"}
          >
            <Text textAlign={"center"} fontWeight={"500"} fontSize={"25px"}>
              Konto
            </Text>
          </Box>
          <Box
            border={"1px solid grey"}
            borderRadius={"35px"}
            w={"350px"}
            paddingY={"18px"}
            cursor={"pointer"}
            _hover={{
              background: "#23a994",
              color: "white",
              borderColor: "#23a994",
            }}
            as={"a"}
            href={"/support/how-it-works"}
          >
            <Text textAlign={"center"} fontWeight={"500"} fontSize={"25px"}>
              Hvordan Virker Det
            </Text>
          </Box>
          <Box
            border={"1px solid grey"}
            borderRadius={"35px"}
            w={"350px"}
            paddingY={"18px"}
            cursor={"pointer"}
            _hover={{
              background: "#23a994",
              color: "white",
              borderColor: "#23a994",
            }}
            as={"a"}
            href={"/support/payments"}
          >
            <Text textAlign={"center"} fontWeight={"500"} fontSize={"25px"}>
              Betalinger
            </Text>
          </Box>
        </SimpleGrid>
        <SimpleGrid columns={1} spacing={8}>
          <Box
            border={"1px solid grey"}
            borderRadius={"35px"}
            w={{ base: "350px", md: "1110px" }}
            paddingY={"18px"}
            cursor={"pointer"}
            _hover={{
              background: "#23a994",
              color: "white",
              borderColor: "#23a994",
            }}
            as={"a"}
            href={"/support/cancel-refund"}
          >
            <Text textAlign={"center"} fontWeight={"500"} fontSize={"25px"}>
              Afbestillinger Og Refusion
            </Text>
          </Box>
        </SimpleGrid>
        <SimpleGrid columns={1} spacing={8}>
          <Box
            border={"1px solid grey"}
            borderRadius={"35px"}
            background={"#23a994"}
            color={"white"}
            w={{ base: "350px", md: "1110px" }}
            paddingY={"18px"}
            cursor={"pointer"}
            _hover={{
              background: "white",
              color: "#23a994",
              borderColor: "#23a994",
            }}
            onClick={toggleFlex}
          >
            <Text textAlign={"center"} fontWeight={"500"} fontSize={"25px"}>
              Kontakt os
            </Text>
          </Box>
        </SimpleGrid>
        <Flex
          flexDirection={"column"}
          w={{ base: "350px", md: "1090px" }}
          alignSelf={"center"}
          gap={"2rem"}
          display={isFlexVisible ? "flex" : "none"}
          transition="opacity 0.5s ease, transform 0.5s ease"
          opacity={isFlexVisible ? 1 : 0}
          transform={`translateY(${isFlexVisible ? "0" : "-20px"})`}
        >
          <Divider color={"gray.900"} borderColor={"gray.900"} />
          <Heading textAlign={"center"} size={"md"}>
            Our opening hours are weekdays 08:00 - 17:00.
          </Heading>
          <SimpleGrid
            columns={{ base: 1, md: 3 }}
            spacing={8}
            alignItems={"center"}
            width={{ base: "350px", md: "1090px" }}
          >
            <Flex
              alignItems={"center"}
              gap={"10px"}
              justifySelf={{ base: "center" }}
            >
              <BsTelephoneFill />
              <Text
                textAlign={{ base: "center", md: "start" }}
                fontSize={"16px"}
                fontWeight={"500"}
              >
                (+43) 05 05 05 05
              </Text>
            </Flex>
            <Flex
              alignItems={"center"}
              gap={"10px"}
              width={{ base: "fit-content", md: "400px" }}
            >
              <MdOutlineMyLocation />
              <Text textAlign={"center"} fontSize={"16px"} fontWeight={"500"}>
                Maglebjergvej 6 - 2800 Kongens Lyngby
              </Text>
            </Flex>
            <Flex
              alignItems={"center"}
              gap={"10px"}
              alignSelf={"flex-end"}
              textAlign={"end"}
              justifySelf={{ base: "center", md: "end" }}
            >
              <MdOutlineAlternateEmail />
              <Text textAlign={"end"} fontSize={"16px"} fontWeight={"500"}>
                support@bookinghero.io
              </Text>
            </Flex>
          </SimpleGrid>
        </Flex>
      </Flex>
      <MarketplaceFooter />
    </>
  );
}

export default MainSupport;
