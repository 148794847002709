import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from "@chakra-ui/react";
import React from "react";

function BookingPageCrumbs({ appointment, setAppointment }) {
  // Define breadCrumb steps
  const crumbData = [
    { label: "Tjenester" },
    { label: "Medarbejdere" },
    { label: "Tid" },
    { label: "Bekræfte" },
  ];
  // get active step from params
  const step = appointment.step;

  return (
    <>
      <Box width="100%" height="fit-content" paddingX="150px">
        <Breadcrumb
          spacing="8px"
          separator={<ChevronRightIcon boxSize={4} color={"gray.500"} />}
          fontWeight="500"
        >
          {crumbData.map((crumb, index) => (
            <BreadcrumbItem key={index} isCurrentPage={step === index + 1}>
              <BreadcrumbLink
                color={step >= index + 1 ? "" : "gray.500"}
                textDecoration={"none"}
              >
                {crumb.label}
              </BreadcrumbLink>
            </BreadcrumbItem>
          ))}
        </Breadcrumb>
      </Box>
    </>
  );
}

export default BookingPageCrumbs;
