import React, { useRef } from "react";
import "./MarketplaceDirectSalon.css";
import MarketplaceNavbar from "../components/MarketplaceNavbar.tsx";
import MarketplaceFooter from "../components/MarketplaceFooter.tsx";
import { Helmet } from "react-helmet-async";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import {
  Avatar,
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {
  BsHeart,
  BsStar,
  BsStarFill,
  BsStarHalf,
  BsTelephoneFill,
} from "react-icons/bs";
import { MapContainer, Marker } from "react-leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import { useNavigate } from "react-router-dom";
import {
  MdIosShare,
  MdOutlineAlternateEmail,
  MdOutlineMyLocation,
} from "react-icons/md";
import { useEffect } from "react";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
import { useState } from "react";
import axios from "axios";
import L from "leaflet";
import { useParams } from "react-router-dom";
import { AddIcon, StarIcon } from "@chakra-ui/icons";

const CustomIcon = ({ rating, isHovered, showAnimation }) => {
  const leftPosition = Number.isInteger(rating) ? "10px" : "6px";
  const leftPosition2 = Number.isInteger(rating) ? "14px" : "9px";
  const jumpAnimationClass = showAnimation ? "" : "jump-animation";
  const fontSize = "10px";
  return L.divIcon({
    className: "custom-icon",
    html: `
      <div  style="position: relative; width=40px; height=40px;">
      
      ${
        rating
          ? !isHovered
            ? `<i class="fa-sharp fa-solid fa-location-pin fa-2xl" style="color: #001642; font-size:36px; ">
          ${`<span style="position:absolute; top:5px; left:${leftPosition};margin:auto; color: white;  font-weight: 600; font-size: ${fontSize};">${rating} </span>`}
          </i>
          `
            : `<i class="${jumpAnimationClass} fa-sharp fa-solid fa-location-pin fa-2xl" style=" color: #001642; font-size:45px; position:relative; left:-3px;top:-3px; ">
            ${`<span style="position:absolute;top:-8px; left:${leftPosition2};margin:auto; color: white;  font-weight: 600; font-size: ${fontSize};">${rating} </span>`}
            <i class="fa-solid fa-star fa-fade" style="position:relative;top:-8px; left:12px;color: #ffffff; font-size:10px;"></i>
            </i>`
          : !isHovered
          ? `<i class="fa-sharp fa-solid fa-location-dot fa-2xl" style="color: #001642; font-size:36px; ">
          
            </i>`
          : `<i class="${jumpAnimationClass} fa-sharp fa-solid fa-location-dot fa-2xl" style=" color: #001642; font-size:45px; position:relative; left:-3px;top:-3px; ">
             
              </i>`
      }
   
      </div>
    `,
  });
};

const DanishDays = {
  Monday: "Mandag",
  Tuesday: "Tirsdag",
  Wednesday: "Onsdag",
  Thursday: "Torsdag",
  Friday: "Fredag",
  Saturday: "Lørdag",
  Sunday: "Søndag",
};

function MarketplaceDirectSalon() {
  const ColorModeValue = useColorModeValue("gray.800", "gray.900");

  const toast = useToast();
  const navigate = useNavigate();
  const { idSaloon } = useParams();

  const isSmallScreen = useBreakpointValue({ base: true, lg: false });

  const [auth, set_auth] = useState(false);

  const [refresh_counter, set_refresh_counter] = useState(0);

  const [isScrolled, setIsScrolled] = useState(false);

  const [currentTreatment, setCurrentTreatment] = useState();

  const {
    isOpen: isTreatmentOpen,
    onOpen: onTreatmentOpen,
    onClose: onTreatmentClose,
  } = useDisclosure();

  const getDecryptedCookie = () => {
    const encryptedValue = Cookies.get("auth_token");
    const decryptedValue = decryptCookieValue(encryptedValue); // Replace with your decryption logic
    return decryptedValue;
  };

  const decryptCookieValue = (encryptedValue) => {
    const secretKey = "bookinghero";
    const bytes = CryptoJS.AES.decrypt(encryptedValue, secretKey);
    const decryptedValue = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedValue;
  };

  useEffect(() => {
    if (Cookies.get("user_id") && Cookies.get("auth_token")) {
      const auth_token = getDecryptedCookie();
      const client_id = Cookies.get("user_id");
      if (auth_token === client_id) {
        set_auth(true);
      } else {
        set_auth(false);
      }
    } else {
      set_auth(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh_counter]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      setIsScrolled(scrollTop > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [is_bookmarked, set_is_bookmarked] = useState(true);

  //this to reference the reviews section so we can hover to it when clicking on reviews number
  const reviewRef = useRef(null);
  const handleScrollToReview = () => {
    // Get the top position of the review element
    const reviewTop = reviewRef.current.offsetTop;

    // Scroll to the review element with an offset of 100 pixels
    window.scrollTo({
      top: reviewTop - 200,
      behavior: "smooth",
    });
  };

  const [staffs_display_count, set_staffs_display_count] = useState(5);

  const [all_staffs, set_all_staffs] = useState(5);

  const [is_data_loading, set_is_data_loading] = useState(true);

  const [hoveredMarker, setHoveredMarker] = useState(null);

  const handleMarkerHover = (markerId) => {
    setHoveredMarker(markerId);
  };

  const handleMarkerMouseLeave = () => {
    setHoveredMarker(null);
  };

  // each time we will show 5 more staffs
  const handleShowMoreStaffs = () => {
    set_staffs_display_count(
      staffs_display_count + 5 > all_staffs
        ? all_staffs
        : staffs_display_count + 5
    );
  };

  //show only 5 staffs
  const handleShowLessStaffs = () => {
    set_staffs_display_count(5);
  };

  const [showAnimation, setShowAnimation] = useState(true);

  // This is just a static schedule | later we will bring it from API
  const saloon_schedule = Object.entries({
    Sunday: {
      startTime: null,
      endTime: null,
      offday: 1,
    },
    Monday: {
      startTime: "10:00",
      endTime: "18:00",
      offday: 0,
    },
    Tuesday: {
      startTime: "10:00",
      endTime: "18:00",
      offday: 0,
    },
    Wednesday: {
      startTime: "10:00",
      endTime: "18:00",
      offday: 0,
    },
    Thursday: {
      startTime: "10:00",
      endTime: "18:00",
      offday: 0,
    },
    Friday: {
      startTime: "10:00",
      endTime: "18:00",
      offday: 0,
    },
    Saturday: {
      startTime: null,
      endTime: null,
      offday: 1,
    },
  });

  const handle_bookmark = async (e, saloon_id) => {
    e.preventDefault();
    try {
      const Data = {
        saloon_id: saloon_id,
      };
      const res = await axios.post("bookmarks/web/external/", Data);

      if (res.status === 200) {
        toast({
          title: "Info",
          description: "Saloon er blevet tilføjet til favoritter",
          position: "top-right",
          isClosable: true,
          status: "success",
        });
      }
    } catch (err) {
      toast({
        title: "Server Fejl",
        description: "Prøv venligst igen om et øjeblik",
        position: "top-right",
        isClosable: true,
        status: "error",
      });
    }
  };

  const encryptCookieValue = (valueToEncrypt) => {
    const secretKey = "bookinghero"; // Replace with your secret key used for encryption

    const encryptedValue = CryptoJS.AES.encrypt(
      valueToEncrypt,
      secretKey
    ).toString();
    return encryptedValue;
  };

  const [userLocation, setUserLocation] = useState(null);

  // Get position of visitor - On page load
  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setUserLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    }
  };

  const handleGetDirection = (saloonlt, saloonlg) => {
    const acceptCookies = Cookies.get("accept_cookies");
    if (acceptCookies === "true") {
      if (Cookies.get("blt")) {
        setUserLocation({
          lat: decryptCookieValue(Cookies.get("blt")),
          lng: decryptCookieValue(Cookies.get("blg")),
        });
      } else {
        getLocation();
        const cookieOptions = {
          sameSite: "strict", // Set the SameSite attribute to 'Strict'
        };
        Cookies.set(
          "blt",
          encryptCookieValue(userLocation.lat.toString()),
          cookieOptions
        );
        Cookies.set(
          "blg",
          encryptCookieValue(userLocation.lng.toString()),
          cookieOptions
        );
      }
    } else {
      getLocation();
    }

    const googleMapsUrl = `https://www.google.com/maps/dir/${userLocation.lat.toString()},${userLocation.lng.toString()}/${saloonlt},${saloonlg}`;

    // Open the URL in a new window or tab
    window.open(googleMapsUrl, "_blank");
  };

  const handleBookNow = async (id) => {
    let ip = "unknown";
    let lt = "unknown";
    let lg = "unknown";
    let city = "unknown";
    let country = "unknown";

    const date = new Date();

    // Get individual date components
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    // Create the formatted date string
    const time = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    const acceptCookies = Cookies.get("accept_cookies");
    if (acceptCookies === "true") {
      if (Cookies.get("bip")) {
        ip = decryptCookieValue(Cookies.get("bip"));
      }
      if (Cookies.get("blt")) {
        lt = decryptCookieValue(Cookies.get("blt"));
        lg = decryptCookieValue(Cookies.get("blg"));
      }

      if (Cookies.get("bcity")) {
        city = decryptCookieValue(Cookies.get("bcity"));
      }
      if (Cookies.get("bcountry")) {
        country = decryptCookieValue(Cookies.get("bcountry"));
      }
    }
    const history = {
      ip: ip,
      city: city,
      country: country,
      time: time,
      latitude: lt,
      longtitude: lg,
    };
    try {
      const res = await axios.post(`/saloon/web/monotorise/${id}`, history);
      if (res["status"] === 200) {
        console.log("success");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const isMobile = window.innerWidth <= 768; // Adjust the breakpoint according to your design.

  const [salonInformation, setSalonInformation] = useState(); //all saloon details
  const [salonEmployees, setSalonEmployees] = useState(); // saloon employees
  const [salonTreatments, setSalonTreatments] = useState(); //saloon services
  // handle number of treatments to show
  const [treatments_display_count, set_treatments_display_count] = useState(30);
  const [all_treatments, set_all_treatments] = useState(5);
  const handleShowMoreTreatments = () => {
    set_treatments_display_count(
      treatments_display_count + 5 > all_treatments
        ? all_treatments
        : treatments_display_count + 5
    );
  };

  // get the date format of mysql and turn it to readable format to display
  const formatDateString = (dateString) => {
    // Convert date string to Date object
    const dateObject = new Date(dateString);

    // Extract day, month, year, hours, and minutes separately
    const day = dateObject.getDate();
    const month = dateObject.toLocaleString("da-DK", { month: "long" });
    const year = dateObject.getFullYear();

    // Extract hours and minutes separately
    const hours = dateObject.getHours();
    const minutes = dateObject.getMinutes();

    // Format the time as "kl HH:mm"
    const formattedTime = `kl ${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;

    // Combine date and time in the desired format
    const result = `${month.toString()} ${day.toString()}, ${year} ${formattedTime}`;

    return result;
  };

  // If review comment is too long we will only show part of it (30letters)
  const [showFullReviews, setShowFullReviews] = useState();
  const toggleReview = (index) => {
    const newShowFullReviews = [...showFullReviews];
    newShowFullReviews[index] = !newShowFullReviews[index];
    setShowFullReviews(newShowFullReviews);
  };

  // Impresseions toggle
  const [impressionToggle, setImpressionToggle] = useState(0);

  // get all infos related to salon
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          "direct_salon/web/get_information",
          {
            salon_slug: idSaloon,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setSalonInformation(response.data.salon_information);
        setSalonEmployees(response.data.salon_employees);
        setSalonTreatments(response.data.salon_treatments);
        set_all_staffs(response.data.salon_employees.length);
        set_all_treatments(response.data.salon_treatments.length);
        set_is_bookmarked(response.data.is_salon_bookmarked);
        setShowFullReviews(
          Array(response.data.salon_information.number_review).fill(false)
        );
        set_is_data_loading(false);
        setImpressionToggle(impressionToggle + 1);

        // Handle different status codes
        switch (response.status) {
          case 500:
            toast({
              title: "Server Fejl",
              description: "Prøv venligst igen om et øjeblik",
              position: "top-right",
              isClosable: true,
              status: "error",
            });
            navigate("/");
            break;
          case 404:
            toast({
              title: "Session over",
              description: "Prøv venligst igen om et øjeblik",
              position: "top-right",
              isClosable: true,
              status: "error",
            });
            navigate("/");
            break;
          case 405:
            toast({
              title: "Salon account is inactive",
              description: "Prøv venligst igen om et øjeblik",
              position: "top-right",
              isClosable: true,
              status: "error",
            });
            navigate("/");
            break;
          case 406:
            toast({
              title: "Salon account not connected",
              description: "Prøv venligst igen om et øjeblik",
              position: "top-right",
              isClosable: true,
              status: "error",
            });
            navigate("/");
            break;
          case 407:
            toast({
              title: "Salon profile incomplete",
              description: "Prøv venligst igen om et øjeblik",
              position: "top-right",
              isClosable: true,
              status: "error",
            });
            navigate("/");
            break;
          default:
            break;
        }
      } catch (error) {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et øjeblik",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
        navigate("/");
      }
    };

    fetchData();
  }, []); // Run useEffect whenever salonSlug changes
  const [imageIndex, setImageIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  // this is useful for small screen to display images as slides
  useEffect(() => {
    if (salonInformation) {
      const updateImageIndex = () => {
        setImageIndex(
          (prevIndex) => (prevIndex + 1) % salonInformation.album_images.length
        );
      };

      const intervalId = setInterval(updateImageIndex, 3000); // Change the interval duration as needed

      return () => clearInterval(intervalId);
    }
  }, []);

  // Insert impression on salon page when loaded
  useEffect(() => {
    const insertImpression = async () => {
      const bodyData = {
        business_id: salonInformation.general_information[0].business_id,
        business_slug: salonInformation.general_information[0].slug,
      };

      try {
        await axios.post("direct_salon/web/insert-impression", bodyData);
      } catch (error) {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et øjeblik",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    };

    if (salonInformation) {
      insertImpression();
    }
  }, [impressionToggle]);

  const [appointment, setAppointment] = useState({
    step: 1,
    create: true,
    personal: true,
    sms: true,
    SalonSlug: idSaloon,
    schedule: {
      day: "",
      dayInText: "",
      year: "",
      month: "",
      startTime: "",
      endTime: "",
    },
    treatment: {
      Price: "",
      Duration: "",
      Name: "",
      Type: -1,
      DurationFrom: "",
      PriceFrom: "",
      ID: -1,
    },
    employee: { Name: "", Speciality: "", Image: "", ID: -1 },
    client: {
      Name: "",
      Address: "",
      Phone: "",
      Email: "",
      Note: "",
      Complete: false,
    },
  });

  useEffect(() => {
    if (appointment.treatment.Name !== "") {
      const currentPath = window.location.pathname;
      const newPath = `${currentPath}`;
      navigate(`${newPath}/booking`, {
        state: { appointment },
      });
    }
  }, [appointment]);

  return !is_data_loading ? (
    <>
      {salonInformation && (
        <>
          <Helmet>
            <title>
              Bookinghero - Book behandlinger hos
              {salonInformation.general_information[0]["name"]}
            </title>

            <meta name="robots" content="noindex" />
          </Helmet>
        </>
      )}
      {salonInformation && (
        <div>
          <Box position="sticky" top={0} zIndex={4}>
            <MarketplaceNavbar />
          </Box>
          <Box
            marginTop={"20px"}
            paddingX={{ md: "1%", xl: "3%", "1920px": "12%" }}
            height={"fit-content"}
          >
            <Flex marginLeft={"2px"} gap={"5px"} marginBottom={"15px"}>
              <Link
                fontWeight={"500"}
                color={"gray.600"}
                _hover={{ textDecoration: "none" }}
                href={"/"}
              >
                Hjem
              </Link>
              <Text fontSize={"lg"} fontWeight={"500"} color={"gray.600"}>
                •
              </Text>
              <Link
                fontWeight={"500"}
                color={"gray.600"}
                _hover={{ textDecoration: "none" }}
                href={
                  "/" +
                  salonInformation.general_information[0]["category_label"]
                    .toLowerCase()
                    .replace(/\s+/g, "-") +
                  "?type=all&sort=normal&page=1"
                }
              >
                {salonInformation.general_information[0]["category_label"]}
              </Link>
              <Text fontSize={"lg"} fontWeight={"500"} color={"gray.600"}>
                •
              </Text>
              <Link
                fontWeight={"500"}
                color={"gray.600"}
                _hover={{ textDecoration: "none" }}
                href={"/d/" + salonInformation.general_information[0]["slug"]}
              >
                {salonInformation.general_information[0]["name"]}
              </Link>
            </Flex>
            <Flex marginBottom={"15px"}>
              <Heading size={"2xl"} fontWeight={"600"}>
                {salonInformation.general_information[0]["name"]}
              </Heading>
            </Flex>
            <Flex
              gap={"5px"}
              width={"100%"}
              position={"relative"}
              alignItems={"center"}
            >
              {salonInformation.salon_rating ? (
                <>
                  <Text fontSize={"lg"} fontWeight={"600"}>
                    {Math.round(salonInformation.salon_rating * 2) / 2}
                  </Text>
                  {Array(5)
                    .fill("")
                    .map((_, i) => {
                      const roundedRating =
                        Math.round(salonInformation.salon_rating * 2) / 2;
                      if (roundedRating - i >= 1) {
                        return (
                          <BsStarFill key={i} color={"#ECC94B"} size={"16px"} />
                        );
                      }
                      if (roundedRating - i === 0.5) {
                        // Add the return statement here
                        return (
                          <BsStarHalf key={i} color={"#ECC94B"} size={"16px"} />
                        );
                      } else {
                        return (
                          <BsStar key={i} color={"#ECC94B"} size={"16px"} />
                        );
                      }
                    })}
                  <Link
                    _hover={{ textDecoration: "none" }}
                    onClick={handleScrollToReview}
                    target="blank"
                  >
                    <Text
                      fontSize={"md"}
                      fontWeight={"600"}
                      color={"teal"}
                      marginLeft={"5px"}
                    >
                      ({salonInformation.number_review})
                    </Text>
                  </Link>
                </>
              ) : (
                <>
                  <Text fontSize={"md"} fontWeight={"600"} color={"teal"}>
                    Ny Salon
                  </Text>
                </>
              )}

              <Text
                fontSize={"lg"}
                fontWeight={"500"}
                color={"gray.600"}
                marginLeft={"5px"}
                marginRight={"5px"}
              >
                •
              </Text>
              <Text fontSize={isSmallScreen ? "sm" : "md"} fontWeight={"500"}>
                {salonInformation.general_information[0]["address"]} {", "}{" "}
                {salonInformation.general_information[0]["city"]}
              </Text>
              <Box
                border={"1px solid lightgray"}
                borderRadius={"50%"}
                cursor={"pointer"}
                _hover={{ background: "whitesmoke" }}
                width={"fit-content"}
                height={"fit-content"}
                padding={"10px"}
                justifySelf={"flex-end"}
                position={"absolute"}
                right={isSmallScreen ? "5" : "0"}
                bottom={isSmallScreen ? "-20" : "2"}
                background={"white"}
                onClick={() => {
                  navigator.clipboard.writeText(window.location.href);
                  toast({
                    title: "Info",
                    description: "Saloon-URL kopieret til udklipsholderen",
                    position: "top-right",
                    isClosable: true,
                    status: "success",
                  });
                }}
              >
                <MdIosShare size={"20px"} />
              </Box>
              <Box
                border={"1px solid lightgray"}
                borderRadius={"50%"}
                cursor={auth ? "pointer" : "not-allowed"}
                _hover={{ background: "whitesmoke" }}
                width={"fit-content"}
                height={"fit-content"}
                padding={"10px"}
                justifySelf={"flex-end"}
                position={"absolute"}
                right={isSmallScreen ? "70" : "50"}
                bottom={isSmallScreen ? "-20" : "2"}
                background={"white"}
                onClick={(event) => {
                  if (auth) {
                    handle_bookmark(
                      event,
                      salonInformation.general_information[0]["business_id"]
                    );
                  }
                }}
              >
                <BsHeart size={"20px"} />
              </Box>
            </Flex>
          </Box>
          <Flex
            display={{ lg: "flex", base: "none" }}
            flexDirection={"row"}
            marginTop={"40px"}
            paddingX={{ md: "1%", xl: "3%", "1920px": "12%" }}
            justifyContent={"space-between"}
            height={"70vh"}
            position={"relative"}
          >
            {isOpen && (
              <Lightbox
                mainSrc={
                  salonInformation.album_images[currentIndex]["image_url"]
                }
                nextSrc={
                  salonInformation.album_images[
                    (currentIndex + 1) % salonInformation.album_images.length
                  ]["image_url"]
                }
                prevSrc={
                  salonInformation.album_images[
                    (currentIndex - 1 + salonInformation.album_images.length) %
                      salonInformation.album_images.length
                  ]["image_url"]
                }
                onCloseRequest={() => setIsOpen(false)}
                onMovePrevRequest={() =>
                  setCurrentIndex(
                    (currentIndex + 1) % salonInformation.album_images.length
                  )
                }
                onMoveNextRequest={() =>
                  setCurrentIndex(
                    (currentIndex - 1 + salonInformation.album_images.length) %
                      salonInformation.album_images.length
                  )
                }
              />
            )}
            <Flex width={"60%"} marginBottom={"50px"}>
              <Image
                src={salonInformation.album_images[0]["image_url"]}
                borderRadius={"8px"}
                height={"100%"}
                width={"100%"}
                objectFit={"cover"}
                backgroundPosition={"5%"}
                onClick={() => {
                  setIsOpen(true);
                  setCurrentIndex(0);
                }}
              />
            </Flex>
            <Flex
              width={"39%"}
              marginBottom={"50px"}
              flexDirection={"column"}
              justifyContent={"space-between"}
            >
              <Image
                src={
                  salonInformation.album_images[1]
                    ? salonInformation.album_images[1]["image_url"]
                    : null
                }
                onClick={() => {
                  setIsOpen(true);
                  setCurrentIndex(salonInformation.album_images[1] ? 1 : 0);
                }}
                fallbackSrc="https://via.placeholder.com/150x70"
                borderRadius={"8px"}
                height={"48.5%"}
                width={"100%"}
                objectFit={"cover"}
                backgroundPosition={"5%"}
              />
              <Image
                src={
                  salonInformation.album_images[2]
                    ? salonInformation.album_images[2]["image_url"]
                    : null
                }
                onClick={() => {
                  setIsOpen(true);
                  setCurrentIndex(salonInformation.album_images[2] ? 2 : 0);
                }}
                fallbackSrc="https://via.placeholder.com/150x70"
                borderRadius={"8px"}
                height={"48.5%"}
                width={"100%"}
                objectFit={"cover"}
                backgroundPosition={"5%"}
              />
            </Flex>
            <Button
              position={"absolute"}
              zIndex={2}
              bottom={"60px"}
              right={{ md: "30px", xl: "60px", "1920px": "250px" }}
              onClick={() => {
                setIsOpen(true);
                setCurrentIndex(0);
              }}
            >
              Se alle billeder
            </Button>
          </Flex>
          <Box
            display={{ lg: "none" }}
            flexDirection={"row"}
            marginTop={"30px"}
            paddingX={{ md: "1%", xl: "3%", "1920px": "12%" }}
            justifyContent={"space-between"}
            height={"60vh"}
          >
            <Image
              src={salonInformation.album_images[imageIndex]["image_url"]}
              borderRadius={"8px"}
              height={"100%"}
              width={"100%"}
              objectFit={"cover"}
              backgroundPosition={"15%"}
            />
          </Box>
          {/* Salon page sections : treatments - employees - general information - reviews  */}
          <Flex
            flexDirection={"row"}
            marginTop={"20px"}
            paddingX={{ md: "1%", xl: "3%", "1920px": "12%" }}
          >
            <Flex
              flex={7}
              height={"fit-content"}
              overflow={"hidden"}
              flexDirection={"column"}
              gap={"10px"}
            >
              <Flex marginBottom={"20px"}>
                <Heading size={"xl"} fontWeight={"600"}>
                  Tjenester
                </Heading>
              </Flex>
              <Flex
                flexDirection={"column"}
                gap={"20px"}
                width={"97%"}
                marginBottom={"50px"}
              >
                {salonTreatments.length === 0 ? (
                  <>
                    <Text
                      fontSize={"lg"}
                      fontWeight={"500"}
                      textAlign={"justify"}
                      width={"97%"}
                      lineHeight={"7"}
                      color={"gray"}
                    >
                      Der er ingen tjenester tildelt til kunden.
                    </Text>
                  </>
                ) : (
                  <>
                    <Tabs variant="soft-rounded">
                      <TabList>
                        <Tab
                          _selected={{
                            color: "white",
                            bg: ColorModeValue,
                          }}
                          color={ColorModeValue}
                          mr={"5px"}
                        >
                          Klipninger
                        </Tab>
                        <Tab
                          _selected={{
                            color: "white",
                            bg: ColorModeValue,
                          }}
                          color={ColorModeValue}
                          mr={"5px"}
                        >
                          Andet
                        </Tab>
                      </TabList>

                      <TabPanels>
                        <TabPanel paddingY={"25px"} paddingX={0}>
                          <Box width={"100%"} height={"fit-content"}>
                            {salonTreatments
                              .slice(0, treatments_display_count)
                              .map((treatement, index) => (
                                <>
                                  {treatement.Type === 0 ? (
                                    <>
                                      <Box
                                        key={index}
                                        width={"100%"}
                                        height={"fit-content"}
                                        border={"1px solid lightgray"}
                                        borderRadius={"12px"}
                                        padding={"20px"}
                                        cursor={"pointer"}
                                        _hover={{
                                          bg: "whitesmoke",
                                        }}
                                        mb={"15px"}
                                        onClick={() => {
                                          setCurrentTreatment(treatement);
                                          onTreatmentOpen();
                                        }}
                                      >
                                        <Flex
                                          justifyContent={"space-between"}
                                          alignItems={"center"}
                                        >
                                          <Box>
                                            <Text
                                              fontSize={"lg"}
                                              fontWeight={"500"}
                                              marginBottom={"15px"}
                                            >
                                              {treatement.Description}
                                            </Text>
                                            <Flex>
                                              <Text
                                                color={"gray.500"}
                                                fontWeight={"500"}
                                                mr={"10px"}
                                              >
                                                <span
                                                  style={{ color: "black" }}
                                                >
                                                  Varighed:
                                                </span>{" "}
                                                {treatement.Duration
                                                  ? treatement.Duration + "min"
                                                  : "utidsbestemt"}
                                              </Text>
                                              <Text
                                                color={"gray.500"}
                                                fontWeight={"500"}
                                              >
                                                <span
                                                  style={{ color: "black" }}
                                                >
                                                  Pris:
                                                </span>{" "}
                                                {treatement.UnitPrice
                                                  ? treatement.UnitPrice + "kr"
                                                  : "utidsbestemt"}
                                              </Text>
                                            </Flex>
                                          </Box>
                                          <Box>
                                            <IconButton
                                              aria-label="Add service"
                                              icon={<AddIcon />}
                                              size={"sm"}
                                              style={{
                                                transition:
                                                  "background-color 0.3s ease",
                                              }}
                                              onClick={(event) => {
                                                event.stopPropagation();
                                                setAppointment(
                                                  (prevAppointment) => ({
                                                    ...prevAppointment,
                                                    step: 2,
                                                    treatment: {
                                                      ...prevAppointment.treatment,
                                                      Name: treatement[
                                                        "Description"
                                                      ],
                                                      ID: treatement["TreatID"],
                                                      Type: treatement["Type"],
                                                      Price:
                                                        treatement["UnitPrice"],
                                                      Duration:
                                                        treatement["Duration"],
                                                      PriceFrom:
                                                        treatement[
                                                          "UnitPriceFrom"
                                                        ],
                                                      DurationFrom:
                                                        treatement[
                                                          "DurationFrom"
                                                        ],
                                                    },
                                                  })
                                                );
                                              }}
                                            />
                                          </Box>
                                        </Flex>
                                      </Box>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ))}
                          </Box>
                        </TabPanel>
                        <TabPanel paddingY={"25px"} paddingX={0}>
                          <Box width={"100%"} height={"fit-content"}>
                            {salonTreatments
                              .slice(0, treatments_display_count)
                              .map((treatement, index) => (
                                <>
                                  {treatement.Type === 1 ? (
                                    <>
                                      <Box
                                        key={index}
                                        width={"100%"}
                                        height={"fit-content"}
                                        border={"1px solid lightgray"}
                                        borderRadius={"12px"}
                                        padding={"20px"}
                                        cursor={"pointer"}
                                        _hover={{
                                          bg: "whitesmoke",
                                        }}
                                        mb={"15px"}
                                        onClick={() => {
                                          setCurrentTreatment(treatement);
                                          onTreatmentOpen();
                                        }}
                                      >
                                        <Flex
                                          justifyContent={"space-between"}
                                          alignItems={"center"}
                                        >
                                          <Box>
                                            <Text
                                              fontSize={"lg"}
                                              fontWeight={"500"}
                                              marginBottom={"15px"}
                                            >
                                              {treatement.Description}
                                            </Text>
                                            <Flex>
                                              <Text
                                                color={"gray.500"}
                                                fontWeight={"500"}
                                                mr={"10px"}
                                              >
                                                <span
                                                  style={{ color: "black" }}
                                                >
                                                  Varighed:
                                                </span>{" "}
                                                {treatement.Duration
                                                  ? treatement.Duration + "min"
                                                  : "utidsbestemt"}
                                              </Text>
                                              <Text
                                                color={"gray.500"}
                                                fontWeight={"500"}
                                              >
                                                <span
                                                  style={{ color: "black" }}
                                                >
                                                  Pris:
                                                </span>{" "}
                                                {treatement.UnitPrice
                                                  ? treatement.UnitPrice + "kr"
                                                  : "utidsbestemt"}
                                              </Text>
                                            </Flex>
                                          </Box>
                                          <Box>
                                            <IconButton
                                              aria-label="Add service"
                                              icon={<AddIcon />}
                                              size={"sm"}
                                              style={{
                                                transition:
                                                  "background-color 0.3s ease",
                                              }}
                                              onClick={(event) => {
                                                event.stopPropagation();
                                                setAppointment(
                                                  (prevAppointment) => ({
                                                    ...prevAppointment,
                                                    step: 2,
                                                    treatment: {
                                                      ...prevAppointment.treatment,
                                                      Name: treatement[
                                                        "Description"
                                                      ],
                                                      ID: treatement["TreatID"],
                                                      Type: treatement["Type"],
                                                      Price:
                                                        treatement["UnitPrice"],
                                                      Duration:
                                                        treatement["Duration"],
                                                      PriceFrom:
                                                        treatement[
                                                          "UnitPriceFrom"
                                                        ],
                                                      DurationFrom:
                                                        treatement[
                                                          "DurationFrom"
                                                        ],
                                                    },
                                                  })
                                                );
                                              }}
                                            />
                                          </Box>
                                        </Flex>
                                      </Box>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ))}
                          </Box>
                        </TabPanel>
                      </TabPanels>
                    </Tabs>
                  </>
                )}

                {all_treatments <= 5 ? (
                  <>
                    <Box
                      display={salonTreatments.length > 0 ? "flex" : "none"}
                      width={"fit-content"}
                      height={"fit-content"}
                      border={"1px solid lightgray"}
                      borderRadius={"12px"}
                      paddingX={"15px"}
                      paddingY={"13px"}
                      cursor={"no-drop"}
                      _hover={{ background: "whitesmoke" }}
                    >
                      <Text fontSize={"lg"} fontWeight={"500"}>
                        {treatments_display_count === 5
                          ? "Se alt"
                          : "Se mindre"}
                      </Text>
                    </Box>
                  </>
                ) : (
                  <Flex>
                    {treatments_display_count < all_treatments && (
                      <Box
                        display={salonTreatments.length > 0 ? "flex" : "none"}
                        width={"fit-content"}
                        height={"fit-content"}
                        border={"1px solid lightgray"}
                        borderRadius={"12px"}
                        paddingX={"15px"}
                        paddingY={"13px"}
                        cursor={"pointer"}
                        _hover={{ background: "whitesmoke" }}
                        onClick={handleShowMoreTreatments}
                      >
                        <Text fontSize={"lg"} fontWeight={"500"}>
                          {"Se alt"}
                        </Text>
                      </Box>
                    )}
                  </Flex>
                )}
              </Flex>
              <Flex marginBottom={"20px"}>
                <Heading size={"xl"} fontWeight={"600"}>
                  Medarbejdere
                </Heading>
              </Flex>
              <Flex
                width={"97%"}
                marginBottom={"20px"}
                flexWrap={"wrap"}
                gap={"20px"}
              >
                {salonEmployees.length === 0 ? (
                  <>
                    <Text
                      fontSize={"lg"}
                      fontWeight={"500"}
                      textAlign={"justify"}
                      width={"97%"}
                      lineHeight={"7"}
                      color={"gray"}
                    >
                      Der er ingen medarbejdere tildelt til opgaven.
                    </Text>
                  </>
                ) : (
                  <>
                    {salonEmployees
                      .slice(0, staffs_display_count)
                      .map((staff, index) => (
                        <Box
                          marginRight={"80px"}
                          key={index}
                          position={"relative"}
                        >
                          <Avatar
                            size="2xl"
                            name={staff.NameID.substring(0, 1)}
                            src={staff.Image}
                            objectFit={"contain"}
                            marginBottom={"20px"}
                            bg={"gray.200"}
                            color={"gray.700"}
                            zIndex={0}
                          />
                          <Text
                            fontSize={"lg"}
                            fontWeight={"500"}
                            textAlign={"center"}
                            mt={"5px"}
                          >
                            {staff.NameID}
                          </Text>
                          <Text
                            fontSize={"lg"}
                            fontWeight={"500"}
                            textAlign={"center"}
                            color={"gray"}
                          >
                            {staff.Job && staff.Job}
                          </Text>
                          <Flex
                            position={"absolute"}
                            zIndex={1}
                            top={"60%"}
                            left={"22%"}
                            backgroundColor={"#fff"}
                            border={"1px solid lightgray"}
                            width={"fit-content"}
                            height={"fit-content"}
                            paddingX={"13px"}
                            paddingY={"5px"}
                            borderRadius={"15px"}
                            textAlign={"center"}
                            alignItems={"center"}
                            gap={"5px"}
                          >
                            <Text fontSize={"md"} fontWeight={"500"}>
                              5.0
                            </Text>
                            <StarIcon
                              boxSize={4}
                              mb={"3px"}
                              color={"#ECC94B"}
                            />
                          </Flex>
                        </Box>
                      ))}
                  </>
                )}
              </Flex>
              <Flex marginBottom={"50px"}>
                {all_staffs <= 5 ? (
                  <>
                    {" "}
                    <Box
                      display={salonEmployees.length > 0 ? "flex" : "none"}
                      width={"fit-content"}
                      height={"fit-content"}
                      border={"1px solid lightgray"}
                      borderRadius={"12px"}
                      paddingX={"15px"}
                      paddingY={"13px"}
                      cursor={"no-drop"}
                      _hover={{ background: "whitesmoke" }}
                    >
                      <Text fontSize={"lg"} fontWeight={"500"}>
                        {staffs_display_count === 5 ? "Se alt" : "Se mindre"}
                      </Text>
                    </Box>
                  </>
                ) : (
                  <>
                    <Box
                      display={salonEmployees.length > 0 ? "flex" : "none"}
                      width={"fit-content"}
                      height={"fit-content"}
                      border={"1px solid lightgray"}
                      borderRadius={"12px"}
                      paddingX={"15px"}
                      paddingY={"13px"}
                      cursor={"pointer"}
                      _hover={{ background: "whitesmoke" }}
                      onClick={
                        staffs_display_count === 5
                          ? handleShowMoreStaffs
                          : handleShowLessStaffs
                      }
                    >
                      <Text fontSize={"lg"} fontWeight={"500"}>
                        {staffs_display_count === 5 ? "Se alt" : "Se mindre"}
                      </Text>
                    </Box>
                  </>
                )}
              </Flex>
              <Flex marginBottom={"10px"}>
                <Heading size={"xl"} fontWeight={"600"}>
                  Om
                </Heading>
              </Flex>
              <Flex marginBottom={"20px"}>
                <Text
                  fontSize={"md"}
                  fontWeight={"500"}
                  textAlign={"justify"}
                  width={"97%"}
                  lineHeight={"7"}
                >
                  {salonInformation.general_information[0]["description"]}
                </Text>
              </Flex>
              <Flex marginBottom={"10px"}>
                <Box
                  width={"97%"}
                  height={{ base: "300px", md: "500px" }}
                  borderRadius={"12px"}
                >
                  <MapContainer
                    center={[
                      salonInformation.general_information[0]["latitude"],
                      salonInformation.general_information[0]["longitude"],
                    ]}
                    zoom={14}
                    style={{
                      height: "100%",
                      width: "100%",
                      zIndex: 1,
                      borderRadius: "12px",
                    }}
                  >
                    <ReactLeafletGoogleLayer
                      apiKey="AIzaSyDEVQV4EJJVG38U21BYV5jp1pvbCHRyQNM"
                      type={"roadmap"}
                      style={{ zIndex: 1, borderRadius: "12px" }}
                    />
                    <Marker
                      key={
                        salonInformation.general_information[0]["business_id"]
                      }
                      position={[
                        salonInformation.general_information[0]["latitude"],
                        salonInformation.general_information[0]["longitude"],
                      ]}
                      eventHandlers={{
                        mouseover: () =>
                          handleMarkerHover(
                            salonInformation.general_information[0][
                              "business_id"
                            ]
                          ),
                        mouseout: handleMarkerMouseLeave,
                      }}
                      zIndexOffset={
                        hoveredMarker ===
                        salonInformation.general_information[0]["business_id"]
                          ? 1
                          : 0
                      } // Higher zIndex for hovered marker
                      icon={CustomIcon({
                        rating:
                          Math.round(salonInformation.salon_rating * 2) / 2,
                        isHovered:
                          hoveredMarker ===
                          salonInformation.general_information[0][
                            "business_id"
                          ],
                        showAnimation,
                      })}
                    ></Marker>
                  </MapContainer>
                </Box>
              </Flex>
              <Flex marginBottom={"40px"}>
                <Text fontSize={"md"} fontWeight={"500"}>
                  {salonInformation.general_information[0]["address"]} {", "}{" "}
                  {salonInformation.general_information[0]["city"]}{" "}
                  <span
                    style={{ color: "blue", cursor: "pointer" }}
                    onClick={() =>
                      handleGetDirection(
                        salonInformation.general_information[0]["latitude"],
                        salonInformation.general_information[0]["longitude"]
                      )
                    }
                  >
                    Få vejledninger
                  </span>
                </Text>
              </Flex>
              <Flex
                marginBottom={"50px"}
                flexDirection={{ base: "column", md: "row" }}
              >
                {/* Schedule section */}
                <Flex
                  flex={5}
                  height={"fit-content"}
                  overflow={"hidden"}
                  flexDirection={"column"}
                >
                  <Heading
                    size={isSmallScreen ? "md" : "lg"}
                    fontWeight={"500"}
                    marginBottom={"20px"}
                  >
                    Åbningstider
                  </Heading>

                  {saloon_schedule &&
                    saloon_schedule.map(([day, schedule], index) => (
                      <Flex
                        justifyContent={"space-between"}
                        marginRight={isSmallScreen ? "0" : "30px"}
                        marginBottom={"5px"}
                        key={index}
                      >
                        <Text
                          fontSize={isSmallScreen ? "sm" : "lg"}
                          fontWeight={"400"}
                        >
                          {DanishDays[day]}
                        </Text>
                        <Text
                          fontSize={isSmallScreen ? "sm" : "lg"}
                          fontWeight={"400"}
                        >
                          {schedule.offday === 1
                            ? "Lukket"
                            : schedule.startTime + " - " + schedule.endTime}
                        </Text>
                      </Flex>
                    ))}
                </Flex>
                {/* Contact section */}
                <Flex
                  flex={5}
                  height={"fit-content"}
                  overflow={"hidden"}
                  flexDirection={"column"}
                  paddingLeft={{ base: "0", md: "90px" }}
                  marginTop={{ base: "20px", md: "0" }}
                >
                  <Heading
                    size={isSmallScreen ? "md" : "lg"}
                    fontWeight={"500"}
                    marginBottom={"20px"}
                  >
                    Kontakt information
                  </Heading>
                  <Flex
                    gap={"15px"}
                    alignItems={"center"}
                    marginBottom={"20px"}
                  >
                    <BsTelephoneFill />
                    <Text
                      fontWeight={"500"}
                      fontSize={isSmallScreen ? "md" : "lg"}
                    >
                      {salonInformation.general_information[0]["phone_number"]}
                    </Text>
                  </Flex>
                  <Flex gap={"15px"} alignItems={"center"}>
                    <MdOutlineAlternateEmail />
                    <Text
                      fontWeight={"500"}
                      fontSize={isSmallScreen ? "md" : "lg"}
                    >
                      {salonInformation.general_information[0]["email"]}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
              {/* Reviews section  */}
              <Flex
                marginBottom={"50px"}
                flexDirection={"column"}
                id="review"
                ref={reviewRef}
              >
                <Flex marginBottom={"35px"}>
                  <Heading size={"xl"} fontWeight={"600"}>
                    Anmeldelser
                  </Heading>
                </Flex>
                {salonInformation.number_review > 0 ? (
                  <Grid
                    templateColumns={{ sm: "1fr", xl: "repeat(2, 1fr)" }}
                    gap="22px"
                    rowGap={"50px"}
                    width={"100%"}
                    marginBottom={"25px"}
                  >
                    {salonInformation.reviews.map((review_, index) => (
                      <GridItem colSpan={{ base: 1, md: 1, lg: 1 }} key={index}>
                        <Flex flexDirection={"column"}>
                          <Flex
                            flexDirection={"row"}
                            gap={"10px"}
                            alignItems={"center"}
                          >
                            <Avatar
                              size="xl"
                              name={"Philip Castel"}
                              src="https://bit.ly/broken-link"
                              marginBottom={"10px"}
                              bg={"gray.200"}
                              color={"gray.700"}
                            />
                            <Flex flexDirection={"column"}>
                              <Text fontWeight={"bold"}>
                                {review_.client_name}
                              </Text>
                              <Text color={"gray.600"}>
                                {formatDateString(review_.created_at)}
                              </Text>
                            </Flex>
                          </Flex>
                          <Flex gap={"5px"}>
                            {Array(5)
                              .fill("")
                              .map((_, i) => {
                                const roundedRating =
                                  Math.round(review_.rating * 2) / 2;
                                if (roundedRating - i >= 1) {
                                  return (
                                    <BsStarFill
                                      key={i}
                                      color={"#ECC94B"}
                                      size={"16px"}
                                      style={{ marginTop: "7px" }}
                                    />
                                  );
                                }
                                if (roundedRating - i === 0.5) {
                                  // Add the return statement here
                                  return (
                                    <BsStarHalf
                                      key={i}
                                      color={"#ECC94B"}
                                      size={"16px"}
                                      style={{ marginTop: "7px" }}
                                    />
                                  );
                                } else {
                                  return (
                                    <BsStar
                                      key={i}
                                      color={"#ECC94B"}
                                      size={"16px"}
                                      style={{ marginTop: "7px" }}
                                    />
                                  );
                                }
                              })}
                          </Flex>

                          <Text
                            marginTop={"10px"}
                            fontSize={"lg"}
                            fontWeight={"500"}
                            maxW={{ lg: "30vw", base: "80%" }}
                          >
                            {review_.review.length > 30 ? (
                              <>
                                {showFullReviews[index]
                                  ? `${review_.review} `
                                  : `${review_.review.slice(0, 30)}... `}
                                <br />
                                {!showFullReviews[index] && (
                                  <Button
                                    onClick={() => toggleReview(index)}
                                    size="sm"
                                    pl={0}
                                    color={"#00879d"}
                                    background={"none"}
                                    _hover={{ background: "none" }}
                                  >
                                    Mere
                                  </Button>
                                )}
                              </>
                            ) : (
                              <Text>{review_.review}</Text>
                            )}
                          </Text>
                        </Flex>
                      </GridItem>
                    ))}
                  </Grid>
                ) : (
                  <Text
                    fontSize={"lg"}
                    fontWeight={"500"}
                    textAlign={"justify"}
                    width={"97%"}
                    lineHeight={"7"}
                    color={"gray"}
                  >
                    ingen anmeldelser endnu.
                  </Text>
                )}
                <Flex marginBottom={"50px"}>
                  <Box
                    display={"flex"}
                    width={"fit-content"}
                    height={"fit-content"}
                    border={"1px solid lightgray"}
                    borderRadius={"12px"}
                    paddingX={"15px"}
                    paddingY={"13px"}
                    cursor={"no-drop"}
                    _hover={{ background: "whitesmoke" }}
                  >
                    <Text fontSize={"lg"} fontWeight={"500"}>
                      Se alt
                    </Text>
                  </Box>
                </Flex>
              </Flex>
            </Flex>
            {/* Right flex card  */}
            <Flex
              display={isSmallScreen ? "none" : "flex"}
              flex={3}
              justifyContent={"left"}
              overflow={"hidden"}
              height={"fit-content"}
              marginBottom={"20px"}
              position="sticky"
              top={100}
              right={0}
              zIndex={3}
            >
              <Box
                width={"100%"}
                height={"fit-content"}
                borderRadius={"12px"}
                border={"1px solid lightgray"}
                flexDirection={"column"}
              >
                <Heading size={"xl"} padding={"20px"} fontWeight={"700"}>
                  {salonInformation.general_information[0]["name"]}
                </Heading>
                <Flex px={"20px"} py={"5px"} gap={"10px"} alignItems={"center"}>
                  {salonInformation.salon_rating ? (
                    <>
                      <Text fontSize={"2xl"} fontWeight={"600"}>
                        {Math.round(salonInformation.salon_rating * 2) / 2}
                      </Text>
                      {Array(5)
                        .fill("")
                        .map((_, i) => {
                          const roundedRating =
                            Math.round(salonInformation.salon_rating * 2) / 2;
                          if (roundedRating - i >= 1) {
                            return (
                              <BsStarFill
                                key={i}
                                color={"#ECC94B"}
                                size={"20px"}
                              />
                            );
                          }
                          if (roundedRating - i === 0.5) {
                            // Add the return statement here
                            return (
                              <BsStarHalf
                                key={i}
                                color={"#ECC94B"}
                                size={"20px"}
                              />
                            );
                          } else {
                            return (
                              <BsStar key={i} color={"#ECC94B"} size={"20px"} />
                            );
                          }
                        })}
                      <Link
                        _hover={{ textDecoration: "none" }}
                        onClick={handleScrollToReview}
                        target="blank"
                      >
                        <Text
                          fontSize={"2xl"}
                          fontWeight={"500"}
                          color={"teal"}
                        >
                          ({salonInformation.number_review})
                        </Text>
                      </Link>
                    </>
                  ) : (
                    <>
                      <Text fontSize={"2xl"} fontWeight={"600"} color={"teal"}>
                        Ny Salon
                      </Text>
                    </>
                  )}
                </Flex>
                <Tag
                  size={"lg"}
                  variant="solid"
                  background={"gray.100"}
                  color={"gray.700"}
                  mx={"20px"}
                  mt={"10px"}
                >
                  {salonInformation.general_information[0]["category_label"]}
                </Tag>
                <Flex
                  padding={"20px"}
                  width={"100%"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Button
                    size={"lg"}
                    width={"100%"}
                    mt={2}
                    background={"#23a994"}
                    _hover={{ bg: "#23a994", transform: "scale(1.05)" }}
                    style={{ transition: "all 250ms ease-in" }}
                    onClick={() => {
                      const currentPath = window.location.pathname;
                      const newPath = `${currentPath}/booking`;

                      // Use the history object to navigate to the new path
                      navigate(newPath);
                    }}
                  >
                    Book Nu
                  </Button>
                </Flex>
                <Flex width={"100%"} marginTop={3}>
                  <Box
                    width={"100%"}
                    padding={"20px"}
                    borderTop={"1px solid lightgray"}
                  >
                    <Flex marginTop={"20px"} gap={"10px"} alignItems={"center"}>
                      <MdOutlineMyLocation size={"25px"} />
                      <Text
                        fontWeight={"500"}
                        alignSelf={"center"}
                        textAlign={"left"}
                      >
                        {salonInformation.general_information[0]["address"]}{" "}
                        {", "} {salonInformation.general_information[0]["city"]}{" "}
                        <span
                          style={{
                            marginLeft: "5px",
                            color: "blue",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            handleGetDirection(
                              salonInformation.general_information[0][
                                "latitude"
                              ],
                              salonInformation.general_information[0][
                                "longitude"
                              ]
                            )
                          }
                        >
                          Få vejledninger
                        </span>
                      </Text>
                    </Flex>
                  </Box>
                </Flex>
              </Box>
            </Flex>
            {/* Handle small screen booking */}
            <Flex
              display={isSmallScreen ? "flex" : "none"}
              position="fixed"
              bottom={"0"}
              left={"0"}
              width={"100%"}
              justifyContent={"space-between"}
              height={"fit-content"}
              padding={"15px"}
              background={"white"}
              alignItems={"center"}
              boxShadow="rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em"
              zIndex={"5"}
            >
              <Text fontWeight={"600"}>
                {salonInformation.general_information[0]["name"]}
              </Text>

              <>
                <Button
                  size={"lg"}
                  background={"#23a994"}
                  _hover={{ bg: "#23a994" }}
                >
                  Book nu
                </Button>
              </>
            </Flex>
          </Flex>
          {/* Footer section */}
          <Box>
            <MarketplaceFooter />
          </Box>
          {/* Treatment big description modal */}
          <Modal
            isCentered
            onClose={onTreatmentClose}
            isOpen={isTreatmentOpen}
            motionPreset="slideInBottom"
            size={"2xl"}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader marginTop={"40px"}>
                {currentTreatment && currentTreatment["Description"]}
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Box width={"100%"} height={"fit-content"}>
                  <Text color={"gray"} fontWeight={"500"}>
                    {currentTreatment && currentTreatment["DurationFrom"]
                      ? "from"
                      : ""}{" "}
                    {currentTreatment && currentTreatment["Duration"]} min
                  </Text>
                  <Text fontWeight={"600"} mb={"30px"}>
                    {currentTreatment && currentTreatment["UnitPriceFrom"]
                      ? "from"
                      : ""}{" "}
                    {currentTreatment && currentTreatment["UnitPrice"]} DKK
                  </Text>
                  <Text fontSize={"sm"} fontWeight={"500"}>
                    {currentTreatment && currentTreatment["BigDescription"]}
                  </Text>
                </Box>
              </ModalBody>
              <ModalFooter>
                <Button
                  backgroundColor={"#23a994"}
                  width={"100%"}
                  height={"45px"}
                  _hover={{ bg: "#23a994", transform: "scale(1.05)" }}
                  style={{ transition: "all 250ms ease-in" }}
                  mb={"10px"}
                  onClick={() => {
                    setAppointment((prevAppointment) => ({
                      ...prevAppointment,
                      step: 2,
                      treatment: {
                        ...prevAppointment.treatment,
                        Name: currentTreatment["Description"],
                        ID: currentTreatment["TreatID"],
                        Type: currentTreatment["Type"],
                        Price: currentTreatment["UnitPrice"],
                        Duration: currentTreatment["Duration"],
                        PriceFrom: currentTreatment["UnitPriceFrom"],
                        DurationFrom: currentTreatment["DurationFrom"],
                      },
                    }));
                  }}
                >
                  Tilføj til booking
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </div>
      )}
    </>
  ) : null;
}

export default MarketplaceDirectSalon;
