import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

function BookingPageConfirm({ appointment, setAppointment }) {
  const nameRef = useRef();
  const emailRef = useRef();
  const phoneRef = useRef();
  const addressRef = useRef();
  const noteRef = useRef();
  const [refresh, setRefresh] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  useEffect(() => {
    const GetClientInfos = async () => {
      try {
        const response = await axios.get(
          "direct_salon/web/personal-information"
        );

        //Verify if there are infos about client
        if (response.data.client_personal_info) {
          //If he didn't enter some values before we will directly update them with those in our db about him
          //else we will keep what he enters
          if (appointment.client.Name === "") {
            nameRef.current.value = response.data.client_personal_info.name;
          } else {
            nameRef.current.value = appointment.client.Name;
          }
          if (appointment.client.Phone === "") {
            phoneRef.current.value = response.data.client_personal_info.phone;
          } else {
            phoneRef.current.value = appointment.client.Phone;
          }
          if (appointment.client.Email === "") {
            emailRef.current.value = response.data.client_personal_info.email;
          } else {
            emailRef.current.value = appointment.client.Email;
          }
          if (appointment.client.Address === "") {
            addressRef.current.value =
              response.data.client_personal_info.adress;
          } else {
            addressRef.current.value = appointment.client.Address;
          }
        } else {
          nameRef.current.value = appointment.client.Name;
          phoneRef.current.value = appointment.client.Phone;
          emailRef.current.value = appointment.client.Email;
          addressRef.current.value = appointment.client.Address;
          noteRef.current.value = appointment.client.Note;
        }
        switch (response.status) {
          case 500:
            toast({
              title: "Server Fejl",
              description: "Prøv venligst igen om et øjeblik",
              position: "top-right",
              isClosable: true,
              status: "error",
            });
            navigate("/");
            break;
        }
      } catch (error) {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et øjeblik",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
        navigate("/");
      }
    };
    GetClientInfos();
  }, []);
  useEffect(() => {
    setAppointment((prevAppointment) => ({
      ...prevAppointment,
      client: {
        ...prevAppointment.client,
        Name: nameRef.current.value,
        Phone: phoneRef.current.value,
        Email: emailRef.current.value,
        Address: addressRef.current.value,
        Note: noteRef.current.value,
        Complete:
          nameRef.current.value != "" &&
          phoneRef.current.value !== "" &&
          emailRef.current.value !== "",
      },
    }));
  }, [refresh]);
  return (
    <>
      <Box width={"100%"} height={"fit-content"}>
        <Box width={"100%"} height={"fit-content"} mb={"30px"}>
          <Heading fontWeight={"600"}>Gennemgå og Bekræft</Heading>
        </Box>
        <Box width={"100%"} height={"fit-content"} mb={"35px"}>
          <Text fontSize={"lg"} fontWeight={"600"} mb={"5px"}>
            Gennemgå og bekræft
          </Text>
          <Text fontWeight={"400"} fontSize={"sm"} mb={"10px"}>
            Du bliver ikke opkrævet nu, betalingen opkræves i butikken efter din
            aftale
          </Text>
          <FormControl mb={"10px"} isRequired>
            <FormLabel>Fulde navn</FormLabel>
            <Input
              type="text"
              ref={nameRef}
              onChange={() => setRefresh(!refresh)}
            />
          </FormControl>
          <Flex alignItems={"center"} gap={"15px"} mb={"10px"}>
            <FormControl mb={"10px"} isRequired>
              <FormLabel>E-mail</FormLabel>
              <Input
                type="email"
                ref={emailRef}
                onChange={() => setRefresh(!refresh)}
              />
            </FormControl>
            <FormControl mb={"10px"} isRequired>
              <FormLabel>Telefonnummer</FormLabel>
              <Input
                type="tel"
                ref={phoneRef}
                onChange={() => setRefresh(!refresh)}
              />
            </FormControl>
          </Flex>
          <FormControl mb={"10px"}>
            <FormLabel>Adresse (valgfri)</FormLabel>
            <Input
              type="text"
              ref={addressRef}
              onChange={() => setRefresh(!refresh)}
            />
          </FormControl>
        </Box>
        <Box width={"100%"} height={"fit-content"} mb={"30px"}>
          <Text fontSize={"md"} fontWeight={"500"} mb={"15px"}>
            Bemærkning (valgfri)
          </Text>
          <Textarea
            resize="none"
            height={"150px"}
            placeholder="Inkluder kommentarer eller anmodninger vedrørende din reservation"
            ref={noteRef}
            onChange={() => setRefresh(!refresh)}
          />
        </Box>
      </Box>
    </>
  );
}

export default BookingPageConfirm;
