import axios from "axios";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
import { createContext, useEffect, useState } from "react";

export const Clientauthcontext = createContext();

export const ClientAuthContextProvider = ({ children }) => {
  const [currentclient, setCurrentclient] = useState(
    JSON.parse(localStorage.getItem("clientuser")) || null
  );

  const loginclient = async (inputs) => {
    const res = await axios.post("client/web/signin/", inputs);
    Cookies.set("user_id", res.data.client_id.toString());
    setEncryptedCookie(res.data.client_id.toString());
    return res;
  };

  const registerclient = async (inputs) => {
    const res = await axios.post("client/web/register/", inputs);
    return res;
  };

  const confirmclient = async (inputs) => {
    const res = await axios.post("client/web/confirm/", inputs);
    Cookies.set("user_id", res.data.client_id.toString());
    setEncryptedCookie(res.data.client_id.toString());
    return res;
  };

  const fergotpassconfirm = async (inputs) => {
    const res = await axios.post("client/web/forgot-password/confirm/", inputs);
    return res;
  };

  const logoutclient = async () => {
    const res = await axios.get("client/web/signout");
    Cookies.remove("user_id");
    Cookies.remove("auth_token");
    return res;
  };

  const resetpassword = async (inputs) => {
    const res = await axios.put("client/web/reset", inputs);
    Cookies.set("user_id", res.data.client_id.toString());
    setEncryptedCookie(res.data.client_id.toString());
    return res;
  };

  /*********************************************************/

  const googlelogin = async (inputs) => {
    const res = await axios.post("client/google/login", inputs);
    setCurrentclient(res.data);
    return res;
  };

  const facebooklogin = async (inputs) => {
    const res = await axios.post("client/facebook/login", inputs);
    setCurrentclient(res.data);
    return res;
  };

  useEffect(() => {
    localStorage.setItem("clientuser", JSON.stringify(currentclient));
  }, [currentclient]);

  return (
    <Clientauthcontext.Provider
      value={{
        currentclient,
        loginclient,
        logoutclient,
        googlelogin,
        facebooklogin,
        confirmclient,
        resetpassword,
        registerclient,
        fergotpassconfirm,
      }}
    >
      {children}
    </Clientauthcontext.Provider>
  );
};

const setEncryptedCookie = (cookieValue) => {
  const encryptedValue = encryptCookieValue(cookieValue); // Replace with your encryption logic
  Cookies.set("auth_token", encryptedValue);
};

const encryptCookieValue = (value) => {
  const secretKey = "bookinghero";
  const encryptedValue = CryptoJS.AES.encrypt(value, secretKey).toString();
  return encryptedValue;
};
