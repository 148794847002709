import React, { useContext, useEffect } from "react";
import "./MarketplaceResetPass.css";
import {
  Button,
  FormControl,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { Clientauthcontext } from "./context/Clientauthcontext";

function MarketplaceResetPass() {
  const { resetpassword } = useContext(Clientauthcontext);

  const location = useLocation();

  const [clientState, setClientState] = useState({
    client_user_id: location.state?.client_user_id, // Set the initial value to null or empty
  });

  useEffect(() => {
    // Check if the variable exists in the state during component initialization
    if (!clientState.client_user_id) {
      navigate("/"); // Redirect to the homepage if client_user_id is falsy
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientState.client_user_id]);

  const [isLoading, setisLoading] = useState(false);

  const [show, setShow] = useState(false);

  const [rshow, rsetShow] = useState(false);

  const navigate = useNavigate();

  const toast = useToast();

  const [inputs, setInputs] = useState({
    client_password: "",
    client_repeat_password: "",
  });

  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handle_submit = async (e) => {
    e.preventDefault();
    setisLoading(true);
    const data = {
      client_password: inputs.client_password,
      client_id: parseInt(clientState.client_user_id),
    };
    try {
      if (
        inputs.client_password === "" ||
        inputs.client_repeat_password === ""
      ) {
        toast({
          title: "Skriv fejl",
          description: "E-mail- eller adgangskodefeltet er tomt",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
        setisLoading(false);
      } else {
        if (inputs.client_password !== inputs.client_repeat_password) {
          toast({
            title: "Skriv fejl",
            description: "E-mail- eller adgangskodefeltet er tomt",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
          setisLoading(false);
        } else {
          const res = await resetpassword(data);

          if (res.status === 200) {
            navigate("/");
            setisLoading(false);
          }
        }
      }
    } catch (err) {
      if (err.response.status === 404) {
        toast({
          title: "Confirm fejl",
          description: err.response.data.message,
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      } else if (err.response.status === 500) {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et øjeblik",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      setisLoading(false);
    }
  };

  return clientState.client_user_id ? (
    <>
      <div className="reset-pass">
        <div className="Left">
          <Link href={"/"} className="logo_container">
            <img src="../assets/Logo/logo.png" alt="" className="logo"></img>
          </Link>
          <div className="Header">Nulstil dit adgangskode</div>
          <div className="Subheader">
            Indtast venligst din nye adgangskode og bekræft den.
          </div>
          <FormControl className="emailInput">
            <FormLabel>Adgangskode</FormLabel>
            <InputGroup size="lg">
              <Input
                name="client_password"
                required
                type={show ? "text" : "password"}
                variant="filled"
                onChange={handleChange}
              />
              <InputRightElement width="4.5rem">
                <Button h="2rem" size="sm" onClick={() => setShow(!show)}>
                  <Icon
                    as={show ? AiFillEye : AiFillEyeInvisible}
                    h={5}
                    w={5}
                  />
                </Button>
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <FormControl className="emailInput">
            <FormLabel>Gentag adgangskode</FormLabel>
            <InputGroup size="lg">
              <Input
                name="client_repeat_password"
                required
                type={rshow ? "text" : "password"}
                variant="filled"
                onChange={handleChange}
              />
              <InputRightElement width="4.5rem">
                <Button h="2rem" size="sm" onClick={() => rsetShow(!rshow)}>
                  <Icon
                    as={rshow ? AiFillEye : AiFillEyeInvisible}
                    h={5}
                    w={5}
                  />
                </Button>
              </InputRightElement>
            </InputGroup>
          </FormControl>

          {!isLoading ? (
            <>
              <Button className="Submitbtn" onClick={handle_submit}>
                Nulstil
              </Button>
            </>
          ) : (
            <>
              <Button className="Submitbtn" isLoading loadingText="Indlæser">
                Nulstil
              </Button>
            </>
          )}
        </div>
        <div className="Right">
          <div className="Imagecontainer">
            <div className="Headerscontainer">
              <div className="Header">DIN GENVEJ TIL VELVÆRE</div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : null;
}

export default MarketplaceResetPass;
