import { Box, Flex, Heading, Link, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import MarketplaceNavbar from "../components/MarketplaceNavbar.tsx";
import MarketplaceFooter from "../components/MarketplaceFooter.tsx";
import {
  getStarted,
  forgotPassword,
  manageAccount,
  dataSecurity,
  dataExperience,
  cancelAccount,
  marketplaceHow,
  favorits,
  manageAppointments,
  marketCovarage,
  paymentSystem,
  paymentReceipts,
  paymentMethods,
  paymentCoupons,
  cancelAppointment,
  cancelReason,
  cancelBefore,
  withdrawCancel,
  cancelEffect,
} from "./constants/AccountText.js";
import { useParams } from "react-router-dom";

function SupportParagraph() {
  const { subSupportId } = useParams();

  const [SupportData, setSupportData] = useState(
    subSupportId === "get-started"
      ? getStarted
      : subSupportId === "reset-password"
      ? forgotPassword
      : subSupportId === "account-information"
      ? manageAccount
      : subSupportId === "security"
      ? dataSecurity
      : subSupportId === "data"
      ? dataExperience
      : subSupportId === "cancel"
      ? cancelAccount
      : subSupportId === "marketplace"
      ? marketplaceHow
      : subSupportId === "favorites"
      ? favorits
      : subSupportId === "appointments"
      ? manageAppointments
      : subSupportId === "supported-areas"
      ? marketCovarage
      : subSupportId === "system"
      ? paymentSystem
      : subSupportId === "receipts"
      ? paymentReceipts
      : subSupportId === "methods"
      ? paymentMethods
      : subSupportId === "coupons"
      ? paymentCoupons
      : subSupportId === "steps"
      ? cancelAppointment
      : subSupportId === "saloon-cancel"
      ? cancelReason
      : subSupportId === "timing"
      ? cancelBefore
      : subSupportId === "withdraw"
      ? withdrawCancel
      : subSupportId === "client-status"
      ? cancelEffect
      : cancelAccount
  );

  return (
    <>
      <Box position="sticky" top={0} zIndex={3}>
        <MarketplaceNavbar />
      </Box>
      <Flex
        minH={"80vh"}
        paddingY={"5rem"}
        width={"100%"}
        flexDirection={"row"}
        justifyContent={"center"}
        gap={"5rem"}
      >
        <Flex
          flexDirection={"column"}
          gap={"2rem"}
          height={"fit-content"}
          width={"420px"}
          position="sticky"
          top={"100px"}
        >
          <Heading size={"md"}>Similaire articles</Heading>
          {SupportData.links.map((link, index) => (
            <>
              <Link key={index} href={link.link}>
                <Text>{link.title}</Text>
              </Link>
            </>
          ))}
        </Flex>
        <Flex
          flexDirection={"column"}
          alignSelf={"center"}
          width={{ md: "800px", "2xl": "700px" }}
          alignItems={"flex-start"}
          gap={"3rem"}
        >
          <Heading size={"xl"} color={"#23a994"}>
            {SupportData.title}
          </Heading>
          {SupportData.paragraphs.map((paragraph, index) => (
            <>
              <Flex flexDirection={"column"}>
                <Heading size={"md"} marginBottom={"10px"} color={"#23a994"}>
                  {paragraph.title}
                </Heading>
                <Text
                  textAlign={"justify"}
                  whiteSpace="pre-line"
                  dangerouslySetInnerHTML={{ __html: paragraph.text }}
                />
              </Flex>
            </>
          ))}
        </Flex>
      </Flex>
      <MarketplaceFooter />
    </>
  );
}

export default SupportParagraph;
