import { Box, Heading, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import WeekPicker from "./WeekPicker";
import axios from "axios";

function BookingPageTime({ appointment, setAppointment }) {
  const toast = useToast();
  const [slots, setSlots] = useState();
  const [refresh, setRefresh] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  //to get the current day in the format year-month-day
  const getCurrentDate = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(currentDate.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const [currentDate, setCurrentDate] = useState(getCurrentDate());
  //get slots for the the next week of current day
  useEffect(() => {
    const fetchData = async () => {
      const url = "direct_salon/web/available-times";
      const data = {
        salon_slug: appointment.SalonSlug,
        date: currentDate,
        employee_id: appointment.employee.ID,
        treatment_id: appointment.treatment.ID,
      };

      try {
        setIsLoading(true);
        const response = await axios.post(url, data);
        setSlots(response.data.salon_available_times);
        setRefresh(!refresh);
        setIsLoading(false);
      } catch (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log("Response data:", error.response.data);
          console.log("Status code:", error.response.status);

          if (error.response.status === 500) {
            toast({
              title: "Server Error",
              description: "Prøv venligst igen om et øjeblik",
              position: "top-right",
              isClosable: true,
              status: "error",
            });
          } else if (error.response.status === 404) {
            toast({
              title: "Session expired",
              description: "Prøv venligst igen om et øjeblik",
              position: "top-right",
              isClosable: true,
              status: "error",
            });
          } else if (error.response.status === 405) {
            toast({
              title: "Inactive Salon",
              description: "Prøv venligst igen om et øjeblik",
              position: "top-right",
              isClosable: true,
              status: "error",
            });
          } else if (error.response.status === 406) {
            toast({
              title: "Salon account not connected",
              description: "Prøv venligst igen om et øjeblik",
              position: "top-right",
              isClosable: true,
              status: "error",
            });
          } else if (error.response.status === 407) {
            toast({
              title: "Incomplete salon account",
              description: "Prøv venligst igen om et øjeblik",
              position: "top-right",
              isClosable: true,
              status: "error",
            });
          }
        } else {
          toast({
            title: "Error with the request",
            description: "Prøv venligst igen om et øjeblik",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        }
      }
    };
    fetchData();
  }, [currentDate]);

  //Get previous seven days slots
  const getPrevious = async (firstdate) => {
    const url = "direct_salon/web/available-times-previous";
    const data = {
      salon_slug: appointment.SalonSlug,
      date: firstdate,
      employee_id: appointment.employee.ID,
      treatment_id: appointment.treatment.ID,
    };

    try {
      setIsLoading(true);
      const response = await axios.post(url, data);
      setSlots(response.data.salon_available_times.reverse());
      setRefresh(!refresh); //to refresh the front displaying slots
      setIsLoading(false);
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log("Response data:", error.response.data);
        console.log("Status code:", error.response.status);

        if (error.response.status === 500) {
          // Handle server error (status code 500)
        } else if (error.response.status === 404) {
          // Handle session error (status code 404)
        } else if (error.response.status === 405) {
          // Handle inactive salon business account (status code 405)
        } else if (error.response.status === 406) {
          // Handle not connected salon business account (status code 406)
        } else if (error.response.status === 407) {
          // Handle incomplete salon business profile (status code 407)
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.log("No response received:", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error during request setup:", error.message);
      }
    }
  };

  return (
    <>
      <Box width={"100%"} height={"fit-content"}>
        <Box width={"100%"} height={"fit-content"} mb={"40px"}>
          <Heading fontWeight={"600"}>Vælg Tid</Heading>
        </Box>

        <Box width={"100%"} height={"fit-content"}>
          {slots && (
            <WeekPicker
              appointment={appointment}
              setAppointment={setAppointment}
              slots={slots}
              currentDate={currentDate}
              setCurrentDate={setCurrentDate}
              refresh={refresh}
              setRefresh={setRefresh}
              getPrevious={getPrevious}
              isLoading={isLoading}
            />
          )}
        </Box>
      </Box>
    </>
  );
}

export default BookingPageTime;
