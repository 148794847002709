import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./MarketplaceConfirmOtp.css";
import {
  Button,
  HStack,
  Link,
  PinInput,
  PinInputField,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { useContext } from "react";
import { Clientauthcontext } from "./context/Clientauthcontext";

function MarketplaceConfirmOtp() {
  const { confirmclient } = useContext(Clientauthcontext);

  const location = useLocation();

  const navigate = useNavigate();

  const [clientState, setClientState] = useState({
    client_user_id: location.state?.client_user_id, // Set the initial value to null or empty
  });

  useEffect(() => {
    // Check if the variable exists in the state during component initialization
    if (!clientState.client_user_id) {
      navigate("/"); // Redirect to the homepage if client_user_id is falsy
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientState.client_user_id]);

  const [isLoading, setisLoading] = useState(false);

  const [input, setInput] = useState(0);

  const toast = useToast();

  const handleResendPin = async (e) => {
    e.preventDefault();
    try {
      // Call to api
      const res = await axios.get(
        "client/web/resend/" + clientState.client_user_id
      );

      if (res.status === 200) {
        toast({
          title: "Pin code sent",
          description: "Check your email",
          position: "top-right",
          isClosable: true,
          status: "success",
        });
      }
    } catch (err) {
      if (err.response.status === 404) {
        toast({
          title: "Sendt fejl",
          description: err.response.data.message,
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      } else if (err.response.status === 500) {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et øjeblik",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    }
  };

  const handle_confirm = async (e) => {
    e.preventDefault();
    setisLoading(true);
    const data = {
      secret_pin: parseInt(input, 10),
      client_id: parseInt(clientState.client_user_id),
    };
    try {
      const res = await confirmclient(data);

      if (res.status === 200) {
        navigate("/");
        setisLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        toast({
          title: "Confirm fejl",
          description: err.response.data.message,
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      } else if (err.response.status === 500) {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et øjeblik",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      setisLoading(false);
    }
  };

  return clientState.client_user_id ? (
    <>
      <div className="Otp">
        <div className="Left">
          <Link href={"/"} className="logo_container">
            <img src="../assets/Logo/logo.png" alt="" className="logo"></img>
          </Link>
          <div className="Header">Indsæt verifikationskode</div>
          <div className="Subheader">
            Vi har sendt dig din verifikationskode til din oplyst e-mailadresse.
          </div>
          <HStack className="Pininput">
            <PinInput
              size="lg"
              onChange={(value) => {
                setInput(value);
              }}
            >
              <PinInputField className="pin" />
              <PinInputField className="pin" />
              <PinInputField className="pin" />
              <PinInputField className="pin" />
            </PinInput>
          </HStack>
          {!isLoading ? (
            <>
              <Button className="Submitbtn" onClick={handle_confirm}>
                Indsend
              </Button>
            </>
          ) : (
            <>
              <Button className="Submitbtn" isLoading loadingText="Indlæser">
                Indsend
              </Button>
            </>
          )}
          <div className="reSendText">
            Har du mistet din verifikationskode?{" "}
            <span className="Link" onClick={handleResendPin}>
              Få tilsendt en ny
            </span>
          </div>
        </div>
        <div className="Right">
          <div className="Imagecontainer">
            <div className="Headerscontainer">
              <div className="Header">DIN GENVEJ TIL VELVÆRE</div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : null;
}

export default MarketplaceConfirmOtp;
