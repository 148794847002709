import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useParams,
} from "react-router-dom";

import Homepage from "./pages/Homepage";
import Vilkar from "./pages/Vilkar";
import Datahandeling from "./pages/Datahandeling";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Profile from "./pages/Profile";
import NotFound from "./pages/NotFound";
import MarketplaceLogin from "./components/MarketplaceLogin";
import MarketplaceFavorits from "./pages/MarketplaceFavorites";
import AboutUs from "./pages/AboutUs";

// Cookies config
import Cookies from "js-cookie";

// Chakra ui config
import { Box, ChakraProvider } from "@chakra-ui/react";
import customTheme from "./pages/customTheme";

// SEO config
import { HelmetProvider } from "react-helmet-async";

// Authentication and authorization configurations
import { ClientAuthContextProvider } from "./components/context/Clientauthcontext";

// Axios request handeling config
import axios from "axios";
import MarketplaceSearchPage from "./pages/MarketplaceSearchPage";
import MarketplaceRegister from "./components/MarketplaceRegister";
import MarketplaceExternalSalon from "./pages/MarketplaceExternalSalon";
import MarketplaceDirectSalon from "./pages/MarketplaceDirectSalon";
import MarketplaceConfirmOtp from "./components/MarketplaceConfirmOtp";
import MarketplaceForgotPass from "./components/MarketplaceForgotPass";
import MarketplaceFergotPin from "./components/MarketplaceFergotPin";
import MarketplaceResetPass from "./components/MarketplaceResetPass";
import MarketplaceSecurity from "./components/MarketplaceSecurity";
import BookingPage from "./pages/BookingPage";
import MainSupport from "./pages/MainSupport";
import SubSupport from "./pages/SubSupport";
import SupportParagraph from "./pages/SupportParagraph";
import NoScreen from "./pages/NoScreen";
import AppointmentsPage from "./pages/AppointmentsPage";

axios.defaults.baseURL = "https://api-v3.bookinghero.io/api/";
//axios.defaults.baseURL = "http://localhost:5000/api/";
//axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
axios.defaults.withCredentials = true;

const validCategories = [
  "akupressur",
  "søg",
  "akupunktur",
  "alternativ-medicin",
  "dyrepleje",
  "ayurveda",
  "barber",
  "skønhed",
  "akupunktur",
  "bioresonans-terapi",
  "kropsterapeuter",
  "kinesisk-medicin",
  "kiropraktik",
  "kosmetisk-tatovering",
  "kraniosakral-terapi",
  "tandpleje",
  "tandlæge",
  "kostråd",
  "hundefrisør",
  "øjenvippeforlængelse",
  "fyldstoffer",
  "frekvensterapi",
  "hårpleje",
  "frisør",
  "healing",
  "sundhed",
  "sundhedspleje",
  "hypnose",
  "injektionsbehandlinger",
  "kinesiologi",
  "laser-behandling",
  "øjenvipper",
  "makeup",
  "manicure",
  "massage",
  "neglepleje",
  "negle-forlængelse",
  "naprapati",
  "ernæringsterapi",
  "ernæringsekspert",
  "ortopædi",
  "osteopati",
  "pedicure",
  "fysioterapi",
  "piercing",
  "plastikkirurgi",
  "profylakse",
  "zoneterapi",
  "hudpleje",
  "spa",
  "spray-tan",
  "stylist",
  "tatovering",
  "taxa",
  "terapi",
  "trådning",
  "vaccination",
  "spray-tan",
  "dyrlæge",
  "wellness",
  "fillers",
];

const validSubSupport = [
  "account",
  "payments",
  "how-it-works",
  "cancel-refund",
];

const validSupportParagraph = [
  "get-started",
  "reset-password",
  "account-information",
  "security",
  "data",
  "cancel",
  "marketplace",
  "favorites",
  "appointments",
  "supported-areas",
  "system",
  "receipts",
  "methods",
  "coupons",
  "steps",
  "saloon-cancel",
  "timing",
  "withdraw",
  "client-status",
];

const SearchRoute = () => {
  const { search } = useParams();

  // Check if the value exists in the validCategories array
  const categoryExists = validCategories.includes(search);

  return categoryExists ? <MarketplaceSearchPage /> : <NotFound />;
};

const SubSupportRoute = () => {
  const { SupportId } = useParams();

  // Check if the value exists in the validCategories array
  const categoryExists = validSubSupport.includes(SupportId);

  return categoryExists ? <SubSupport /> : <NotFound />;
};

const SupportParagraphRoute = () => {
  const { SupportId } = useParams();
  const { subSupportId } = useParams();

  // Check if the value exists in the validCategories array
  const categoryExists =
    validSubSupport.includes(SupportId) &&
    validSupportParagraph.includes(subSupportId);

  return categoryExists ? <SupportParagraph /> : <NotFound />;
};

const ProtectedRoute = (element) => {
  if (Cookies.get("user_id") && Cookies.get("auth_token")) {
    return <Homepage />;
  } else {
    return element;
  }
};

const ReverseProtectedRoute = (element) => {
  if (Cookies.get("user_id") && Cookies.get("auth_token")) {
    return element;
  } else {
    return <Homepage />;
  }
};

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <GoogleOAuthProvider clientId="317838096961-s50tjit42khbsarv6kdi47u3cmhsnkdn.apps.googleusercontent.com">
        <HelmetProvider>
          <ClientAuthContextProvider>
            <ChakraProvider theme={customTheme}>
              <Box display={{ base: "block", sm: "block" }}>
                <Routes>
                  <Route exact path="/" element={<Homepage />} />
                  <Route exact path="/vilkar" element={<Vilkar />} />
                  <Route exact path="/om-os" element={<AboutUs />} />
                  <Route
                    exact
                    path="/Databehandling"
                    element={<Datahandeling />}
                  />
                  <Route
                    exact
                    path="/log-paa"
                    element={ProtectedRoute(<MarketplaceLogin />)}
                  />
                  <Route
                    exact
                    path="/logind/glemt-kodeord"
                    element={ProtectedRoute(<MarketplaceForgotPass />)}
                  />
                  <Route
                    exact
                    path="/logind/glemt-kodeord/otp"
                    element={ProtectedRoute(<MarketplaceFergotPin />)}
                  />
                  <Route
                    exact
                    path="/login/nulstille-kodeord"
                    element={ProtectedRoute(<MarketplaceResetPass />)}
                  />
                  <Route exact path="/:search" element={<SearchRoute />} />
                  <Route
                    exact
                    path="/e/:idSaloon"
                    element={<MarketplaceExternalSalon />}
                  />
                  <Route
                    exact
                    path="/d/:idSaloon"
                    element={<MarketplaceDirectSalon />}
                  />
                  <Route
                    exact
                    path="/d/:idSaloon/booking"
                    element={<BookingPage />}
                  />
                  <Route
                    exact
                    path="/opret-konto"
                    element={ProtectedRoute(<MarketplaceRegister />)}
                  />
                  <Route
                    exact
                    path="/opret-konto/otp"
                    element={ProtectedRoute(<MarketplaceConfirmOtp />)}
                  />
                  <Route
                    exact
                    path="/profil"
                    element={ReverseProtectedRoute(<Profile />)}
                  />
                  <Route
                    exact
                    path="/aftaler"
                    element={ReverseProtectedRoute(<AppointmentsPage />)}
                  />
                  <Route
                    exact
                    path="/adgangskode"
                    element={ReverseProtectedRoute(<MarketplaceSecurity />)}
                  />
                  <Route
                    exact
                    path="/favoritter"
                    element={ReverseProtectedRoute(<MarketplaceFavorits />)}
                  />
                  <Route exact path="/support" element={<MainSupport />} />
                  <Route
                    exact
                    path="/support/:SupportId"
                    element={<SubSupportRoute />}
                  />
                  <Route
                    exact
                    path="/support/:SupportId/:subSupportId"
                    element={<SupportParagraphRoute />}
                  />
                </Routes>
              </Box>
              {/* 
              <Box display={{ base: "block", sm: "none" }}>
                <NoScreen />
              </Box>
              */}
            </ChakraProvider>
          </ClientAuthContextProvider>
        </HelmetProvider>
      </GoogleOAuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);
