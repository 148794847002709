import { AddIcon, CheckIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { ThreeDots } from "react-loader-spinner";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function BookingPageServiceSelect({ appointment, setAppointment }) {
  const ColorModeValue = useColorModeValue("gray.800", "gray.900");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [treatments, setTreatments] = useState();
  const [currentTreatment, setCurrentTreatment] = useState();
  const [selectedTreatmentIndex, setSelectedTreatmentIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.post(
          "direct_salon/web/treatments",
          {
            salon_slug: appointment.SalonSlug,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setTreatments(response.data.salon_treatments);
        // Handle different status codes
        switch (response.status) {
          case 500:
            toast({
              title: "Server Fejl",
              description: "Prøv venligst igen om et øjeblik",
              position: "top-right",
              isClosable: true,
              status: "error",
            });
            navigate("/");
            break;
          case 404:
            toast({
              title: "Session over",
              description: "Prøv venligst igen om et øjeblik",
              position: "top-right",
              isClosable: true,
              status: "error",
            });
            navigate("/");
            break;
          case 405:
            toast({
              title: "Salon account is inactive",
              description: "Prøv venligst igen om et øjeblik",
              position: "top-right",
              isClosable: true,
              status: "error",
            });
            navigate("/");
            break;
          case 406:
            toast({
              title: "Salon account not connected",
              description: "Prøv venligst igen om et øjeblik",
              position: "top-right",
              isClosable: true,
              status: "error",
            });
            navigate("/");
            break;
          case 407:
            toast({
              title: "Salon profile incomplete",
              description: "Prøv venligst igen om et øjeblik",
              position: "top-right",
              isClosable: true,
              status: "error",
            });
            navigate("/");
            break;
          default:
            break;
        }
        setIsLoading(false);
      } catch (error) {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et øjeblik",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
        navigate("/");
      }
    };
    fetchData();
  }, []);

  // return list of treatments based on the type [0-1-2-3]
  const getTreatments = (type) => {
    if (treatments.length > 0)
      return treatments.map((treatment, index) =>
        treatment["Type"] === type ? (
          <Box
            width={"100%"}
            height={"fit-content"}
            border={
              selectedTreatmentIndex === treatment["TreatID"]
                ? "3px solid #23a994"
                : "1px solid lightgray"
            }
            borderRadius={"12px"}
            padding={"20px"}
            cursor={"pointer"}
            _hover={{
              bg: "whitesmoke",
            }}
            mb={"15px"}
            style={{
              transition: "border-color 0.3s ease-in",
            }}
            onClick={() => {
              setCurrentTreatment(treatment);
              onOpen();
            }}
          >
            <Flex justifyContent={"space-between"} alignItems={"center"}>
              <Box>
                <Text fontWeight={"500"} mb={"5px"}>
                  {treatment["Description"]}
                </Text>
                <Text mb={"5px"} color={"gray"}>
                  {treatment["DurationFrom"] ? "from" : ""}{" "}
                  {treatment["Duration"]}min
                </Text>
                <Box
                  mb={"5px"}
                  color={"gray"}
                  maxW={"600px"}
                  overflow="hidden"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                >
                  {treatment["BigDescription"] && treatment["BigDescription"]}
                </Box>
                <Text fontWeight={"500"} fontSize={"sm"}>
                  {treatment["UnitPriceFrom"] ? "from" : ""}{" "}
                  {treatment["UnitPrice"]} DKK
                </Text>
              </Box>
              <Box>
                <IconButton
                  aria-label="Add service"
                  icon={
                    selectedTreatmentIndex === treatment["TreatID"] ? (
                      <CheckIcon style={{ transition: "all 0.3s ease" }} />
                    ) : (
                      <AddIcon />
                    )
                  }
                  size={"sm"}
                  onClick={(event) => {
                    event.stopPropagation();
                    setCurrentTreatment(treatment);
                    setSelectedTreatmentIndex(treatment["TreatID"]);
                    setAppointment((prevAppointment) => ({
                      ...prevAppointment,
                      treatment: {
                        ...prevAppointment.treatment,
                        Name: treatment["Description"],
                        ID: treatment["TreatID"],
                        Type: treatment["Type"],
                        Price: treatment["UnitPrice"],
                        Duration: treatment["Duration"],
                        PriceFrom: treatment["UnitPriceFrom"],
                        DurationFrom: treatment["DurationFrom"],
                      },
                    }));
                  }}
                  bg={
                    selectedTreatmentIndex === treatment["TreatID"]
                      ? "#23a994"
                      : "transparent"
                  }
                  color={
                    selectedTreatmentIndex === treatment["TreatID"]
                      ? "white"
                      : "black"
                  }
                  style={{ transition: "background-color 0.3s ease" }}
                />
              </Box>
            </Flex>
          </Box>
        ) : null
      );
    else return <Box>No treatments for this category</Box>;
  };

  return (
    <>
      <Box width={"100%"} height={"fit-content"}>
        <Box width={"100%"} height={"fit-content"} mb={"20px"}>
          <Heading fontWeight={"600"}>Vælg Service</Heading>
        </Box>
        <Box width={"100%"} height={"fit-content"}>
          <Tabs variant="soft-rounded">
            <TabList>
              <Tab
                _selected={{
                  color: "white",
                  bg: ColorModeValue,
                }}
                color={ColorModeValue}
              >
                Klipninger
              </Tab>
              <Tab
                _selected={{
                  color: "white",
                  bg: ColorModeValue,
                }}
                color={ColorModeValue}
              >
                Andet
              </Tab>
              {/* 
              <Tab
                _selected={{
                  color: "white",
                  bg: ColorModeValue,
                }}
                color={ColorModeValue}
              >
                Kropsbehandlings
              </Tab>
              <Tab
                _selected={{
                  color: "white",
                  bg: ColorModeValue,
                }}
                color={ColorModeValue}
              >
                Bryn & vipper
              </Tab>
              */}
            </TabList>
            {isLoading ? (
              <Flex
                height={"30vh"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <ThreeDots
                  visible={true}
                  height="70"
                  width="80"
                  color="#23a994"
                  radius="9"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </Flex>
            ) : (
              <TabPanels>
                <TabPanel paddingY={"50px"}>
                  <Box width={"100%"} height={"fit-content"}>
                    {treatments && getTreatments(0)}
                  </Box>
                </TabPanel>
                <TabPanel>
                  <Box width={"100%"} height={"fit-content"}>
                    {treatments && getTreatments(1)}
                  </Box>
                </TabPanel>
                <TabPanel>
                  <Box width={"100%"} height={"fit-content"}>
                    {treatments && getTreatments(2)}
                  </Box>
                </TabPanel>
                <TabPanel>
                  <Box width={"100%"} height={"fit-content"}>
                    {treatments && getTreatments(3)}
                  </Box>
                </TabPanel>
              </TabPanels>
            )}
          </Tabs>
        </Box>

        <Modal
          isCentered
          onClose={onClose}
          isOpen={isOpen}
          motionPreset="slideInBottom"
          size={"2xl"}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader marginTop={"40px"}>
              {currentTreatment && currentTreatment["Description"]}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box width={"100%"} height={"fit-content"}>
                <Text color={"gray"} fontWeight={"500"}>
                  {currentTreatment && currentTreatment["DurationFrom"]
                    ? "from"
                    : ""}{" "}
                  {currentTreatment && currentTreatment["Duration"]} min
                </Text>
                <Text fontWeight={"600"} mb={"30px"}>
                  {currentTreatment && currentTreatment["UnitPriceFrom"]
                    ? "from"
                    : ""}{" "}
                  {currentTreatment && currentTreatment["UnitPrice"]} DKK
                </Text>
                <Text fontSize={"sm"} fontWeight={"500"}>
                  {currentTreatment && currentTreatment["BigDescription"]}
                </Text>
              </Box>
            </ModalBody>
            <ModalFooter>
              <Button
                background={"#23a994"}
                width={"100%"}
                height={"45px"}
                style={{ transition: "all 250ms ease-in" }}
                _hover={{
                  bg: "#23a994",
                  transform: "scale(1.05)",
                }}
                mb={"10px"}
                onClick={() => {
                  setSelectedTreatmentIndex(currentTreatment["TreatID"]);
                  onClose();
                  setAppointment((prevAppointment) => ({
                    ...prevAppointment,
                    treatment: {
                      ...prevAppointment.treatment,
                      Name: currentTreatment["Description"],
                      ID: currentTreatment["TreatID"],
                      Type: currentTreatment["Type"],
                      Price: currentTreatment["UnitPrice"],
                      Duration: currentTreatment["Duration"],
                      PriceFrom: currentTreatment["UnitPriceFrom"],
                      DurationFrom: currentTreatment["DurationFrom"],
                    },
                  }));
                }}
              >
                Tilføj til booking
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </>
  );
}

export default BookingPageServiceSelect;
