import { StarIcon, TimeIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Grid,
  GridItem,
  HStack,
  Image,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  PinInput,
  PinInputField,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { ThreeDots } from "react-loader-spinner";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { BsStar, BsStarFill, BsStarHalf } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { Clientauthcontext } from "./context/Clientauthcontext";
import { IoMdArrowBack } from "react-icons/io";
function BookingPageOverview({
  appointment,
  setAppointment,
  disable,
  setDisable,
}) {
  const storedAppointment = localStorage.getItem("appointment");

  const [isAuth, setIsAuth] = useState();
  const [email, setEmail] = useState("");
  const [password, setpassword] = useState("");
  const [repeat_password, setRepeat_password] = useState("");
  const [name, setname] = useState("");
  const [clientid, setClientid] = useState(-1);
  const [showPass, setShowPass] = useState(false);
  const [isLoadingSignup, setIsLoadingSignup] = useState(false);

  const [isChecked, setIsChecked] = useState(true);

  // is authentificated
  const verifyCookie = async () => {
    try {
      if (email !== "") {
        const res = await axios.post("direct_salon/web/check-auth", {
          email: email,
        });
        if (res.status === 202) {
          signupModal.onOpen();
          setIsAuth(false);
        } else {
          if (res.status === 201) {
            signinModal.onOpen();
            setIsAuth(false);
          } else {
            setIsAuth(true);
          }
        }
      } else {
        const res = await axios.post("direct_salon/web/check-auth");
        if (res.status !== 200) {
          emailModal.onOpen();
          setIsAuth(false);
        } else {
          setIsAuth(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const { loginclient } = useContext(Clientauthcontext);
  const { registerclient } = useContext(Clientauthcontext);
  const { confirmclient } = useContext(Clientauthcontext);

  const handle_signin = async () => {
    try {
      const inputs = {
        client_email: email,
        client_password: password,
      };
      const res = await loginclient(inputs);
      if (res.status === 200) {
        setAppointment((prevState) => ({
          ...prevState,
          step: 4,
        }));
      }
    } catch (err) {
      if (err.response.status === 404) {
        toast({
          title: "Login fejl",
          description: err.response.data.message,
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      } else if (err.response.status === 500) {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et øjeblik",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    }
  };

  const handle_signup = async (e) => {
    try {
      if (
        email === "" ||
        password === "" ||
        repeat_password === "" ||
        name === ""
      ) {
        toast({
          title: "Skriv fejl",
          description: "E-mail- eller adgangskodefeltet er tomt",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      } else if (password !== repeat_password) {
        toast({
          title: "Skriv fejl",
          description: "bekræftet adgangskode forkert",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      } else {
        const Data = {
          client_name: name,
          client_email: email,
          client_password: password,
        };
        const res = await registerclient(Data);

        if (res.status === 200) {
          signupModal.onClose();
          setIsLoadingSignup(false);
          otpModal.onOpen();
          setClientid(res.data.client_user_id);
        }
      }
    } catch (err) {
      if (err.response.status === 404) {
        toast({
          title: "Login fejl",
          description: err.response.data.message,
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      } else if (err.response.status === 500) {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et øjeblik",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    }
  };
  const [input, setInput] = useState(0);

  const handle_confirm = async (e) => {
    const data = {
      secret_pin: parseInt(input, 10),
      client_id: parseInt(clientid),
    };
    try {
      const res = await confirmclient(data);

      if (res.status === 200) {
        setAppointment((prevState) => ({
          ...prevState,
          step: 4,
        }));
      }
    } catch (err) {
      if (err.response.status === 404) {
        toast({
          title: "Confirm fejl",
          description: err.response.data.message,
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      } else if (err.response.status === 500) {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et øjeblik",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    }
  };

  const handleNextStep = () => {
    if (appointment.step == 3) {
      verifyCookie();
      if (!isAuth) return;
    }
    setAppointment((prevState) => ({
      ...prevState,
      step: prevState.step + 1 < 4 ? prevState.step + 1 : 4,
    }));
  };

  const monthMap = {
    januar: "01",
    februar: "02",
    marts: "03",
    april: "04",
    maj: "05",
    juni: "06",
    juli: "07",
    august: "08",
    september: "09",
    oktober: "10",
    november: "11",
    december: "12",
  };
  const [create, setCreate] = useState(true);
  const ColorModeValue = useColorModeValue("gray.700", "gray.800");
  const notConnectedModal = useDisclosure(); //Displayed modal when the user want to confirm but he is signed out
  const signinModal = useDisclosure();
  const signupModal = useDisclosure();
  const emailModal = useDisclosure();
  const otpModal = useDisclosure();
  const staffModal = useDisclosure();
  const [employees, setEmployees] = useState();
  const [selectedEmployeeIndex, setSelectedEmployeeIndex] = useState(
    appointment.employee.ID
  );
  const [SalonInfos, setSalonInfos] = useState();
  const buttonText =
    appointment.step === 4 ? (create ? "Bekræfte" : "Opdatering") : "Fortsætte";
  const [buttonLoading, setButtonLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  //Get Salon Infos to display on the overview card
  useEffect(() => {
    const fatchData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.post(
          "direct_salon/web/booking-information",
          {
            salon_slug: appointment.SalonSlug,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log(response.data.salon_information);
        setSalonInfos(response.data.salon_information);
        // Handle different status codes
        switch (response.status) {
          case 500:
            toast({
              title: "Server Fejl",
              description: "Prøv venligst igen om et øjeblik",
              position: "top-right",
              isClosable: true,
              status: "error",
            });
            navigate("/");
            break;
          case 404:
            toast({
              title: "Session over",
              description: "Prøv venligst igen om et øjeblik",
              position: "top-right",
              isClosable: true,
              status: "error",
            });
            navigate("/");
            break;
          case 405:
            toast({
              title: "Salon account is inactive",
              description: "Prøv venligst igen om et øjeblik",
              position: "top-right",
              isClosable: true,
              status: "error",
            });
            navigate("/");
            break;
          case 406:
            toast({
              title: "Salon account not connected",
              description: "Prøv venligst igen om et øjeblik",
              position: "top-right",
              isClosable: true,
              status: "error",
            });
            navigate("/");
            break;
          case 407:
            toast({
              title: "Salon profile incomplete",
              description: "Prøv venligst igen om et øjeblik",
              position: "top-right",
              isClosable: true,
              status: "error",
            });
            navigate("/");
            break;
          default:
            break;
        }
        setIsLoading(false);
      } catch (error) {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et øjeblik",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
        navigate("/");
      }
    };

    if (storedAppointment) {
      setAppointment(JSON.parse(storedAppointment));
      setCreate(JSON.parse(storedAppointment).create);
      localStorage.removeItem("appointment");
    }

    fatchData();
  }, [storedAppointment]);

  // Make an appointment
  const makeAppointment = async () => {
    setButtonLoading(true);
    const url = "direct_salon/web/make-appointment";

    const data = {
      salon_slug: appointment.SalonSlug,
      client_name: appointment.client.Name,
      client_phone: appointment.client.Phone,
      client_email: appointment.client.Email,
      client_address: appointment.client.Address,
      treatment_id: appointment.treatment.ID.toString(),
      treatment_duration: appointment.treatment.Duration,
      treatment_price: appointment.treatment.Price.toString(),
      treatment_name: appointment.treatment.Name,
      employee_id: appointment.employee.ID,
      employee_name: appointment.employee.Name,
      date_time: `${appointment.schedule.year}-${
        monthMap[appointment.schedule.month]
      }-${appointment.schedule.day}T${appointment.schedule.startTime}:00`,
      comment: appointment.client.Note,
    };

    try {
      const response = await axios.post(url, data);
      if (response.status === 200) {
        toast({
          title: "Aftale registreret",
          description: "Din aftale er blevet oprettet succesfuldt",
          position: "top-right",
          isClosable: true,
          status: "success",
        });
        await insertConversion();
        navigate("/aftaler");
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 500) {
          toast({
            title: "Server Fejl",
            description: "Prøv venligst igen om et øjeblik",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        } else if (error.response.status === 404) {
          //display not connected modal
          notConnectedModal.onOpen();
          localStorage.setItem("appointment", JSON.stringify(appointment));
          // localStorage.setItem("client",JSON.stringify({}))
        } else if (error.response.status === 405) {
          toast({
            title: "Inactive Salon",
            description: "Prøv venligst igen om et øjeblik",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        } else if (error.response.status === 406) {
          toast({
            title: "Salon not connected",
            description: "Prøv venligst igen om et øjeblik",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        } else if (error.response.status === 407) {
          toast({
            title: "Incomplete Salon infos",
            description: "Prøv venligst igen om et øjeblik",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        }
      } else {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et øjeblik",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    }
    setButtonLoading(false);
  };

  // Update an appointment
  const updateAppointment = async () => {
    setButtonLoading(true);

    const url = "client/web/update-appointment";

    const data = {
      salon_slug: appointment.SalonSlug,
      client_name: appointment.client.Name,
      client_phone: appointment.client.Phone,
      client_email: appointment.client.Email,
      client_address: appointment.client.Address,
      treatment_id: appointment.treatment.ID.toString(),
      treatment_duration: appointment.treatment.Duration,
      employee_id: appointment.employee.ID,
      employee_name: appointment.employee.Name,
      date_time: `${appointment.schedule.year}-${
        monthMap[appointment.schedule.month]
      }-${appointment.schedule.day}T${appointment.schedule.startTime}:00`,
      comment: appointment.client.Note,
      appointment_id: appointment.appointment_id,
      entry_no: appointment.entry_no,
    };

    try {
      const response = await axios.post(url, data);
      if (response.status === 200) {
        toast({
          title: "Aftale registreret",
          description: "Din aftale er blevet oprettet succesfuldt",
          position: "top-right",
          isClosable: true,
          status: "success",
        });
        navigate("/aftaler");
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 500) {
          toast({
            title: "Server Fejl",
            description: "Prøv venligst igen om et øjeblik",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        } else if (error.response.status === 404) {
          //display not connected modal
          notConnectedModal.onOpen();
          localStorage.setItem("appointment", JSON.stringify(appointment));
        } else if (error.response.status === 405) {
          toast({
            title: "Inactive Salon",
            description: "Prøv venligst igen om et øjeblik",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        } else if (error.response.status === 406) {
          toast({
            title: "Salon not connected",
            description: "Prøv venligst igen om et øjeblik",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        } else if (error.response.status === 407) {
          toast({
            title: "Incomplete Salon infos",
            description: "Prøv venligst igen om et øjeblik",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        }
      } else {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et øjeblik",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    }
    setButtonLoading(false);
  };

  // Insert conversion after making appointment
  const insertConversion = async () => {
    const bodyData = {
      business_slug: appointment.SalonSlug,
    };

    try {
      await axios.post("direct_salon/web/insert-conversion", bodyData);
    } catch (error) {
      toast({
        title: "Server Fejl",
        description: "Prøv venligst igen om et øjeblik",
        position: "top-right",
        isClosable: true,
        status: "error",
      });
    }
  };

  const getEmployeeList = async () => {
    try {
      const response = await axios.post(
        "direct_salon/web/employees",
        {
          salon_slug: appointment.SalonSlug,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setEmployees(response.data.salon_employees);
      // Handle different status codes
      switch (response.status) {
        case 500:
          toast({
            title: "Server Fejl",
            description: "Prøv venligst igen om et øjeblik",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
          navigate("/");
          break;
        case 404:
          toast({
            title: "Session over",
            description: "Prøv venligst igen om et øjeblik",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
          navigate("/");
          break;
        case 405:
          toast({
            title: "Salon account is inactive",
            description: "Prøv venligst igen om et øjeblik",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
          navigate("/");
          break;
        case 406:
          toast({
            title: "Salon account not connected",
            description: "Prøv venligst igen om et øjeblik",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
          navigate("/");
          break;
        case 407:
          toast({
            title: "Salon profile incomplete",
            description: "Prøv venligst igen om et øjeblik",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
          navigate("/");
          break;
        default:
          break;
      }
    } catch (error) {
      toast({
        title: "Server Fejl",
        description: "Prøv venligst igen om et øjeblik",
        position: "top-right",
        isClosable: true,
        status: "error",
      });
      navigate("/");
    }
  };

  return (
    <>
      <Box
        width={"100%"}
        height={"fit-content"}
        marginLeft={"50px"}
        border={"1px solid lightgray"}
        borderRadius={"12px"}
      >
        <Box width={"100%"} height={"fit-content"} padding={"25px"}>
          {!SalonInfos ? (
            <Flex
              height={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <ThreeDots
                visible={true}
                height="70"
                width="80"
                color="#23a994"
                radius="9"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </Flex>
          ) : (
            <Flex alignItems={"center"} gap={"15px"} width={"100%"} mb={"25px"}>
              <Box boxSize="80px" minW={"90px"} minH={"80px"}>
                <Image
                  borderRadius={"12px"}
                  src={
                    SalonInfos && SalonInfos.general_information[0].logo_image
                  }
                  alt="Dan Abramov"
                  objectFit={"fill"}
                  minW={"90px"}
                  minH={"80px"}
                />
              </Box>
              <Flex
                flexDirection={"column"}
                justifyContent={"space-between"}
                gap={"5px"}
              >
                <Text fontWeight={"600"} fontSize={"md"}>
                  {SalonInfos && SalonInfos.general_information[0].name}
                </Text>
                <Flex alignItems={"center"} gap={"10px"}>
                  <Text fontWeight={"600"} fontSize={"sm"}>
                    {SalonInfos && Math.round(SalonInfos.salon_rating * 2) / 2}
                  </Text>
                  <Flex gap={"5px"} alignItems={"center"} mb={"3px"}>
                    {SalonInfos &&
                      Array(Math.round(5))
                        .fill("")
                        .map((_, i) => {
                          const roundedRating =
                            Math.round(SalonInfos.salon_rating * 2) / 2;
                          if (roundedRating - i >= 1) {
                            return (
                              <BsStarFill
                                key={i}
                                color={"#ECC94B"}
                                size={"15px"}
                              />
                            );
                          }
                          if (roundedRating - i === 0.5) {
                            return (
                              <BsStarHalf
                                key={i}
                                color={"#ECC94B"}
                                size={"15px"}
                              />
                            );
                          }
                          return (
                            <BsStar key={i} color={"#ECC94B"} size={"15px"} />
                          );
                        })}
                  </Flex>
                  <Text fontSize={"sm"} fontWeight={"500"}>
                    ({SalonInfos && SalonInfos.number_review})
                  </Text>
                </Flex>
                <Text fontWeight={"500"} color={"gray"} fontSize={"sm"}>
                  {SalonInfos && SalonInfos.general_information[0].address},
                  {SalonInfos && SalonInfos.general_information[0].city}
                </Text>
              </Flex>
            </Flex>
          )}
          <Box mb={"25px"}>
            <Text fontSize={"xl"} fontWeight={"600"}>
              Oversigt
            </Text>
          </Box>
          <Box width={"100%"} height={"fit-content"} mb={"25px"}>
            {appointment.treatment.ID !== -1 && (
              <Flex
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignContent={"center"}
              >
                <Box>
                  <Text fontWeight={"600"} fontSize={"sm"}>
                    {appointment.treatment.Name}
                  </Text>
                  <Text color={"gray"}>
                    {appointment.treatment.DurationFrom ? "from" : ""}{" "}
                    {appointment.treatment.Duration} min
                  </Text>
                </Box>
                <Box>
                  <Text fontWeight={"600"} fontSize={"sm"}>
                    {appointment.treatment.PriceFrom ? "from" : ""}{" "}
                    {appointment.treatment.Price} DKK
                  </Text>
                </Box>
              </Flex>
            )}
          </Box>
          <Box width={"100%"} height={"fit-content"} mb={"25px"}>
            <Divider color={"black"} />
          </Box>
          <Box mb={"25px"}>
            <Flex justifyContent={"space-between"} alignItems={"center"}>
              <Text fontSize={"lg"} fontWeight={"600"}>
                Total
              </Text>
              <Text fontSize={"md"} fontWeight={"600"}>
                {appointment.treatment.ID === -1 && "0"}
                {appointment.treatment.ID !== -1 &&
                appointment.treatment.PriceFrom
                  ? "fra"
                  : ""}{" "}
                {appointment.treatment.ID !== -1 && appointment.treatment.Price}{" "}
                DKK
              </Text>
            </Flex>
          </Box>
          <Box mb={"25px"}>
            <Text fontSize={"xl"} fontWeight={"600"}>
              Aftale
            </Text>
          </Box>
          <Box mb={"25px"} width={"100%"} height={"fit-content"}>
            {appointment.employee.ID !== -1 && (
              <Flex justifyContent={"space-between"} alignItems={"center"}>
                <Flex gap={"15px"} alignItems={"center"}>
                  <Box>
                    <Avatar
                      name={appointment.employee.Name.substring(0, 1)}
                      src={appointment.employee.Image}
                    />
                  </Box>
                  <Box>
                    <Text
                      fontSize={"sm"}
                      fontWeight={"600"}
                      color={"#23a994"}
                      cursor={"pointer"}
                      _hover={{
                        textDecoration: "underline",
                      }}
                      onClick={() => {
                        getEmployeeList();
                        staffModal.onOpen();
                        {
                          /*  setAppointment((prevAppointment) => ({
                          ...prevAppointment,
                          step: 2,
                        }));
                        */
                        }
                      }}
                    >
                      {appointment.employee.Name}
                    </Text>
                    <Text fontSize={"sm"} color={"gray"}>
                      {appointment.employee.Speciality}
                    </Text>
                  </Box>
                </Flex>
              </Flex>
            )}
          </Box>
          {appointment.schedule.startTime !== "" && (
            <Box mb={"45px"} width={"100%"} height={"fit-content"}>
              <Flex alignItems={"center"} gap={"15px"}>
                <Box
                  width={"fit-content"}
                  height={"fit-content"}
                  border={"2px solid lightgray"}
                  borderRadius={"5px"}
                  textAlign={"center"}
                  padding={"5px"}
                >
                  <TimeIcon boxSize={"30px"} />
                </Box>
                <Box>
                  <Text fontSize={"sm"} fontWeight={"600"}>
                    {appointment.schedule.dayInText} {appointment.schedule.day}{" "}
                    {appointment.schedule.month} {appointment.schedule.year}
                  </Text>
                  <Text fontSize={"sm"} color={"gray"}>
                    {appointment.schedule.startTime}-
                    {appointment.schedule.endTime}
                  </Text>
                </Box>
              </Flex>
            </Box>
          )}
          <Box width={"100%"} height={"fit-content"}>
            <Button
              backgroundColor={"#23a994"}
              width={"100%"}
              height={"45px"}
              _hover={
                disable
                  ? {
                      backgroundColor: "#23a994",
                    }
                  : {
                      backgroundColor: "#23a994",
                      transform: "scale(1.05)",
                    }
              }
              onClick={() => {
                handleNextStep();
                setDisable(false);
                if (appointment.step === 4) {
                  if (create) makeAppointment();
                  else updateAppointment();
                }
              }}
              isDisabled={disable}
              style={{ transition: "all 250ms ease-in" }}
              isLoading={buttonLoading}
              spinner={
                <ThreeDots
                  visible={true}
                  height="30"
                  width="30"
                  color="white"
                  radius="9"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              }
            >
              {buttonText}
            </Button>
          </Box>
        </Box>
        {/* not connected */}
        <Modal
          isCentered
          onClose={notConnectedModal.onClose}
          isOpen={notConnectedModal.isOpen}
          motionPreset="slideInBottom"
          size={"2xl"}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader marginTop={"40px"}>Du er ikke forbundet</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box width={"100%"} height={"fit-content"}>
                <Text fontSize={"sm"} fontWeight={"500"}>
                  For at gennemføre din aftale skal du logge ind/registrere dig
                </Text>
              </Box>
            </ModalBody>
            <ModalFooter>
              <Button
                backgroundColor={useColorModeValue("gray.800", "gray.900")}
                color={"white"}
                width={"100%"}
                height={"45px"}
                _hover={{
                  backgroundColor: useColorModeValue("gray.700", "gray.800"),
                }}
                mb={"10px"}
                onClick={() => {
                  notConnectedModal.onClose();
                  navigate("/log-paa");
                }}
              >
                Gå til login-siden
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        {/* Give email */}
        <Modal
          isCentered
          onClose={emailModal.onClose}
          isOpen={emailModal.isOpen}
          motionPreset="slideInBottom"
          size={"lg"}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader marginTop={"24px"}>
              <Text fontSize={"2xl"} mb={"5px"}>
                Log ind eller tilmeld dig
              </Text>
              <Text fontSize={"sm"} fontWeight={"normal"}>
                Log ind eller tilmeld dig for at fuldføre din reservation
              </Text>
            </ModalHeader>

            <ModalCloseButton />
            <ModalBody>
              <Box width={"100%"} height={"fit-content"} paddingTop={"24px"}>
                <Input
                  size={"lg"}
                  type="mail"
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="E-mail adresse"
                />
              </Box>
            </ModalBody>
            <ModalFooter>
              <Button
                backgroundColor={"#23a994"}
                width={"100%"}
                height={"45px"}
                marginTop={"16px"}
                style={{
                  transition: "all 250ms ease-in",
                }}
                _hover={{
                  backgroundColor: "#23a994",
                  transform: "scale(1.05)",
                }}
                mb={"10px"}
                onClick={() => {
                  emailModal.onClose();
                  verifyCookie();
                }}
              >
                Blive ved
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        {/* signin-password  */}
        <Modal
          isCentered
          onClose={signinModal.onClose}
          isOpen={signinModal.isOpen}
          motionPreset="slideInBottom"
          size={"lg"}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <Box
                cursor={"pointer"}
                fontSize={"xl"}
                onClick={() => {
                  signinModal.onClose();
                  emailModal.onOpen();
                }}
              >
                <IoMdArrowBack />
              </Box>
              <ModalCloseButton />
              <Text fontSize={"2xl"} marginTop={"24px"} mb={"5px"}>
                Velkommen tilbage
              </Text>
              <Text fontSize={"sm"} fontWeight={"normal"}>
                Indtast din adgangskode for at logge ind {email}
              </Text>
            </ModalHeader>
            <ModalBody>
              <Box
                width={"100%"}
                height={"fit-content"}
                position={"relative"}
                paddingTop={"24px"}
              >
                <Input
                  size={"lg"}
                  type={showPass ? "text" : "password"}
                  onChange={(e) => setpassword(e.target.value)}
                  placeholder="Password"
                />
                <Box
                  position={"absolute"}
                  zIndex={40}
                  right={"8px"}
                  top={"36px"}
                  fontSize={"2xl"}
                  onClick={() => setShowPass(!showPass)}
                  cursor={"pointer"}
                >
                  {showPass ? <IoEyeOutline /> : <IoEyeOffOutline />}
                </Box>
              </Box>
            </ModalBody>
            <ModalFooter>
              <Button
                backgroundColor={"#23a994"}
                width={"100%"}
                height={"45px"}
                marginTop={"16px"}
                style={{
                  transition: "all 250ms ease-in",
                }}
                _hover={{
                  transform: "scale(1.05)",
                }}
                mb={"10px"}
                onClick={() => {
                  signinModal.onClose();
                  handle_signin();
                }}
              >
                Log på
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        {/* signup */}
        <Modal
          isCentered
          onClose={signupModal.onClose}
          isOpen={signupModal.isOpen}
          motionPreset="slideInBottom"
          size={"xl"}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <Box
                cursor={"pointer"}
                fontSize={"xl"}
                onClick={() => {
                  signupModal.onClose();
                  emailModal.onOpen();
                }}
              >
                <IoMdArrowBack />
              </Box>

              <Text fontSize={"2xl"} marginTop={"24px"} mb={"5px"}>
                Opret konto
              </Text>
              <Text fontSize={"sm"} fontWeight={"normal"}>
                Opret din nye konto til {email} ved at udfylde disse oplysninger
              </Text>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box width={"100%"} height={"fit-content"}>
                <Box marginBottom={"20px"}>
                  <Text>Fulde navn *</Text>
                  <Input
                    type="text"
                    onChange={(e) => setname(e.target.value)}
                    size={"lg"}
                  />
                </Box>
                <Box marginBlock={"20px"}>
                  <Text>Adgangskode *</Text>
                  <Input
                    type="password"
                    onChange={(e) => setpassword(e.target.value)}
                    size={"lg"}
                  />
                </Box>
                <Box marginBlock={"20px"}>
                  <Text>Gentag adgangskode *</Text>
                  <Input
                    type="password"
                    onChange={(e) => setRepeat_password(e.target.value)}
                    size={"lg"}
                  />
                </Box>
                <Flex marginBlock={"20px"}>
                  <Checkbox
                    colorScheme="green"
                    marginRight={"8px"}
                    size={"lg"}
                    isChecked={isChecked}
                    onChange={() => {
                      setIsChecked(!isChecked);
                    }}
                  />{" "}
                  <Text>
                    Jeg accepterer privatlivspolitikken, vilkårene for brug og{" "}
                    <Link href="/vilkar" target="_blank">
                      servicevilkårene
                    </Link>
                  </Text>
                </Flex>
              </Box>
            </ModalBody>
            <ModalFooter>
              <Button
                backgroundColor={"#23a994"}
                width={"100%"}
                height={"45px"}
                _hover={
                  isChecked && { bg: "#23a994", transform: "scale(1.05)" }
                }
                style={{ transition: "all 250ms ease-in" }}
                isLoading={isLoadingSignup}
                loadingText="Indlæser"
                spinnerPlacement="end"
                isDisabled={!isChecked}
                mb={"10px"}
                onClick={() => {
                  setIsLoadingSignup(true);
                  handle_signup();
                }}
              >
                Blive ved
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        {/* pin */}
        <Modal
          isCentered
          onClose={otpModal.onClose}
          isOpen={otpModal.isOpen}
          motionPreset="slideInBottom"
          size={"xl"}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader marginTop={"40px"}>
              <Text fontSize={"2xl"} marginTop={"24px"} mb={"5px"}>
                Venlist tjekke din email for at bekræfte din konto
              </Text>
              <Text fontSize={"sm"} fontWeight={"normal"}>
                Indtast venligst din hemmelige pinkode
              </Text>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <HStack className="Pininput" marginTop="24px">
                <PinInput
                  onChange={(value) => {
                    setInput(value);
                  }}
                >
                  <PinInputField
                    className="pin"
                    h={"80px"}
                    w={"80px"}
                    marginInline={"10px"}
                    fontSize={"2xl"}
                    fontWeight={"bold"}
                  />
                  <PinInputField
                    className="pin"
                    h={"80px"}
                    w={"80px"}
                    marginInline={"10px"}
                    fontSize={"2xl"}
                    fontWeight={"bold"}
                  />
                  <PinInputField
                    className="pin"
                    h={"80px"}
                    w={"80px"}
                    marginInline={"10px"}
                    fontSize={"2xl"}
                    fontWeight={"bold"}
                  />
                  <PinInputField
                    className="pin"
                    h={"80px"}
                    w={"80px"}
                    marginInline={"10px"}
                    fontSize={"2xl"}
                    fontWeight={"bold"}
                  />
                </PinInput>
              </HStack>
            </ModalBody>
            <ModalFooter>
              <Button
                backgroundColor={"#23a994"}
                width={"100%"}
                height={"45px"}
                marginTop={"16px"}
                _hover={{ bg: "#23a994", transform: "scale(1.05)" }}
                style={{ transition: "all 250ms ease-in" }}
                mb={"10px"}
                onClick={() => {
                  otpModal.onClose();
                  handle_confirm();
                }}
              >
                Indsend
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        {/* Change Staff member modal*/}
        <Modal
          isCentered
          onClose={staffModal.onClose}
          isOpen={staffModal.isOpen}
          motionPreset="slideInBottom"
          size={"3xl"}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <Text fontSize={"2xl"} marginTop={"30px"} mb={"15px"}>
                {appointment.treatment.Name}
              </Text>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody minH={"50vh"} maxH={"65vh"} overflow={"auto"}>
              <Grid
                templateColumns={{
                  sm: "repeat(1, 1fr)",
                  md: "repeat(2, 1fr)",
                  lg: "repeat(3, 1fr)",
                }}
                gap={6}
              >
                {employees &&
                  employees.map(
                    (employee, index) =>
                      employee["Online"] && (
                        <GridItem
                          onClick={() => {
                            setSelectedEmployeeIndex(employee["No"]);
                            setAppointment((prevAppointment) => ({
                              ...prevAppointment,
                              employee: {
                                ...prevAppointment.employee,
                                Name: employee["NameID"],
                                Image: employee["Image"],
                                ID: employee["No"],
                                Speciality: employee["Job"],
                              },
                            }));
                            staffModal.onClose();
                          }}
                        >
                          <Flex
                            flexDirection={"column"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            border={
                              selectedEmployeeIndex === employee["No"]
                                ? "3px solid #23a994"
                                : "1px solid lightgray"
                            }
                            paddingX={"15px"}
                            paddingY={"30px"}
                            borderRadius={"12px"}
                            cursor={"pointer"}
                            _hover={{
                              bg: "whitesmoke",
                            }}
                            style={{
                              transition: "border-color 0.3s ease",
                            }}
                            position={"relative"}
                          >
                            <Avatar
                              size="lg"
                              name={employee["NameID"].substring(0, 1)}
                              src={employee["Image"]}
                              marginBottom={"15px"}
                              zIndex={0}
                            />
                            <Box>
                              <Text fontWeight={"500"}>
                                {employee["NameID"]}
                              </Text>
                            </Box>
                            <Box>
                              <Text fontWeight={"400"} color={"gray"}>
                                {employee["Job"]}
                              </Text>
                            </Box>
                            <Flex
                              position={"absolute"}
                              zIndex={1}
                              top={"50%"}
                              left={"38%"}
                              backgroundColor={"#fff"}
                              border={"1px solid lightgray"}
                              width={"fit-content"}
                              height={"fit-content"}
                              paddingX={"8px"}
                              paddingY={"2px"}
                              borderRadius={"15px"}
                              textAlign={"center"}
                              alignItems={"center"}
                              gap={"5px"}
                            >
                              <Text fontSize={"sm"} fontWeight={"500"}>
                                5.0
                              </Text>
                              <StarIcon
                                boxSize={3}
                                mb={"3px"}
                                color={"#ECC94B"}
                              />
                            </Flex>
                          </Flex>
                        </GridItem>
                      )
                  )}
              </Grid>
            </ModalBody>
            <ModalFooter></ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </>
  );
}

export default BookingPageOverview;
