export const Account = {
  title: "KONTO OG PROFIL",
  links: [
    {
      id: 1,
      title: "Hvordan kommer jeg i gang med Bookinghero?",
      link: "/support/account/get-started",
    },
    {
      id: 2,
      title: "Jeg har glemt min adgangskode - hvordan nulstiller jeg den?",
      link: "/support/account/reset-password",
    },
    {
      id: 3,
      title: "Hvordan administrerer jeg mine kontooplysninger?",
      link: "/support/account/account-information",
    },
    {
      id: 4,
      title: "Hvor sikre er mine data?",
      link: "/support/account/security",
    },
    {
      id: 5,
      title: "Hvordan kan mine data gøre min oplevelse bedre?",
      link: "/support/account/data",
    },
    {
      id: 6,
      title: "Hvordan kan jeg deaktivere min konto?",
      link: "/support/account/cancel",
    },
  ],
};

export const How_it_works = {
  title: "HVORDAN VIRKER DET",
  links: [
    {
      id: 1,
      title: "Hvordan bruger jeg Bookinghero?",
      link: "/support/how-it-works/marketplace",
    },
    {
      id: 2,
      title: "Hvordan kan jeg gemme mine yndlingssteder?",
      link: "/support/how-it-works/favorites",
    },
    {
      id: 3,
      title: "Hvordan administrerer jeg aftaler?",
      link: "/support/how-it-works/appointments",
    },
    {
      id: 4,
      title: "Hvilke områder understøttes af Bookinghero?",
      link: "/support/how-it-works/supported-areas",
    },
  ],
};

export const Payments = {
  title: "BETALINGER",
  links: [
    {
      id: 1,
      title: "Hvordan fungerer betaling?",
      link: "/support/payments/system",
    },
    {
      id: 2,
      title: "Hvordan får jeg en kvittering?",
      link: "/support/payments/receipts",
    },
    {
      id: 3,
      title: "Hvad er de understøttede betalingsmetoder?",
      link: "/support/payments/methods",
    },
    {
      id: 4,
      title: "Kan jeg bruge rabatkuponer?",
      link: "/support/payments/coupons",
    },
  ],
};

export const Cancelations = {
  title: "AFBESTILLINGER OG REFUSION",
  links: [
    {
      id: 1,
      title: "Jeg ønsker at aflyse en aftale, hvordan gør jeg?",
      link: "/support/cancel-refund/steps",
    },
    {
      id: 2,
      title: "Salonen aflyste min aftale, hvorfor det?",
      link: "/support/cancel-refund/saloon-cancel",
    },
    {
      id: 3,
      title: "Kan jeg aflyse en aftale 5 minutter før den starter?",
      link: "/support/cancel-refund/timing",
    },
    {
      id: 4,
      title: "Kan jeg trække min anmodning om annullering tilbage?",
      link: "/support/cancel-refund/withdraw",
    },
    {
      id: 5,
      title:
        "Hvad sker der, når jeg aflyser en aftale, og hvordan påvirker det mig? ",
      link: "/support/cancel-refund/client-status",
    },
  ],
};
