import { ArrowBackIcon, CloseIcon } from "@chakra-ui/icons";
import { Box, Flex, IconButton } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";

function BookingPageHeader({ appointment, setAppointment }) {
  const handlePreviousStep = () => {
    setAppointment((prevState) => ({
      ...prevState,
      step:
        prevState.step - 1 > 0
          ? prevState.step - 1
          : 1 && navigate(window.location.pathname.replace("/booking", "")),
    }));
  };
  const navigate = useNavigate();

  return (
    <>
      <Box width={"100%"} height={"fit-content"} bg={"white"}>
        <Flex
          width={"100%"}
          height={"fit-content"}
          justifyContent={"space-between"}
          alignItems={"center"}
          paddingY={"10px"}
          paddingX={"45px"}
        >
          <Box>
            <IconButton
              backgroundColor={"white"}
              aria-label="Previous step"
              icon={<ArrowBackIcon boxSize={"25px"} color={"blackAlpha.800"} />}
              onClick={handlePreviousStep}
            />
          </Box>
          <Box>
            <IconButton
              backgroundColor={"white"}
              aria-label="Cancel booking"
              icon={<CloseIcon color={"blackAlpha.800"} />}
              onClick={() =>
                navigate(window.location.pathname.replace("/booking", ""))
              }
            />
          </Box>
        </Flex>
      </Box>
    </>
  );
}

export default BookingPageHeader;
