import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
  Textarea,
  Tooltip,
  calc,
  textDecoration,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { IoMdArrowBack } from "react-icons/io";

import React, { useCallback, useEffect, useState } from "react";
import MarketplaceNavbar from "../components/MarketplaceNavbar.tsx";
import MarketplaceFooter from "../components/MarketplaceFooter.tsx";
import { MdMyLocation } from "react-icons/md";
import axios from "axios";
import { ThreeDots } from "react-loader-spinner";
import moment from "moment";
import { ChatIcon, CheckCircleIcon } from "@chakra-ui/icons";
import "moment/locale/da";
import { useNavigate } from "react-router-dom";

function AppointmentsPage() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [rating, set_rating] = useState();
  const [hideList, setHideList] = useState(false);
  const [review, set_review] = useState("");
  const [refresh, setRefresh] = useState(false);
  const navigate = useNavigate();

  const monthMap = {
    "01": "januar",
    "02": "februar",
    "03": "marts",
    "04": "april",
    "05": "maj",
    "06": "juni",
    "07": "juli",
    "08": "august",
    "09": "september",
    10: "oktober",
    11: "november",
    12: "december",
  };
  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure();
  const cancelRef = React.useRef();
  const [review_button_loading, set_review_button_loading] = useState(false);
  // Upcoming appointments
  const [upcoming_appointments, set_upcoming_appointments] = useState([]);
  // Completed appointments
  const [completed_appointments, set_completed_appointments] = useState([]);
  // Selected appointment type (upcoming - completed)
  const [selected_appointment_type, set_selected_appointment_type] =
    useState("");
  // Loading data indicator for upcoming appointments list
  const [
    upcoming_appointments_data_loading,
    set_upcoming_appointments_data_loading,
  ] = useState(false);
  // Loading data indicator for appointment information
  const [selected_appointment_loading, set_selected_appointment_loading] =
    useState(false);
  // Selected appointment information
  const [
    selected_appointment_information,
    set_selected_appointment_information,
  ] = useState();

  const toast = useToast();
  const calculateEndTime = (startTime, duration) => {
    const [startHour, startMinute] = startTime.split(":").map(Number);

    // Calculate endTime by adding duration (in minutes)
    const endMinute = (startHour * 60 + startMinute + duration) % 1440;
    const endHour = Math.floor(endMinute / 60);
    const formattedEndMinute = (endMinute % 60).toString().padStart(2, "0");

    return `${endHour.toString().padStart(2, "0")}:${formattedEndMinute}`;
  };
  // Handle retreiving list of upcoming appointments
  const get_upcoming_appointments = useCallback(async () => {
    try {
      const response = await axios.get("/client/web/appointments");
      if (response.status === 200) {
        set_upcoming_appointments(response.data.upcoming_appointments);
        set_completed_appointments(response.data.completed_appointments);
        set_upcoming_appointments_data_loading(false);
      }
    } catch (error) {
      if (error.response.status === 404) {
        toast({
          position: "top-right",
          title: "Session Error",
          description: "Your session has expired, please sign in",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
      if (error.response.status === 500) {
        toast({
          position: "top-right",
          title: "Server error",
          description: "Please try again in a few minutes",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
  }, []);

  // Handle retreiving information of selected appointment
  const get_selected_appointment_information = useCallback(
    async (appointment_id) => {
      try {
        const body = {
          appointment_id: appointment_id,
        };
        const response = await axios.post("/client/web/appointment", body);
        if (response.status === 200) {
          set_selected_appointment_information(response.data.appointment_info);
          set_selected_appointment_loading(false);
        }
      } catch (error) {
        if (error.response.status === 404) {
          toast({
            position: "top-right",
            title: "Session Error",
            description: "Your session has expired, please sign in",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
        if (error.response.status === 500) {
          toast({
            position: "top-right",
            title: "Server error",
            description: "Please try again in a few minutes",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      }
    },
    []
  );

  // Handle submitting a review
  const submit_review = async () => {
    set_review_button_loading(true);
    try {
      const body = {
        appointment_id:
          selected_appointment_information.appointments_info.appointment_id,
        business_id: selected_appointment_information.salon_info.business_id,
        treatment_id:
          selected_appointment_information.appointments_info.treatment_id,
        rating: rating,
        review: review,
      };
      console.log("end");
      const response = await axios.post("/client/web/make-review", body);
      if (response.status === 200) {
        toast({
          position: "top-right",
          title: "Tak !",
          description: "Din mening hjælper os med at forbedre.",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        set_review_button_loading(false);
        onModalClose();
        set_selected_appointment_information(null);
      }
    } catch (error) {
      if (error.response.status === 404) {
        toast({
          position: "top-right",
          title: "Session Error",
          description: "Your session has expired, please sign in",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
      if (error.response.status === 500) {
        toast({
          position: "top-right",
          title: "Server error",
          description: "Please try again in a few minutes",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
  };

  // Handle canceling appointment
  const cancelAppointment = async () => {
    try {
      await axios.post("client/web/cancel-appointment", {
        salon_slug:
          selected_appointment_information.appointments_info.saloon_slug,
        date: selected_appointment_information.appointments_info.date_time.slice(
          0,
          10
        ),
        entry_no:
          selected_appointment_information.appointments_info.admind_entry_id,
        appointment_id:
          selected_appointment_information.appointments_info.appointment_id,
      });
      toast({
        position: "top-right",
        title: "Aftale aflyst",
        description: "Din aftale er blevet aflyst",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      onClose();
      setRefresh(!refresh);
    } catch (error) {
      if (error.response.status === 404) {
        toast({
          position: "top-right",
          title: "Authntification Error",
          description: "There have been a problem in session, please try again",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
      if (error.response.status === 500) {
        toast({
          position: "top-right",
          title: "Server Error",
          description: "There have been a connexion error, please try again",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
  };

  // Retreive list of upcoming apointments on page load
  useEffect(() => {
    window.scrollTo(0, 0);
    set_upcoming_appointments_data_loading(true);
    get_upcoming_appointments();
    set_selected_appointment_information();
  }, [refresh]);

  // Handle selecting appointment
  const select_appointment = (appointment_id, type) => {
    set_selected_appointment_loading(true);
    set_selected_appointment_type(type);
    set_rating(null);
    get_selected_appointment_information(appointment_id);
  };

  // Format date and time of appointment
  const format_date_time = (date_time_string) => {
    const moment_obj = moment(date_time_string);
    return moment_obj.format("ddd DD MMM YYYY [kl] h:mm");
  };

  // Upcoming appointments cards component
  const appointments_cards = (
    <>
      <Flex flexDirection={"column"} gap={"15px"} mb={"25px"}>
        {upcoming_appointments.map((appointment, index) => (
          <Box
            key={index}
            cursor={"pointer"}
            padding={"8px"}
            height={"fit-content"}
            width={"85%"}
            bg={"gray.100"}
            _hover={{
              bg: "gray.200",
            }}
            borderRadius={"12px"}
            marginLeft={{
              base: "30px",
              sm: "30px",
              md: "30px",
              lg: "30px",
              xl: "60px",
            }}
            onClick={() => {
              select_appointment(appointment.appointment_id, "upcoming");
              setHideList(true);
            }}
          >
            <Flex alignContent={"center"} gap={"10px"}>
              <Image
                boxSize="80px"
                objectFit="cover"
                src={appointment.salon_image}
                alt="Dan Abramov"
                borderRadius={"12px"}
              />
              <Flex gap={"4px"} height={"100%"} flexDirection={"column"}>
                <Text fontWeight={"600"} fontSize={"md"}>
                  {appointment.salon_name}
                </Text>
                <Text fontSize={"sm"} fontWeight={"500"}>
                  {format_date_time(appointment.date_time)}
                </Text>
                <Text fontSize={"sm"} fontWeight={"500"} color={"gray"}>
                  {appointment.treatment_price} DKK
                </Text>
              </Flex>
            </Flex>
          </Box>
        ))}
      </Flex>
    </>
  );

  // Loading spinner component
  const loading_spinner = (
    <>
      <Box
        marginLeft={{
          base: "30px",
          sm: "30px",
          md: "30px",
          lg: "30px",
          xl: "60px",
        }}
        width={"100%"}
        textAlign={"center"}
      >
        <ThreeDots
          visible={true}
          height="70"
          width="80"
          color="#23a994"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </Box>
    </>
  );

  // completed appointments cards component
  const completed_appointments_cards = (
    <>
      <Flex alignItems={"center"} gap={"15px"} mb={"20px"}>
        <Text
          fontSize={"lg"}
          fontWeight={"600"}
          paddingLeft={{
            base: "30px",
            sm: "30px",
            md: "30px",
            lg: "30px",
            xl: "60px",
          }}
        >
          Fuldført
        </Text>
        <Box
          borderRadius={"50%"}
          width={"25px"}
          height={"25px"}
          backgroundColor={"#23a994"}
          textAlign={"center"}
        >
          <Text fontSize={"lg"} fontWeight={"600"} color={"white"}>
            {completed_appointments && completed_appointments.length}
          </Text>
        </Box>
      </Flex>
      {upcoming_appointments_data_loading ? (
        loading_spinner
      ) : (
        <>
          <Flex flexDirection={"column"} gap={"15px"}>
            {completed_appointments.map((appointment, index) => (
              <Box
                key={index}
                cursor={"pointer"}
                padding={"8px"}
                height={"fit-content"}
                width={"85%"}
                bg={appointment.active === 0 ? "red.100" : "green.100"}
                _hover={{
                  bg: appointment.active === 0 ? "red.200" : "green.200",
                }}
                borderRadius={"12px"}
                marginLeft={{
                  base: "30px",
                  sm: "30px",
                  md: "30px",
                  lg: "30px",
                  xl: "60px",
                }}
                onClick={() => {
                  select_appointment(
                    appointment.appointment_id,
                    appointment.active === 0 ? "canceled" : "completed"
                  );
                  setHideList(true);
                }}
              >
                <Flex alignContent={"center"} gap={"10px"}>
                  <Image
                    boxSize="80px"
                    objectFit="cover"
                    src={appointment.salon_image}
                    alt="Dan Abramov"
                    borderRadius={"12px"}
                  />
                  <Flex gap={"4px"} height={"100%"} flexDirection={"column"}>
                    <Text fontWeight={"600"} fontSize={"md"}>
                      {appointment.salon_name}
                    </Text>
                    <Text fontSize={"sm"} fontWeight={"500"}>
                      {format_date_time(appointment.date_time)}
                    </Text>
                    <Text fontSize={"sm"} fontWeight={"500"} color={"gray"}>
                      {appointment.treatment_price} DKK
                    </Text>
                  </Flex>
                </Flex>
              </Box>
            ))}
          </Flex>
        </>
      )}
    </>
  );

  // Selected appointment card component
  const selected_appointment_card = (
    <>
      {selected_appointment_information && (
        <Box
          width={"600px"}
          height={"fit-content"}
          borderRadius={"12px"}
          boxShadow={"sm"}
          border={"1px solid lightgray"}
        >
          <Box
            bgImage={selected_appointment_information.salon_info.image_url}
            width={"100%"}
            borderTopRadius={"inherit"}
            height={"300px"}
            mb={"20px"}
            backgroundSize={"cover"}
            backgroundPosition={"15%"}
          ></Box>
          <Box width={"100%"} paddingX={"20px"}>
            {(selected_appointment_type === "completed" ||
              selected_appointment_type === "canceled") && (
              <Flex
                width={"100%"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <>
                  <Tag
                    size={"lg"}
                    borderRadius="full"
                    variant="solid"
                    bg={
                      selected_appointment_type === "completed"
                        ? "green.200"
                        : "red.200"
                    }
                    color={"black"}
                    mb={"20px"}
                  >
                    <TagLabel>
                      {selected_appointment_type === "completed"
                        ? "Bestået"
                        : "Aflyst"}
                    </TagLabel>
                  </Tag>
                </>

                <Button
                  borderRadius={"18px"}
                  background={"none"}
                  border={"1px #309593 solid"}
                  marginBottom={"20px"}
                  size={"lg"}
                  onClick={() =>
                    navigate(
                      `/d/${selected_appointment_information.appointments_info.saloon_slug}/booking`
                    )
                  }
                >
                  Rebook
                </Button>
              </Flex>
            )}
            <Heading fontSize={"30px"} mb={"10px"}>
              <Link
                _hover={{ textDecoration: "none" }}
                href={`/d/${selected_appointment_information.appointments_info.saloon_slug}`}
              >
                {selected_appointment_information.salon_info.name}
              </Link>
            </Heading>
            <Text fontSize={"xl"} fontWeight={"500"} mb={"5px"}>
              {format_date_time(
                selected_appointment_information.appointments_info.date_time
              )}
            </Text>
            <Text color={"gray"} fontWeight={"600"} mb={"40px"}>
              {
                selected_appointment_information.appointments_info
                  .treatment_duration
              }
              min Varighed
            </Text>
            <Flex alignItems={"center"} gap={"15px"} mb={"20px"}>
              <Box
                height={"50px"}
                width={"50px"}
                borderRadius={"50%"}
                textAlign={"center"}
                background={"teal.200"}
              >
                <Icon
                  as={MdMyLocation}
                  boxSize={"30px"}
                  color={"teal.500"}
                  marginTop={"10px"}
                />
              </Box>
              <Flex flexDirection={"column"}>
                <Text fontWeight={"500"} fontSize={"lg"}>
                  Er på vej
                </Text>
                <Text>
                  {selected_appointment_information.salon_info.address},{" "}
                  {selected_appointment_information.salon_info.city}
                </Text>
              </Flex>
            </Flex>
            <Divider mb={"30px"} />
            <Text fontSize={"xl"} fontWeight={"600"} mb={"30px"}>
              Oversigt
            </Text>
            <Flex
              justifyContent={"space-between"}
              alignItems={"center"}
              mb={"30px"}
            >
              <Box>
                <Text fontWeight={"500"}>
                  {
                    selected_appointment_information.appointments_info
                      .treatment_name
                  }
                </Text>
                <Text color={"gray"}>
                  {
                    selected_appointment_information.appointments_info
                      .treatment_duration
                  }
                  min med{" "}
                  {
                    selected_appointment_information.appointments_info
                      .employee_name
                  }
                </Text>
              </Box>
              <Box>
                <Text fontWeight={"500"}>
                  {
                    selected_appointment_information.appointments_info
                      .treatment_price
                  }{" "}
                  DKK
                </Text>
              </Box>
            </Flex>
            <Divider mb={"30px"} />
            <Box mb={"30px"}>
              <Text fontSize={"xl"} fontWeight={"600"} mb={"10px"}>
                Aflysningspolitik
              </Text>
              <Text>
                Undlad venligst at aflyse inden for <strong> 2 timer</strong>{" "}
                før din aftaletid
              </Text>
            </Box>
          </Box>
          <Box mb={"10px"} paddingX={"20px"}>
            {selected_appointment_type === "upcoming" && (
              <Flex justifyContent={"space-between"} px={1}>
                <Button
                  width={"100%"}
                  mr={"3px"}
                  colorScheme="orange"
                  onClick={onOpen}
                  _hover={{ transform: "scale(1.02)" }}
                  style={{ transition: "all 250ms ease-in" }}
                  isDisabled={
                    !(
                      selected_appointment_information &&
                      selected_appointment_information.appointments_info
                        .active === 1
                    )
                  }
                >
                  Aflys aftale
                </Button>
                <Button
                  width={"100%"}
                  ml={"3px"}
                  colorScheme="green"
                  _hover={{ transform: "scale(1.02)" }}
                  style={{ transition: "all 250ms ease-in" }}
                  onClick={() => {
                    localStorage.setItem(
                      "appointment",
                      JSON.stringify({
                        step: 1,
                        create: false,
                        personal: true,
                        sms: true,
                        entry_no:
                          selected_appointment_information.appointments_info
                            .admind_entry_id,
                        appointment_id:
                          selected_appointment_information.appointments_info
                            .appointment_id,
                        SalonSlug:
                          selected_appointment_information.appointments_info
                            .saloon_slug,
                        schedule: {
                          day: selected_appointment_information.appointments_info.date_time.substring(
                            8,
                            10
                          ),
                          dayInText: new Date(
                            selected_appointment_information.appointments_info.date_time
                          ).toLocaleDateString("da-DK", { weekday: "long" }),
                          year: selected_appointment_information.appointments_info.date_time.substring(
                            0,
                            4
                          ),
                          month:
                            monthMap[
                              selected_appointment_information.appointments_info.date_time.substring(
                                5,
                                7
                              )
                            ],
                          startTime:
                            selected_appointment_information.appointments_info.date_time.substring(
                              11,
                              16
                            ),
                          endTime: calculateEndTime(
                            selected_appointment_information.appointments_info.date_time.substring(
                              11,
                              16
                            ),
                            selected_appointment_information.appointments_info
                              .treatment_duration
                          ),
                        },
                        treatment: {
                          Price:
                            selected_appointment_information.appointments_info
                              .treatment_price,
                          Duration:
                            selected_appointment_information.appointments_info
                              .treatment_duration,
                          Name: selected_appointment_information
                            .appointments_info.treatment_name,
                          Type: -1,
                          DurationFrom: "",
                          PriceFrom: "",
                          ID: selected_appointment_information.appointments_info
                            .treatment_id,
                        },
                        employee: {
                          Name: selected_appointment_information
                            .appointments_info.employee_name,
                          Speciality: "",
                          Image: "",
                          ID: selected_appointment_information.appointments_info
                            .employee_id,
                        },
                        client: {
                          Name: "",
                          Address: "",
                          Phone: "",
                          Email: "",
                          Note: "",
                          Complete: false,
                        },
                      })
                    );
                    navigate(
                      `/d/${selected_appointment_information.appointments_info.saloon_slug}/booking`
                    );
                  }}
                  isDisabled={
                    !(
                      selected_appointment_information &&
                      selected_appointment_information.appointments_info
                        .active === 1
                    )
                  }
                >
                  Opdatering
                </Button>
              </Flex>
            )}
            {selected_appointment_type === "completed" && (
              <>
                <Button
                  width={"100%"}
                  bg={"gray.800"}
                  color={"white"}
                  _hover={{
                    backgroundColor: "gray.900",
                    transform: "scale(1.05)",
                  }}
                  style={{ transition: "all 250ms ease-in" }}
                  onClick={onModalOpen}
                  isDisabled={
                    selected_appointment_information.appointments_info
                      .is_reviewed
                  }
                >
                  Skriv anmeldelse
                </Button>
              </>
            )}
          </Box>
        </Box>
      )}
    </>
  );

  // Loading spinner for selected card component
  const selected_spinner = (
    <>
      <Box width={"fit-content"} textAlign={"center"} marginTop={"200px"}>
        <ThreeDots
          visible={true}
          height="70"
          width="80"
          color="#23a994"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </Box>
    </>
  );

  return (
    <>
      <Box position={"relative"}>
        <Box position="sticky" top={0} zIndex={3} boxShadow={"sm"}>
          <MarketplaceNavbar />
        </Box>
        <Box h={`100%`} width={"100%"} zIndex={2}>
          <Grid
            templateColumns="repeat(6, 1fr)"
            gap="24px"
            minH={"100%"}
            width={"100%"}
          >
            <GridItem
              colSpan={{ base: 6, sm: 6, md: 6, lg: 2 }}
              borderRight={"1px solid lightgray"}
              position={"sticky"}
              top={"50px"}
              h={`calc(100vh - 100px)`}
              overflowY={"scroll"}
              display={{ md: hideList ? "none" : "block", lg: "block" }}
            >
              <Box width={"100%"}>
                <Text
                  fontSize={"xl"}
                  fontWeight={"600"}
                  paddingTop={"40px"}
                  paddingBottom={"30px"}
                  paddingLeft={{
                    base: "30px",
                    sm: "30px",
                    md: "30px",
                    lg: "30px",
                    xl: "60px",
                  }}
                >
                  Aftaler
                </Text>
                <Flex alignItems={"center"} gap={"15px"} mb={"20px"}>
                  <Text
                    fontSize={"lg"}
                    fontWeight={"600"}
                    paddingLeft={{
                      base: "30px",
                      sm: "30px",
                      md: "30px",
                      lg: "30px",
                      xl: "60px",
                    }}
                  >
                    Forestående
                  </Text>
                  <Box
                    borderRadius={"50%"}
                    width={"25px"}
                    height={"25px"}
                    backgroundColor={"#23a994"}
                    textAlign={"center"}
                  >
                    <Text fontSize={"lg"} fontWeight={"600"} color={"white"}>
                      {upcoming_appointments && upcoming_appointments.length}
                    </Text>
                  </Box>
                </Flex>

                {upcoming_appointments_data_loading
                  ? loading_spinner
                  : appointments_cards}

                {completed_appointments && completed_appointments_cards}
              </Box>
            </GridItem>
            <GridItem
              colSpan={{ base: 0, sm: 0, md: 0, lg: 4 }}
              display={{
                base: "none",
                sm: "none",
                md: hideList ? "block" : "none",
                lg: "block",
              }}
              width={hideList ? "90%" : "auto"}
              margin={"auto"}
            >
              <Box
                onClick={() => setHideList(false)}
                display={{ md: "block", lg: "none" }}
                cursor={"pointer"}
              >
                <IoMdArrowBack style={{ width: "28px", height: "28px" }} />
              </Box>
              <Flex
                width={"100%"}
                height={"fit-content"}
                justifyContent={"center"}
                paddingY={"25px"}
              >
                {selected_appointment_loading
                  ? selected_spinner
                  : selected_appointment_card}
              </Flex>
            </GridItem>
          </Grid>
        </Box>
        <Box>
          <MarketplaceFooter />
        </Box>
      </Box>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Aflys Aftale
            </AlertDialogHeader>

            <AlertDialogBody>
              Er du sikker? Du kan ikke fortryde denne handling bagefter.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Behold
              </Button>
              <Button colorScheme="red" onClick={cancelAppointment} ml={3}>
                Aflys
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Modal
        isOpen={isModalOpen}
        onClose={onModalClose}
        motionPreset="slideInBottom"
        size={"2xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Flex alignItems={"center"} gap={"10px"}>
              <Box
                width={"30px"}
                height={"30px"}
                borderRadius={"50%"}
                backgroundColor={useColorModeValue("gray.800", "gray.900")}
                color={"white"}
                textAlign={"center"}
              >
                <ChatIcon />
              </Box>
              <Text>Feedback</Text>
            </Flex>
          </ModalHeader>
          <Divider width={"80%"} textAlign={"center"} alignSelf={"center"} />
          <ModalCloseButton />
          <ModalBody paddingX={"80px"}>
            <Box width={"100%"} marginTop={"20px"} marginBottom={"20px"}>
              <Heading textAlign={"center"} fontSize={"2xl"} fontWeight={"600"}>
                Hvordan var din aftale ?
              </Heading>
            </Box>
            <Box marginBottom={"50px"}>
              <Text textAlign={"center"}>
                Din feedback er værdifuld og hjælper os med at forstå dine behov
                bedre samt tilpasse vores service derefter.
              </Text>
            </Box>
            <Box marginBottom={"50px"} width={"100%"} textAlign={"center"}>
              <Flex
                alignItems={"center"}
                gap={"25px"}
                justifyContent={"center"}
              >
                <Tooltip
                  hasArrow
                  label="Rædselsfuldt"
                  bg="gray.800"
                  color="white"
                  borderRadius={"3px"}
                >
                  <Box
                    width={"65px"}
                    height={"65px"}
                    background={
                      rating === 1
                        ? "linear-gradient(to bottom, #ffffcc, #f0f0f0)"
                        : "gray.200"
                    }
                    borderRadius={"50%"}
                    textAlign={"center"}
                    fontSize={"30px"}
                    color={"gray.300"}
                    cursor={"pointer"}
                    padding={"5px"}
                    filter={`grayscale(${rating === 1 ? "0%" : "80%"})`}
                    transform={rating === 1 ? "scale(1.2)" : ""}
                    transition="transform 0.3s ease-in-out, background 0.3s ease-in-out, filter 0.3s ease-in-out"
                    _hover={{
                      filter: "grayscale(0%)",
                      transform: "scale(1.2)", // Increase the size on hover
                      background:
                        "linear-gradient(to bottom, #ffffcc, #f0f0f0)",
                    }}
                    onClick={() => {
                      set_rating(1);
                    }}
                  >
                    <Image src="https://freepngimg.com/thumb/emoji/8-2-fearful-emoji-png.png" />
                  </Box>
                </Tooltip>
                <Tooltip
                  hasArrow
                  label="Dårligt"
                  bg="gray.800"
                  color="white"
                  borderRadius={"3px"}
                >
                  <Box
                    width={"65px"}
                    height={"65px"}
                    background={
                      rating === 2
                        ? "linear-gradient(to bottom, #ffffcc, #f0f0f0)"
                        : "gray.200"
                    }
                    borderRadius={"50%"}
                    textAlign={"center"}
                    fontSize={"30px"}
                    color={"gray.300"}
                    cursor={"pointer"}
                    padding={"7px"}
                    filter={`grayscale(${rating === 2 ? "0%" : "80%"})`}
                    transform={rating === 2 ? "scale(1.2)" : ""}
                    transition="transform 0.3s ease-in-out, background 0.3s ease-in-out, filter 0.3s ease-in-out"
                    _hover={{
                      filter: "grayscale(0%)",
                      transform: "scale(1.2)", // Increase the size on hover
                      background:
                        "linear-gradient(to bottom, #ffffcc, #f0f0f0)",
                    }}
                    onClick={() => {
                      set_rating(2);
                    }}
                  >
                    <Image src="https://freepngimg.com/thumb/emoji/10-2-unamused-face-emoji-png.png" />
                  </Box>
                </Tooltip>
                <Tooltip
                  hasArrow
                  label="Gennemsnitligt"
                  bg="gray.800"
                  color="white"
                  borderRadius={"3px"}
                >
                  <Box
                    width={"65px"}
                    height={"65px"}
                    background={
                      rating === 3
                        ? "linear-gradient(to bottom, #ffffcc, #f0f0f0)"
                        : "gray.200"
                    }
                    borderRadius={"50%"}
                    textAlign={"center"}
                    fontSize={"30px"}
                    color={"gray.300"}
                    cursor={"pointer"}
                    padding={"8px"}
                    filter={`grayscale(${rating === 3 ? "0%" : "80%"})`}
                    transform={rating === 3 ? "scale(1.2)" : ""}
                    transition="transform 0.3s ease-in-out, background 0.3s ease-in-out, filter 0.3s ease-in-out"
                    _hover={{
                      filter: "grayscale(0%)",
                      transform: "scale(1.2)", // Increase the size on hover
                      background:
                        "linear-gradient(to bottom, #ffffcc, #f0f0f0)",
                    }}
                    onClick={() => {
                      set_rating(3);
                    }}
                  >
                    <Image src="https://freepngimg.com/thumb/emoji/77530-emoticon-thinking-thought-world-whatsapp-day-emoji.png" />
                  </Box>
                </Tooltip>
                <Tooltip
                  hasArrow
                  label="Godt"
                  bg="gray.800"
                  color="white"
                  borderRadius={"3px"}
                >
                  <Box
                    width={"65px"}
                    height={"65px"}
                    background={
                      rating === 4
                        ? "linear-gradient(to bottom, #ffffcc, #f0f0f0)"
                        : "gray.200"
                    }
                    borderRadius={"50%"}
                    textAlign={"center"}
                    fontSize={"30px"}
                    color={"gray.300"}
                    cursor={"pointer"}
                    padding={"5px"}
                    filter={`grayscale(${rating === 4 ? "0%" : "80%"})`}
                    transform={rating === 4 ? "scale(1.2)" : ""}
                    transition="transform 0.3s ease-in-out, background 0.3s ease-in-out, filter 0.3s ease-in-out"
                    _hover={{
                      filter: "grayscale(0%)",
                      transform: "scale(1.2)", // Increase the size on hover
                      background:
                        "linear-gradient(to bottom, #ffffcc, #f0f0f0)",
                    }}
                    onClick={() => {
                      set_rating(4);
                    }}
                  >
                    <Image src="https://freepngimg.com/thumb/emoji/47416-2-smiley-image-free-png-hq.png" />
                  </Box>
                </Tooltip>
                <Tooltip
                  hasArrow
                  label="Fremragende"
                  bg="gray.800"
                  color="white"
                  borderRadius={"3px"}
                >
                  <Box
                    width={"65px"}
                    height={"65px"}
                    background={
                      rating === 5
                        ? "linear-gradient(to bottom, #ffffcc, #f0f0f0)"
                        : "gray.200"
                    }
                    borderRadius={"50%"}
                    textAlign={"center"}
                    fontSize={"30px"}
                    color={"gray.300"}
                    cursor={"pointer"}
                    padding={"3px"}
                    filter={`grayscale(${rating === 5 ? "0%" : "80%"})`}
                    transform={rating === 5 ? "scale(1.2)" : ""}
                    transition="transform 0.3s ease-in-out, background 0.3s ease-in-out, filter 0.3s ease-in-out"
                    _hover={{
                      filter: "grayscale(0%)",
                      transform: "scale(1.2)", // Increase the size on hover
                      background:
                        "linear-gradient(to bottom, #ffffcc, #f0f0f0)",
                    }}
                    onClick={() => {
                      set_rating(5);
                    }}
                  >
                    <Image src="https://freepngimg.com/thumb/emoji/65263-emoticon-of-smiley-face-tears-joy-whatsapp.png" />
                  </Box>
                </Tooltip>
              </Flex>
            </Box>
            <Box width={"100%"}>
              <Textarea
                width={"100%"}
                placeholder="Tilføj en kommentar..."
                resize="none"
                height={"100px"}
                onChange={(event) => {
                  set_review(event.target.value);
                }}
              />
            </Box>
          </ModalBody>

          <ModalFooter paddingX={"80px"}>
            <Button
              width={"100%"}
              backgroundColor={useColorModeValue("gray.800", "gray.900")}
              _hover={
                !(review === "" || !rating) && {
                  transform: "scale(1.05)",
                }
              }
              style={{ transition: "all 250ms ease-in" }}
              color={"white"}
              isDisabled={review === "" || !rating}
              isLoading={review_button_loading}
              spinner={
                <ThreeDots
                  visible={true}
                  height="30"
                  width="30"
                  color="white"
                  radius="9"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              }
              onClick={submit_review}
            >
              Indsend nu
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default AppointmentsPage;
