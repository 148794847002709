import {
  Flex,
  Box,
  Image,
  Badge,
  Heading,
  Link,
  useToast,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Spinner,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { BsStar, BsStarFill, BsStarHalf } from "react-icons/bs";
import axios from "axios";
import { LockIcon } from "@chakra-ui/icons";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
import { useLoadScript } from "@react-google-maps/api";
interface RatingProps {
  rating: number;
  numReviews: number;
}

function Rating({ rating, numReviews }: RatingProps) {
  return (
    <Box display="flex" alignItems="center">
      <Box
        as="span"
        mr="1"
        mt="0.5"
        color="gray.600"
        fontSize="sm"
        fontWeight={"bold"}
      >
        {rating}
      </Box>
      <BsStarFill style={{ marginLeft: "1" }} color={"#ECC94B"} />

      <Box
        as="span"
        ml="2"
        mt="0.5"
        color="gray.600"
        fontSize="sm"
        fontWeight={"600"}
      >
        ({numReviews})
      </Box>
    </Box>
  );
}

function MarketplaceNearbySalons() {
  // Google maps libraries
  const libraries = ["places"];
  // Google maps api loader - Api key
  useLoadScript({
    googleMapsApiKey: "AIzaSyAYXObwfBn4s7Fmgwy96ztYPSypI9l-Hmg",
    libraries,
  });
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [nearby_salons, set_nearby_salons] = useState([{}]);

  const [visible, set_visible] = useState(
    Cookies.get("accept_cookies") || false
  );

  const secretKey = "bookinghero";

  const toast = useToast();

  const [lat, setLat] = useState(
    Cookies.get("accept_cookies")
      ? Cookies.get("blt") &&
          CryptoJS.AES.decrypt(Cookies.get("blt"), secretKey).toString(
            CryptoJS.enc.Utf8
          )
      : ""
  );
  const [lng, setLng] = useState(
    Cookies.get("accept_cookies")
      ? Cookies.get("blg") &&
          CryptoJS.AES.decrypt(Cookies.get("blg"), secretKey).toString(
            CryptoJS.enc.Utf8
          )
      : ""
  );

  //to control cookies prompt if there is no refresh but there is useEffect
  const [alreadypressed, setAlreadypressed] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `client/web/nearby/?client_lat=${lat}&client_lng=${lng}`
        );

        if (res.status === 200) {
          let loop_array = [{}];
          for (let index = 0; index < 4; index++) {
            if (
              res.data.nearby_salons.nearby_saloons[index].saloon_tag ===
              "external"
            ) {
              loop_array.push({
                tag: res.data.nearby_salons.nearby_saloons[index].saloon_tag,
                name: res.data.nearby_salons.nearby_saloons[index].saloon_name,
                adress:
                  res.data.nearby_salons.nearby_saloons[index].saloon_adress,
                rating:
                  res.data.nearby_salons.nearby_saloons[index].saloon_rating,
                image_url:
                  res.data.nearby_salons.nearby_saloons[index].saloon_logo,
                number_reviews:
                  res.data.nearby_salons.nearby_saloons[index]
                    .saloon_number_reviews,
                distance:
                  res.data.nearby_salons.nearby_saloons[index].saloon_distance,
                slug: res.data.nearby_salons.nearby_saloons[index].saloon_slug,
              });
            } else {
              loop_array.push({
                tag: res.data.nearby_salons.nearby_saloons[index].saloon_tag,
                name: res.data.nearby_salons.nearby_saloons[index].saloon_name,
                adress:
                  res.data.nearby_salons.nearby_saloons[index].saloon_adress,
                rating:
                  res.data.nearby_salons.nearby_saloons[index].saloon_rating,
                image_url:
                  "https://api-v3.bookinghero.io" +
                  res.data.nearby_salons.nearby_saloons[index].saloon_logo,
                number_reviews:
                  res.data.nearby_salons.nearby_saloons[index]
                    .saloon_number_reviews,
                distance:
                  res.data.nearby_salons.nearby_saloons[index].saloon_distance,
                slug: res.data.nearby_salons.nearby_saloons[index].saloon_slug,
              });
            }
          }
          set_nearby_salons(loop_array.slice(-4));
        }
      } catch (err) {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et par minutter",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    };
    if (!alreadypressed && !Cookies.get("accept_cookies")) {
      onOpen();
      setAlreadypressed(true);
    } else {
      handle_location();
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lat, lng]);

  const handle_accept_cookies = async () => {
    const cookieOptions = {
      sameSite: "strict", // Set the SameSite attribute to 'Strict'
      expires: 365,
    };
    Cookies.set("accept_cookies", true, cookieOptions);
    onClose();
  };

  const handle_location = async () => {
    // Setting the cookie options
    const cookieOptions = {
      sameSite: "strict", // Set the SameSite attribute to 'Strict'
      expires: 365,
    };
    // Setting the users Ip adress

    if (Cookies.get("accept_cookies")) {
      fetch("https://api.ipify.org/?format=json")
        .then((response) => response.json())
        .then((data) => {
          const encryptedValue = CryptoJS.AES.encrypt(
            data.ip,
            secretKey
          ).toString();
          Cookies.set("bip", encryptedValue, cookieOptions);
        });
    }
    // Setting the users location information
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLat(position?.coords?.latitude.toString());
          setLng(position?.coords?.longitude.toString());

          if (Cookies.get("accept_cookies")) {
            const encryptedLat = CryptoJS.AES.encrypt(
              position?.coords?.latitude.toString(),
              secretKey
            ).toString();
            Cookies.set("blt", encryptedLat, cookieOptions);

            const encryptedLng = CryptoJS.AES.encrypt(
              position?.coords?.longitude.toString(),
              secretKey
            ).toString();
            Cookies.set("blg", encryptedLng, cookieOptions);
          }
          const geocoder = new window.google.maps.Geocoder();
          const location = new window.google.maps.LatLng(
            position.coords.latitude,
            position.coords.longitude
          );
          if (Cookies.get("accept_cookies")) {
            geocoder.geocode({ location }, (results, status) => {
              if (status === "OK") {
                const city = results?.[0].address_components.find((component) =>
                  component.types.includes("locality")
                )?.long_name;

                const country = results?.[0].address_components.find(
                  (component) => component.types.includes("country")
                )?.long_name;
                const encryptedCity = CryptoJS.AES.encrypt(
                  city,
                  secretKey
                ).toString();
                const encryptedCountry = CryptoJS.AES.encrypt(
                  country,
                  secretKey
                ).toString();
                Cookies.set("bctr", encryptedCountry, cookieOptions);
                Cookies.set("bct", encryptedCity, cookieOptions);
              }
            });
          }
        },
        (error) => {
          console.error(error);
        },
        {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 0,
        }
      );
    }
    set_visible(true);
  };
  const [isHovered, setIsHovered] = useState();
  return (
    <>
      <Flex alignItems="center" justifyContent="center" mt={"20px"}>
        <Heading>Saloner i nærheden</Heading>
      </Flex>
      {visible ? (
        <>
          <Flex
            p={50}
            w="full"
            alignItems="center"
            justifyContent="center"
            css={{
              "@media (max-width: 1268px)": {
                flexWrap: "wrap",
              },
            }}
            gap={"50px"}
          >
            {!(lat && lng) ? (
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              />
            ) : (
              nearby_salons.map((data, index) => (
                <Box
                  key={index}
                  bg={"white.800"}
                  width={{
                    base: "100%",
                    md: "400px",
                    sm: "400px",
                    lg: "300px",
                  }} // Use responsive width values
                  maxH={{ base: "auto", sm: "600px", md: "350px", lg: "300px" }} // Use responsive maxH values
                  borderWidth="1px"
                  rounded="lg"
                  shadow="lg"
                  position="relative"
                  mb={5} // Add margin bottom to create space between rows
                  as={"a"}
                  href={
                    (data as { tag?: string }).tag === "external"
                      ? `/e/${(data as { slug?: string }).slug}`
                      : `/d/${(data as { slug?: string }).slug}`
                  }
                  style={{ textDecoration: "none" }}
                  onMouseEnter={() => setIsHovered(index)}
                  onMouseLeave={() => setIsHovered(null)}
                  overflow={"hidden"}
                >
                  <Box position="relative" overflow={"hidden"}>
                    <Image
                      src={(data as { image_url?: string }).image_url!}
                      alt={`Picture of ${(data as { name?: string }).name}`}
                      roundedTop="lg"
                      objectFit={"cover"}
                      height={{
                        md: "200px",
                        lg: "150px",
                        sm: "200px",
                        base: "200px",
                      }}
                      width={"100%"}
                      style={{
                        transform: isHovered === index ? "scale(1.2)" : "none",
                        transition: "transform 0.3s ease",
                      }}
                    />
                  </Box>
                  <Box p="6">
                    <Box display="flex" alignItems="baseline"></Box>
                    <Flex
                      mt="1"
                      justifyContent="space-between"
                      alignContent="center"
                    >
                      <Box
                        fontSize="xl"
                        fontWeight="semibold"
                        as="h4"
                        lineHeight="tight"
                      >
                        <Text
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "250px",
                          }}
                        >
                          {(data as { name?: string }).name}
                        </Text>
                      </Box>
                    </Flex>
                    <Flex justifyContent="space-between" alignContent="center">
                      <Box
                        fontSize="sm"
                        fontWeight="semibold"
                        as="h4"
                        lineHeight="tight"
                        mb={1}
                        mt={2}
                      >
                        <Text
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "250px",
                            color: "teal",
                          }}
                        >
                          {(data as { adress?: string }).adress}
                        </Text>
                      </Box>
                    </Flex>

                    <Flex justifyContent="space-between" alignContent="center">
                      <Rating
                        rating={data.rating}
                        numReviews={data.number_reviews}
                      />
                      <Box>{(data as { distance?: string }).distance} km</Box>
                    </Flex>
                  </Box>
                </Box>
              ))
            )}
          </Flex>
          <Flex alignItems="center" justifyContent="center" mb={"70px"}>
            <Link href={"/søg?type=all&sort=nearest&page=1"}>Se alle</Link>
          </Flex>
        </>
      ) : (
        <>
          <Flex
            alignItems="center"
            justifyContent="center"
            mt={"20px"}
            mb={"60px"}
            position="relative"
          >
            <Box
              backgroundImage={{
                md: process.env.PUBLIC_URL + "/assets/nearby_salons.png",
                base: "none",
              }}
              backgroundRepeat={"no-repeat"}
              backgroundSize={{ base: "cover", md: "contain" }}
              width={"70%"}
              height={{ base: "200px", md: "400px" }}
              boxShadow="md"
              rounded="lg"
              borderRadius={"12px"}
              position="relative"
              zIndex={"0"}
            >
              <Box
                position="absolute"
                top={0}
                left={0}
                width="100%"
                height="100%"
                backgroundColor="rgba(255, 255, 255, 0.8)" // Adjust the alpha value to control transparency
              />
            </Box>
            <Button
              size={"lg"}
              borderRadius={"12px"}
              fontSize={"xl"}
              fontWeight={600}
              color={"white"}
              _hover={{ backgroundColor: "teal.500" }}
              bg={"teal.400"}
              position="absolute"
              top={{ md: "60%", base: "40%" }}
              left={"auto"}
              zIndex={"1"}
              onClick={handle_location}
            >
              vis nærliggende saloner
            </Button>
          </Flex>
        </>
      )}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          position="fixed"
          bottom={0}
          left={"auto"}
          right={"auto"}
          maxWidth={"900px"}
        >
          <ModalHeader alignItems={"center"}>
            Vores cookies
            <LockIcon
              width={"15px"}
              color={"blue.400"}
              marginBottom={"4px"}
              marginLeft={"10px"}
            />
          </ModalHeader>
          <ModalBody>
            Vi bruger cookies for at sikre, at du får den bedste oplevelse, når
            du færdes på vores side. Ved at klikke på &quot;Accepter alle
            cookies&quot; giver du os lov til at give dig personlige
            anbefalinger, som vi tror, du vil kunne lide.
            <br />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" mr={10} onClick={handle_accept_cookies}>
              Accepter alle cookies
            </Button>
            <Link onClick={onClose}>Afvis</Link>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default MarketplaceNearbySalons;
