import {
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Divider,
  useToast,
  Link,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import "./MarketplaceCategories.css";

function MarketplaceCategories() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [categories, setCategories] = useState([]);

  const toast = useToast();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get("client/web/categories/");

        if (res.status === 200) {
          setCategories(res.data.categories);
          localStorage.setItem(
            "categories",
            JSON.stringify(res.data.categories)
          );
        }
      } catch (err) {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et par minutter",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    };
    const _categories = localStorage.getItem("categories");

    if (_categories) {
      setCategories(JSON.parse(_categories));
    } else {
      fetchData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addCacheLifetime = (imageUrl, lifetimeInSeconds) => {
    const timestamp = new Date().getTime();
    const cacheLifetime = lifetimeInSeconds * 1000; // Convert seconds to milliseconds
    const urlWithCacheLifetime = `${imageUrl}?t=${timestamp}&c=${cacheLifetime}`;
    return urlWithCacheLifetime;
  };
  const lifeTime = 3600; // Set cache lifetime to 1 hour
  const frisorImg = addCacheLifetime(
    process.env.PUBLIC_URL + "/assets/categories/Frisor.webp",
    lifeTime
  );
  const skonhedImg = addCacheLifetime(
    process.env.PUBLIC_URL + "/assets/categories/Skonhed.webp",
    lifeTime
  );
  const massageImg = addCacheLifetime(
    process.env.PUBLIC_URL + "/assets/categories/vaxning.jpg",
    lifeTime
  );
  const negleplejeImg = addCacheLifetime(
    process.env.PUBLIC_URL + "/assets/categories/Neglepleje.webp",
    lifeTime
  );
  const fillersImg = addCacheLifetime(
    process.env.PUBLIC_URL + "/assets/categories/Fillers.webp",
    lifeTime
  );
  const ojnevipperImg = addCacheLifetime(
    process.env.PUBLIC_URL + "/assets/categories/Ojnevipper.webp",
    lifeTime
  );
  const piercingImg = addCacheLifetime(
    process.env.PUBLIC_URL + "/assets/categories/Piercing.webp",
    lifeTime
  );
  const pedicureImg = addCacheLifetime(
    process.env.PUBLIC_URL + "/assets/categories/Pedicure.webp",
    lifeTime
  );
  const makeupImg = addCacheLifetime(
    process.env.PUBLIC_URL + "/assets/categories/Makeup.webp",
    lifeTime
  );
  function handleClickSoon(event) {
    event.preventDefault();
  }
  return (
    <>
      <div className="Categories">
        <div
          className="Header"
          style={{ textAlign: "left", paddingLeft: "13%", width: "100%" }}
        >
          <h1>Book velvære</h1>
        </div>
        <div
          className="Subheader"
          style={{
            textAlign: "left",
            paddingLeft: "13%",
            paddingRight: "13%",
            width: "100%",
          }}
        >
          <p>
            Bestil tid hos frisøren, få lavet dine negle, øjenvipper, nyd en
            massage eller forkæl dine fødder med pedicure i en salon nær dig
          </p>
        </div>
        <div className="Cardscontainer">
          <Link
            className="Card"
            href={"/frisør"}
            style={{ textDecoration: "none" }}
          >
            <div>
              <Image className="Cardimage" src={frisorImg} alt="" />
              <div className="Cardtext">
                <span style={{ color: "#23a994" }}>
                  Frisør i hele Danmark ·
                </span>
                <br />
                <br />
                Vælg alt indenfor klipning, farvning og hårextensions. Bestil
                tid hos en frisør i hele Danmark!
              </div>
              <div className="title">Frisør</div>
            </div>
          </Link>
          <Link
            className="Card"
            href={"/skønhed"}
            style={{ textDecoration: "none" }}
          >
            <div>
              <Image className="Cardimage" src={skonhedImg} alt="" />
              <div className="Cardtext">
                <span style={{ color: "#23a994" }}>Skønhed hele Danmark ·</span>
                <br />
                <br />
                Vælg ansigtsbehandling, hårpleje, pedicure etc. Book
                skønhedsbehandling i hele Danmark!
              </div>
              <div className="title">Skønhed</div>
            </div>
          </Link>
          <Link
            className="Card"
            href={"/neglepleje"}
            style={{ textDecoration: "none" }}
          >
            <div>
              <Image className="Cardimage" src={negleplejeImg} alt="" />
              <div className="Cardtext">
                <span style={{ color: "#23a994" }}>
                  Negle over hele Danmark ·
                </span>
                <br />
                <br />
                Stilfuld til dine negle i hele Danmark. Vælg bl.a. manicure og
                behandling af negleproblemer.
              </div>
              <div className="title">Neglepleje</div>
            </div>
          </Link>
          <Link
            className="Card"
            href={"/massage"}
            style={{ textDecoration: "none" }}
            onClick={handleClickSoon}
          >
            <div>
              <div className="CardSoon">
                <Image className="CardimageSoon" src={massageImg} alt="" />
                <div class="card-text">kommer snart</div>
              </div>
              {/*<div className="Cardtext">
                <span style={{ color: "#23a994" }}>
                  Vaxning over hele Danmark ·
                </span>
                <br />
                <br />
                Bestil tid til voksbehandling i hele Danmark. Vælg mellem
                brazilian, benvoksning, armvoksning m.m.
              </div>*/}
              <div className="title">Vaxning</div>
            </div>
          </Link>
          <Link
            className="Card"
            href={"/fillers"}
            style={{ textDecoration: "none" }}
            onClick={handleClickSoon}
          >
            <div>
              <div className="CardSoon">
                <Image className="CardimageSoon" src={fillersImg} alt="" />
                <div class="card-text">kommer snart</div>
              </div>

              {/* <div className="Cardtext">
                <span style={{ color: "#23a994" }}>
                  Fillers over hele Danmark ·
                </span>
                <br />
                <br />
                Vælg mellem behandlinger som læbeforstørrelse og fillers i
                kæben. Book i hele Danmark!
              </div> */}
              <div className="title">Fillers</div>
            </div>
          </Link>
          <Link
            className="Card"
            href={"/øjenvipper"}
            style={{ textDecoration: "none" }}
            onClick={handleClickSoon}
          >
            <div>
              <div className="CardSoon">
                <Image className="CardimageSoon" src={ojnevipperImg} alt="" />
                <div class="card-text">kommer snart</div>
              </div>
              {/* <div className="Cardtext">
                <span style={{ color: "#23a994" }}>
                  Vipper over hele Danmark ·
                </span>
                <br />
                <br />
                Book vippebehandling i hele Danmark. Vælg eyelash extensions,
                eyelash toning m.m.
              </div> */}
              <div className="title">Øjenvipper</div>
            </div>
          </Link>
          <Link
            className="Card"
            href={"/piercing"}
            style={{ textDecoration: "none" }}
            onClick={handleClickSoon}
          >
            <div>
              <div className="CardSoon">
                <Image className="CardimageSoon" src={piercingImg} alt="" />
                <div class="card-text">kommer snart</div>
              </div>
              {/* <div className="Cardtext">
                <span style={{ color: "#23a994" }}>
                  Piercing over hele Danmark ·
                </span>
                <br />
                <br />
                Bestil alle former for piercinger, dermals, onepoint, og
                skalpelpiercinger m.m.
              </div> */}
              <div className="title">Piercing</div>
            </div>
          </Link>
          <Link
            className="Card"
            href={"/pedicure"}
            style={{ textDecoration: "none" }}
            onClick={handleClickSoon}
          >
            <div>
              <div className="CardSoon">
                <Image className="CardimageSoon" src={pedicureImg} alt="" />
                <div class="card-text">kommer snart</div>
              </div>
              {/* <div className="Cardtext">
                <span style={{ color: "#23a994" }}>
                  Neglepleje i hele Danmark ·
                </span>
                <br />
                <br />
                Bestil tid til neglepleje i hele Danmark. Vælg
                negleforlængelser, manicure, pleje af neglesvamp m.m.
              </div> */}
              <div className="title">Pedicure</div>
            </div>
          </Link>
          <Link
            className="Card"
            href={"/makeup"}
            style={{ textDecoration: "none" }}
            onClick={handleClickSoon}
          >
            <div>
              <div className="CardSoon">
                <Image className="CardimageSoon" src={makeupImg} alt="" />
                <div class="card-text">kommer snart</div>
              </div>
              {/* <div className="Cardtext">
                <span style={{ color: "#23a994" }}>
                  Makeup i hele Danmark ·
                </span>
                <br />
                <br />
                Vælg forskellige typer makeup i hele Danmark, med alt fra
                brudesminke til permanent makeup.
              </div> */}
              <div className="title">Makeup</div>
            </div>
          </Link>
          {/* <div className="Card" id="Allcategories" onClick={onOpen}>
            VIS ALLE KATEGORIER
          </div> */}
        </div>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader className="Modalheader">Alle Kategorier</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <div className="Modalcategories">
                <div className="Firstletter">A</div>
                {categories &&
                  categories.map((category, index) => (
                    <>
                      <Link
                        className="Categoryname"
                        style={{ textDecoration: "none" }}
                        href={`/${category.name
                          .toLowerCase()
                          .replace(/\s+/g, "-")}`}
                      >
                        <div>{category.name}</div>
                      </Link>
                      <Divider />
                      {index + 1 < categories.length ? (
                        <>
                          {category.name.charAt(0) !==
                          categories[index + 1].name.charAt(0) ? (
                            <>
                              <div className="Firstletter">
                                {categories[index + 1].name.charAt(0)}
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ))}
              </div>
            </ModalBody>

            <ModalFooter></ModalFooter>
          </ModalContent>
        </Modal>
      </div>
    </>
  );
}

export default MarketplaceCategories;
