import React from "react";
import "./Vilkar.css";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import MarketplaceNavbar from "../components/MarketplaceNavbar.tsx";
import MarketplaceFooter from "../components/MarketplaceFooter.tsx";
import { Box } from "@chakra-ui/react";

function Vilkar() {
  // roll page to top - On page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Bookinghero - Vores privatlivspolitik</title>
        <meta
          name="description"
          content="Læs Bookingheros privatlivspolitik for at forstå, hvordan vi beskytter dine personlige oplysninger."
        />
        <link rel="canonical" href="/vilkar" />
      </Helmet>
      <div className="Vilkar">
        <Box position="sticky" top={0} zIndex={3}>
          <MarketplaceNavbar />
        </Box>
        <div className="Content">
          <div className="Main_header">Vilkår og Betingelser</div>
          <div className="Subheader">Sidst opdateret den 19. Februar 2023</div>
          <div className="Header">1. Generelle regler</div>
          <div className="Text">
            Som udgangspunkt er det vigtigste for os, at vores brugere får en
            god oplevelse med hjemmesiden og appen. Når du tilmelder dig
            Bookinghero.io, accepterer du de vilkår og betingelser, som stilles.
            Hvis du ikke er enig med følgende, bør du straks ophøre med at bruge
            denne service.
          </div>
          <div className="Header">2. Virksomheden</div>
          <div className="Text">
            Bookinghero holder til i København. Mere information om virksomheden
            kan fås på www.cvr.dk. CVR- nummer: 44001136.
          </div>
          <div className="Header">3. Medlemskab</div>
          <div className="Text">
            Medlemskabet opnås ved at oprette profil på Bookinghero.io eller i
            appen. Ved oprettelse skal medlemmer angive korrekte oplysninger og
            er selv ansvarlige for at holde oplysningerne opdaterede.
            <br />
            <br />
            Det er kun tilladt at bruge egen e-mail-adresse. Alle indtastede
            informationer skal være korrekte, og det er ikke tilladt at give
            misvisende informationer. Du skal sørge for at holde dine
            login-oplysninger fortrolige, så uvedkommende ikke har adgang til
            din profil. Bookinghero forpligter sig til at holde dine
            informationer fortrolige i vores database. Al indtastet data er
            sikkert gemt hos Bookinghero og videregives ikke til tredjepart.
            <br />
            <br />
            Du kan til enhver tid opsige dit medlemskab hos Bookinghero. Du
            finder et link hertil inde i hjemmesiden og appen under
            “Indstillinger -&gt; Administrer din konto”. <br />
            <br />
            Vi forbeholder os retten til at suspendere et medlem, hvis
            vedkommende overtræder eller krænker vilkårene. Vi forbeholder os
            retten til efter eget skøn at opsige et medlemskab, hvis vi finder
            det nødvendigt.
          </div>
          <div className="Header">4. Rettigheder</div>
          <div className="Text">
            Alle rettigheder til Bookinghero ejes af Bookinghero, og medlemmer
            har udelukkende adgang til at benytte servicen. Bookinghero kan til
            enhver tid lukke adgangen til systemet uden varsel. Herudover afstår
            du fra, at (i) benytte hjemmesiden og appen på en sådan måde, som
            kan deaktivere, overbebyrde, beskadige eller svække hjemmesiden og
            appens eller forstyrre andres brug af hjemmesiden og appen, herunder
            andre brugeres mulighed for at tilgå hjemmesiden og appens indhold i
            realtid, (ii) at gøre brug af nogen søgerobot, ”spider” eller anden
            automatisk anordning, proces eller metode til at besøge hjemmesiden
            og appen uanset besøgets formål, herunder ”web scraping”, ”data
            mining”, overvågning eller kopiering af nogen del af hjemmesiden og
            appens indhold, (iii) at gøre brug af nogen manuel proces til at
            overvåge eller kopiere nogen del af hjemmesiden og appens indhold
            mv. uden forudgående skriftlige samtykke med Bookinghero ApS, (iv) i
            øvrigt gøre brug af nogen anordning, software eller fremgangsmåde,
            som forstyrrer hjemmesiden og appens normale drift, eller (v) på
            anden måde forstyrre hjemmesiden og appens normale drift.
          </div>
          <div className="Header">5. Online betalinger</div>
          <div className="Text">
            Online betalinger foregår teknisk i hjemmesiden og appen Bookinghero
            og varetages af{" "}
            <a
              href="https://pensopay.com/"
              style={{ color: "blue" }}
              target="_blank"
              rel="noreferrer"
            >
              PensoPay
            </a>{" "}
            A/S som betaling udbyder.
          </div>
          <div className="Header">6. Ansvarsfraskrivelse</div>
          <div className="Text">
            Hjemmesiden og appen vedligeholdes løbende, og nye funktioner
            udvikles ligeledes over tid. Derfor tager Bookinghero forbehold for
            fejl i hjemmesiden og appen og ændringer, der kan forårsage fejl,
            nedbrud og andre uhensigtsmæssigheder.
            <br />
            <br />
            Bookinghero kan på ingen måder stilles til ansvar for fejl i
            hjemmesiden og appen, ændringer, tabt arbejdsfortjeneste eller
            omsætning hos behandlere eller de i hjemmesiden og appen, listede
            virksomheder.
          </div>
          <div className="Header">7. Sikkerhed</div>
          <div className="Text">
            Bookinghero bestræber sig på at opretholde en høj grad af sikkerhed,
            herunder relevant SSL-kryptering (https) ved tilgang til systemet.
            Bookinghero kan dog ikke garantere mod hacker-angreb, som kan
            forårsage systemnedbrud og/eller tab af data. Tab forårsaget af
            hackerangreb vil derfor ikke blive dækket af Bookinghero. Kunden er
            selv ansvarlig for sikkerhed på de anvendte enheder og på kundens
            egne systemer. Bookinghero foretager løbende backup, der opbevares i
            tre måneder.
          </div>
          <div className="Header">8. Cookies</div>
          <div className="Text">
            Der anvendes cookies i hjemmesiden og appen.
          </div>
          <div className="Header">9. Kontakt</div>
          <div className="Text">
            For yderligere information kontakt Bookinghero, under support på +
            45 93 600 925 eller på support@bookinghero.io
          </div>
        </div>
        <Box>
          <MarketplaceFooter />
        </Box>
      </div>
    </>
  );
}

export default Vilkar;
