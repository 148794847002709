import {
  Box,
  Button,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  PinInput,
  PinInputField,
  Text,
  useColorModeValue,
  ModalCloseButton,
  useDisclosure,
  useToast,
  Input,
  Checkbox,
} from "@chakra-ui/react";

import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { useContext } from "react";
import { BsStar, BsStarFill, BsStarHalf } from "react-icons/bs";
import { Clientauthcontext } from "./context/Clientauthcontext";
import { IoMdArrowBack } from "react-icons/io";
function BookingPageOverviewResponsive({
  appointment,
  setAppointment,
  disable,
  setDisable,
}) {
  const [isAuth, setIsAuth] = useState();
  const [email, setEmail] = useState("");
  const [password, setpassword] = useState("");
  const [repeat_password, setRepeat_password] = useState("");
  const [name, setname] = useState("");
  const [clientid, setClientid] = useState(-1);
  const [showPass, setShowPass] = useState(false);
  const [isLoadingSignup, setIsLoadingSignup] = useState(false);
  // is authentificated
  const verifyCookie = async () => {
    try {
      if (email !== "") {
        const res = await axios.post("direct_salon/web/check-auth", {
          email: email,
        });
        if (res.status === 202) {
          signupModal.onOpen();
          setIsAuth(false);
        } else {
          if (res.status === 201) {
            signinModal.onOpen();
            setIsAuth(false);
          } else {
            setIsAuth(true);
          }
        }
      } else {
        const res = await axios.post("direct_salon/web/check-auth");
        if (res.status !== 200) {
          emailModal.onOpen();
          setIsAuth(false);
        } else {
          setIsAuth(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const { loginclient } = useContext(Clientauthcontext);
  const { registerclient } = useContext(Clientauthcontext);
  const { confirmclient } = useContext(Clientauthcontext);
  const ColorModeValue = useColorModeValue("gray.700", "gray.800");
  const signinModal = useDisclosure();
  const signupModal = useDisclosure();
  const emailModal = useDisclosure();
  const otpModal = useDisclosure();
  const handle_signin = async () => {
    try {
      const inputs = {
        client_email: email,
        client_password: password,
      };
      const res = await loginclient(inputs);
      if (res.status === 200) {
        setAppointment((prevState) => ({
          ...prevState,
          step: 4,
        }));
      }
    } catch (err) {
      if (err.response.status === 404) {
        toast({
          title: "Login fejl",
          description: err.response.data.message,
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      } else if (err.response.status === 500) {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et øjeblik",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    }
  };

  const handle_signup = async (e) => {
    try {
      if (
        email === "" ||
        password === "" ||
        repeat_password === "" ||
        name === ""
      ) {
        toast({
          title: "Skriv fejl",
          description: "E-mail- eller adgangskodefeltet er tomt",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      } else if (password !== repeat_password) {
        toast({
          title: "Skriv fejl",
          description: "bekræftet adgangskode forkert",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      } else {
        const Data = {
          client_name: name,
          client_email: email,
          client_password: password,
        };
        const res = await registerclient(Data);

        if (res.status === 200) {
          signupModal.onClose();
          setIsLoadingSignup(false);
          otpModal.onOpen();
          setClientid(res.data.client_user_id);
        }
      }
    } catch (err) {
      if (err.response.status === 404) {
        toast({
          title: "Login fejl",
          description: err.response.data.message,
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      } else if (err.response.status === 500) {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et øjeblik",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    }
  };
  const [input, setInput] = useState(0);

  const handle_confirm = async (e) => {
    const data = {
      secret_pin: parseInt(input, 10),
      client_id: parseInt(clientid),
    };
    try {
      const res = await confirmclient(data);

      if (res.status === 200) {
        setAppointment((prevState) => ({
          ...prevState,
          step: 4,
        }));
      }
    } catch (err) {
      if (err.response.status === 404) {
        toast({
          title: "Confirm fejl",
          description: err.response.data.message,
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      } else if (err.response.status === 500) {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et øjeblik",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    }
  };

  const handleNextStep = () => {
    if (appointment.step == 3) {
      verifyCookie();
      if (!isAuth) return;
    }
    setAppointment((prevState) => ({
      ...prevState,
      step: prevState.step + 1 < 4 ? prevState.step + 1 : 4,
    }));
  };
  const monthMap = {
    January: "01",
    February: "02",
    March: "03",
    April: "04",
    May: "05",
    June: "06",
    July: "07",
    August: "08",
    September: "09",
    October: "10",
    November: "11",
    December: "12",
  };
  const notConnectedModal = useDisclosure(); //Displayed modal when the user want to confirm but he is signed out
  const [SalonInfos, setSalonInfos] = useState();
  const buttonText = appointment.step === 4 ? "Bekræfte" : "Fortsætte";
  const [buttonLoading, setButtonLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  //Get Salon Infos to display on the overview card
  useEffect(() => {
    const fatchData = async () => {
      const storedAppointment = localStorage.getItem("appointment");
      if (storedAppointment) {
        setAppointment(JSON.parse(storedAppointment));
        localStorage.removeItem("appointment");
        return;
      }
      try {
        setIsLoading(true);
        const response = await axios.post(
          "direct_salon/web/booking-information",
          {
            salon_slug: appointment.SalonSlug,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setSalonInfos(response.data.salon_information);
        // Handle different status codes
        switch (response.status) {
          case 500:
            toast({
              title: "Server Fejl",
              description: "Prøv venligst igen om et øjeblik",
              position: "top-right",
              isClosable: true,
              status: "error",
            });
            navigate("/");
            break;
          case 404:
            toast({
              title: "Session over",
              description: "Prøv venligst igen om et øjeblik",
              position: "top-right",
              isClosable: true,
              status: "error",
            });
            navigate("/");
            break;
          case 405:
            toast({
              title: "Salon account is inactive",
              description: "Prøv venligst igen om et øjeblik",
              position: "top-right",
              isClosable: true,
              status: "error",
            });
            navigate("/");
            break;
          case 406:
            toast({
              title: "Salon account not connected",
              description: "Prøv venligst igen om et øjeblik",
              position: "top-right",
              isClosable: true,
              status: "error",
            });
            navigate("/");
            break;
          case 407:
            toast({
              title: "Salon profile incomplete",
              description: "Prøv venligst igen om et øjeblik",
              position: "top-right",
              isClosable: true,
              status: "error",
            });
            navigate("/");
            break;
          default:
            break;
        }
        setIsLoading(false);
      } catch (error) {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et øjeblik",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
        navigate("/");
      }
    };
    fatchData();
  }, []);

  // Make an appointment
  const makeAppointment = async () => {
    const url = "direct_salon/web/make-appointment";

    const data = {
      salon_slug: appointment.SalonSlug,
      client_name: appointment.client.Name,
      client_phone: appointment.client.Phone,
      client_email: appointment.client.Email,
      client_address: appointment.client.Address,
      treatment_id: appointment.treatment.ID.toString(),
      treatment_duration: appointment.treatment.Duration,
      treatment_price: appointment.treatment.Price.toString(),
      treatment_name: appointment.treatment.Name,
      employee_id: appointment.employee.ID,
      employee_name: appointment.employee.Name,
      date_time: `${appointment.schedule.year}-${
        monthMap[appointment.schedule.month]
      }-${appointment.schedule.day}T${appointment.schedule.startTime}:00`,
      comment: appointment.client.Note,
    };

    try {
      const response = await axios.post(url, data);
      toast({
        title: "Appointment registred",
        description: "Your appointment has being made succesfully",
        position: "top-right",
        isClosable: true,
        status: "success",
      });
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log("Response data:", error.response.data);
        console.log("Status code:", error.response.status);

        if (error.response.status === 500) {
          toast({
            title: "Server Fejl",
            description: "Prøv venligst igen om et øjeblik",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        } else if (error.response.status === 404) {
          //display not connected modal
          notConnectedModal.onOpen();
          localStorage.setItem("appointment", JSON.stringify(appointment));
          // localStorage.setItem("client",JSON.stringify({}))
        } else if (error.response.status === 405) {
          toast({
            title: "Inactive Salon",
            description: "Prøv venligst igen om et øjeblik",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        } else if (error.response.status === 406) {
          toast({
            title: "Salon not connected",
            description: "Prøv venligst igen om et øjeblik",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        } else if (error.response.status === 407) {
          toast({
            title: "Incomplete Salon infos",
            description: "Prøv venligst igen om et øjeblik",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        }
      } else {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et øjeblik",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    }
  };
  return (
    <>
      <Flex
        position="fixed"
        bottom={"0"}
        left={"0"}
        width={"100%"}
        justifyContent={"space-between"}
        height={"fit-content"}
        padding={"15px"}
        background={"white"}
        alignItems={"center"}
        boxShadow="rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em"
        zIndex={"5"}
      >
        <Flex direction={"column"}>
          <Flex fontWeight={"500"} fontSize={"md"}>
            {appointment.treatment.ID !== -1 && (
              <Flex
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignContent={"center"}
              >
                <Flex fontSize={"sm"}>
                  <Text fontWeight={"600"} mx={"8px"}>
                    {appointment.treatment.Name}
                  </Text>
                  <Text color={"gray"} mx={"8px"}>
                    {appointment.treatment.DurationFrom ? "from" : ""}{" "}
                    {appointment.treatment.Duration} min
                  </Text>
                  <Text color={"gray"} mx={"8px"}>
                    {appointment.treatment.PriceFrom ? "from" : ""}{" "}
                    {appointment.treatment.Price} DKK
                  </Text>
                </Flex>
              </Flex>
            )}
          </Flex>
          <Text fontWeight={"600"} fontSize={"xl"}>
            {appointment.employee.ID !== -1 && (
              <Flex justifyContent={"space-between"} alignItems={"center"}>
                <Flex gap={"15px"} alignItems={"center"}>
                  <Flex fontSize={"sm"} fontWeight={"600"} mx={"8px"}>
                    Med{" "}
                    <Text color="gray" ml={"6px"}>
                      {appointment.employee.Name}
                    </Text>
                  </Flex>
                  <Box>
                    {appointment.schedule.startTime !== "" && (
                      <Box width={"100%"} height={"fit-content"}>
                        <Flex
                          alignItems={"center"}
                          fontSize={"sm"}
                          fontWeight={"600"}
                        >
                          <Text color="gray" mr={"6px"}>
                            {appointment.schedule.day}{" "}
                            {appointment.schedule.month}{" "}
                            {appointment.schedule.year}
                          </Text>{" "}
                          Tor{" "}
                          <Text color="gray" ml={"6px"}>
                            {" "}
                            {appointment.schedule.startTime}
                          </Text>
                        </Flex>
                      </Box>
                    )}
                  </Box>
                </Flex>
              </Flex>
            )}
          </Text>
        </Flex>

        <>
          <Button
            size={"lg"}
            background={"#1a202c"}
            color={"white"}
            _hover={{ bg: "#232c3d" }}
            onClick={() => {
              setButtonLoading(true);
              handleNextStep();
              setDisable(false);
              if (appointment.step === 4) {
                makeAppointment();
                navigate("/");
              }
              setButtonLoading(false);
            }}
            isDisabled={disable}
            isLoading={buttonLoading}
          >
            {buttonText}
          </Button>
        </>

        {/* Give email */}
        <Modal
          isCentered
          onClose={emailModal.onClose}
          isOpen={emailModal.isOpen}
          motionPreset="slideInBottom"
          size={"lg"}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader marginTop={"24px"}>
              <Text fontSize={"2xl"} mb={"5px"}>
                Log ind eller tilmeld dig
              </Text>
              <Text fontSize={"sm"} fontWeight={"normal"}>
                Log ind eller tilmeld dig for at fuldføre din reservation
              </Text>
            </ModalHeader>

            <ModalCloseButton />
            <ModalBody>
              <Box width={"100%"} height={"fit-content"} paddingTop={"24px"}>
                <Input
                  size={"lg"}
                  type="mail"
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="E-mail adresse"
                />
              </Box>
            </ModalBody>
            <ModalFooter>
              <Button
                backgroundColor={useColorModeValue("gray.800", "gray.900")}
                color={"white"}
                width={"100%"}
                height={"45px"}
                marginTop={"16px"}
                _hover={{
                  backgroundColor: useColorModeValue("gray.700", "gray.800"),
                }}
                mb={"10px"}
                onClick={() => {
                  emailModal.onClose();
                  verifyCookie();
                }}
              >
                Blive ved
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        {/* signin-password  */}
        <Modal
          isCentered
          onClose={signinModal.onClose}
          isOpen={signinModal.isOpen}
          motionPreset="slideInBottom"
          size={"lg"}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <Box
                cursor={"pointer"}
                fontSize={"xl"}
                onClick={() => {
                  signinModal.onClose();
                  emailModal.onOpen();
                }}
              >
                <IoMdArrowBack />
              </Box>
              <ModalCloseButton />
              <Text fontSize={"2xl"} marginTop={"24px"} mb={"5px"}>
                Velkommen tilbage
              </Text>
              <Text fontSize={"sm"} fontWeight={"normal"}>
                Indtast din adgangskode for at logge ind {email}
              </Text>
            </ModalHeader>
            <ModalBody>
              <Box
                width={"100%"}
                height={"fit-content"}
                position={"relative"}
                paddingTop={"24px"}
              >
                <Input
                  size={"lg"}
                  type={showPass ? "text" : "password"}
                  onChange={(e) => setpassword(e.target.value)}
                  placeholder="Password"
                />
                <Box
                  position={"absolute"}
                  zIndex={40}
                  right={"8px"}
                  top={"36px"}
                  fontSize={"2xl"}
                  onClick={() => setShowPass(!showPass)}
                  cursor={"pointer"}
                >
                  {showPass ? <IoEyeOutline /> : <IoEyeOffOutline />}
                </Box>
              </Box>
            </ModalBody>
            <ModalFooter>
              <Button
                backgroundColor={useColorModeValue("gray.800", "gray.900")}
                color={"white"}
                width={"100%"}
                height={"45px"}
                marginTop={"16px"}
                _hover={{
                  backgroundColor: useColorModeValue("gray.700", "gray.800"),
                }}
                mb={"10px"}
                onClick={() => {
                  signinModal.onClose();
                  handle_signin();
                }}
              >
                Log på
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        {/* signup */}
        <Modal
          isCentered
          onClose={signupModal.onClose}
          isOpen={signupModal.isOpen}
          motionPreset="slideInBottom"
          size={"xl"}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <Box
                cursor={"pointer"}
                fontSize={"xl"}
                onClick={() => {
                  signupModal.onClose();
                  emailModal.onOpen();
                }}
              >
                <IoMdArrowBack />
              </Box>

              <Text fontSize={"2xl"} marginTop={"24px"} mb={"5px"}>
                Opret konto
              </Text>
              <Text fontSize={"sm"} fontWeight={"normal"}>
                Opret din nye konto til {email} ved at udfylde disse oplysninger
              </Text>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box width={"100%"} height={"fit-content"}>
                <Box marginBottom={"20px"}>
                  <Text>Fulde navn *</Text>
                  <Input
                    type="text"
                    onChange={(e) => setname(e.target.value)}
                    size={"lg"}
                  />
                </Box>
                <Box marginBlock={"20px"}>
                  <Text>Adgangskode *</Text>
                  <Input
                    type="password"
                    onChange={(e) => setpassword(e.target.value)}
                    size={"lg"}
                  />
                </Box>
                <Box marginBlock={"20px"}>
                  <Text>Gentag adgangskode *</Text>
                  <Input
                    type="password"
                    onChange={(e) => setRepeat_password(e.target.value)}
                    size={"lg"}
                  />
                </Box>
                <Flex marginBlock={"20px"}>
                  <Checkbox
                    colorScheme="green"
                    marginRight={"8px"}
                    size={"lg"}
                  />{" "}
                  <Text>
                    Jeg accepterer privatlivspolitikken, vilkårene for brug og
                    servicevilkårene
                  </Text>
                </Flex>
                <Flex marginBlock={"20px"}>
                  <Checkbox
                    colorScheme="green"
                    marginRight={"8px"}
                    size={"lg"}
                  />{" "}
                  <Text>
                    Jeg accepterer at modtage markedsføringsmeddelelser med
                    tilbud og nyheder
                  </Text>
                </Flex>
              </Box>
            </ModalBody>
            <ModalFooter>
              <Button
                backgroundColor={useColorModeValue("gray.800", "gray.900")}
                color={"white"}
                width={"100%"}
                height={"45px"}
                _hover={{
                  backgroundColor: useColorModeValue("gray.700", "gray.800"),
                }}
                isLoading={isLoadingSignup}
                loadingText="please wait"
                colorScheme="teal"
                variant="outline"
                spinnerPlacement="end"
                mb={"10px"}
                onClick={() => {
                  setIsLoadingSignup(true);
                  handle_signup();
                }}
              >
                Blive ved
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        {/* pin */}
        <Modal
          isCentered
          onClose={otpModal.onClose}
          isOpen={otpModal.isOpen}
          motionPreset="slideInBottom"
          size={"xl"}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader marginTop={"40px"}>
              <Text fontSize={"2xl"} marginTop={"24px"} mb={"5px"}>
                Venlist tjekke din email for at bekræfte din konto
              </Text>
              <Text fontSize={"sm"} fontWeight={"normal"}>
                Indtast venligst din hemmelige pinkode
              </Text>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <HStack className="Pininput" marginTop="24px">
                <PinInput
                  onChange={(value) => {
                    setInput(value);
                  }}
                >
                  <PinInputField
                    className="pin"
                    h={"80px"}
                    w={"80px"}
                    marginInline={"10px"}
                    fontSize={"2xl"}
                    fontWeight={"bold"}
                  />
                  <PinInputField
                    className="pin"
                    h={"80px"}
                    w={"80px"}
                    marginInline={"10px"}
                    fontSize={"2xl"}
                    fontWeight={"bold"}
                  />
                  <PinInputField
                    className="pin"
                    h={"80px"}
                    w={"80px"}
                    marginInline={"10px"}
                    fontSize={"2xl"}
                    fontWeight={"bold"}
                  />
                  <PinInputField
                    className="pin"
                    h={"80px"}
                    w={"80px"}
                    marginInline={"10px"}
                    fontSize={"2xl"}
                    fontWeight={"bold"}
                  />
                </PinInput>
              </HStack>
            </ModalBody>
            <ModalFooter>
              <Button
                backgroundColor={useColorModeValue("gray.800", "gray.900")}
                color={"white"}
                width={"100%"}
                height={"45px"}
                marginTop={"16px"}
                _hover={{
                  backgroundColor: useColorModeValue("gray.700", "gray.800"),
                }}
                mb={"10px"}
                onClick={() => {
                  otpModal.onClose();
                  handle_confirm();
                }}
              >
                Indsend
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>
    </>
  );
}

export default BookingPageOverviewResponsive;
