import { Box, Grid, GridItem, useMediaQuery, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import BookingPageServiceSelect from "./BookingPageServiceSelect";
import BookingPageOverview from "./BookingPageOverview";
import BookingPageStaff from "./BookingPageStaff";
import BookingPageOverviewReponsive from "./BookingPageHeaderResponsive";
import BookingPageTime from "./BookingPageTime";
import BookingPageConfirm from "./BookingPageConfirm";

function BookingPageServices({
  appointment,
  setAppointment,
  disable,
  setDisable,
}) {
  const [isSmallerThan1330] = useMediaQuery("(max-width: 1330px)");

  const step = appointment.step;
  let stepComponent;

  switch (step) {
    case 1:
      stepComponent = (
        <BookingPageServiceSelect
          appointment={appointment}
          setAppointment={setAppointment}
        />
      );
      break;
    case 2:
      stepComponent = (
        <BookingPageStaff
          appointment={appointment}
          setAppointment={setAppointment}
        />
      );
      break;
    case 3:
      stepComponent = (
        <BookingPageTime
          appointment={appointment}
          setAppointment={setAppointment}
        />
      );
      break;
    case 4:
      stepComponent = (
        <BookingPageConfirm
          appointment={appointment}
          setAppointment={setAppointment}
        />
      );
      break;
    default:
      stepComponent = (
        <BookingPageServiceSelect
          appointment={appointment}
          setAppointment={setAppointment}
        />
      );
  }

  // Disable or Enable the Continue/Confirm button based on the user inputs completed/not completed
  useEffect(() => {
    if (appointment.step === 1) {
      if (appointment.treatment.ID !== -1) setDisable(false);
      else setDisable(true);
    }
    if (appointment.step === 2) {
      if (appointment.employee.ID !== -1) setDisable(false);
      else setDisable(true);
    }
    if (appointment.step === 3) {
      if (appointment.schedule.endTime !== "") setDisable(false);
      else setDisable(true);
    }
    if (appointment.step === 4) {
      if (appointment.client.Complete === true) setDisable(false);
      else setDisable(true);
    }
  }, [appointment]);

  return (
    <>
      <Box
        width={"100%"}
        height={"fit-content"}
        paddingX={"150px"}
        paddingY={"20px"}
      >
        <Grid
          templateColumns={{
            base: "1fr",
            md: "1fr",
            lg: isSmallerThan1330 ? "1fr" : "6fr 4fr",
          }}
          gap={0}
        >
          <GridItem>{stepComponent}</GridItem>
          <GridItem display={isSmallerThan1330 ? "none" : "block"}>
            <BookingPageOverview
              appointment={appointment}
              setAppointment={setAppointment}
              disable={disable}
              setDisable={setDisable}
            />
          </GridItem>
          <GridItem display={isSmallerThan1330 ? "block" : "none"}>
            <BookingPageOverviewReponsive
              appointment={appointment}
              setAppointment={setAppointment}
              disable={disable}
              setDisable={setDisable}
            />
          </GridItem>
        </Grid>
      </Box>
    </>
  );
}

export default BookingPageServices;
