import React from "react";
import { Box, Heading, Text, Button } from "@chakra-ui/react";
import MarketplaceNavbar from "../components/MarketplaceNavbar.tsx";
import { Link } from "react-router-dom";

function NotFound() {
  return (
    <>
      <Box position="sticky" top={0} zIndex={3}>
        <MarketplaceNavbar />
      </Box>
      <Box textAlign="center" py={10} px={6} mt={40}>
        <Heading
          display="inline-block"
          as="h1"
          size="4xl"
          bgGradient="linear(to-r, teal.400, teal.600)"
          backgroundClip="text"
        >
          404
        </Heading>
        <Text fontSize="50px" mt={3} mb={2}>
          Side Ikke Fundet
        </Text>
        <Text fontSize="25px" color={"gray.500"} mb={6}>
          Den side, du leder efter, ser ikke ud til at eksistere
        </Text>
        <Link to="/">
          <Button
            colorScheme="teal"
            bgGradient="linear(to-r, teal.400, teal.500, teal.600)"
            color="white"
            variant="solid"
          >
            Gå til Hjem
          </Button>
        </Link>
      </Box>
    </>
  );
}

export default NotFound;
