import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import "react-pagination-js/dist/styles.css"; // import css
import MarketplaceNavbar from "../components/MarketplaceNavbar.tsx";
import MarketplaceFooter from "../components/MarketplaceFooter.tsx";
import { Box, Flex, Icon, Image, Link, Stack, Text } from "@chakra-ui/react";
import Fade from "react-reveal/Fade";
import { extendTheme } from "@chakra-ui/react";
import { FaGlobe, FaItunes, FaLinkedin, FaYoutube } from "react-icons/fa";

function AboutUs() {
  useEffect(() => {
    window.scrollTo(0, 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const breakpoints = {
    sm: "30em", // 480px
    md: "48em", // 768px
    lg: "62em", // 992px
    xl: "78em", // 1280px
    "2xl": "94em", // 1360px
  };

  // 3. Extend the theme
  const theme = extendTheme({ breakpoints });
  const [isHovered, setIsHovered] = useState();

  return (
    <>
      <Helmet>
        <title>Bookinghero – Udforsk Vores Historie og Mission</title>
        <meta
          name="description"
          content="Opdag historien bag Bookinghero, din førende markedsplads til booking af skønheds- og wellnesssaloner i Danmark. Lær om vores engagement i at forenkle din selvplejerutine ved at forbinde dig med de fineste saloner. Bliv en del af vores revolution af måden, du forkæler dig selv på."
        />
        <link rel="canonical" href="/om-os" />
      </Helmet>
      <div className="clientSupport">
        <Box position="sticky" top={0} zIndex={3}>
          <MarketplaceNavbar />
        </Box>

        <Box
          paddingLeft={{ base: "1%", md: "5%", xl: "2%", "2xl": "10%" }}
          paddingRight={{ base: "1%", md: "5%", xl: "2%", "2xl": "10%" }}
          paddingBottom={{ base: "1%", md: "5%", xl: "2%", "2xl": "10%" }}
          paddingTop={{ base: "1%", md: "2%", xl: "1%", "2xl": "3%" }}
          height={"100%"}
          width={"100%"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
        >
          <Flex
            flexDirection={{ base: "column", md: "row" }}
            pr={{ base: "1%", md: "5%" }}
            pl={{ base: "1%", md: "5%" }}
            marginBottom={0}
          >
            <Box w={{ base: "100%", md: "60%" }} zIndex={2}>
              <Text
                fontSize={{ base: "1.6rem", md: "3rem" }}
                w={{ base: "98%", md: "70vw" }}
                marginBottom={{ md: "4rem", "2xl": "2rem" }}
                textAlign={"justify"}
              >
                Din genvej til velvære.
              </Text>
              <Box
                position="relative"
                overflow={"hidden"}
                w={{ base: "99%", md: "95%" }}
                h={{ base: "250px", md: "400px" }}
                borderRadius={"20px"}
              >
                <Image
                  objectFit={"cover"}
                  w={"100%"}
                  h={"100%"}
                  filter="grayscale(80%)"
                  src="https://circaworks.com/wp-content/uploads/2020/12/what-makes-a-good-team.jpg"
                />
              </Box>{" "}
            </Box>

            <Box
              w={{ base: "100%", md: "35%", lg: "40%" }}
              alignSelf={"flex-end"}
              paddingTop={{ base: "40px", md: "140px", "2xl": "100px" }}
              marginBottom={"240px"}
            >
              <Text
                fontSize={"1.4rem"}
                marginBottom={"3rem"}
                marginTop={{ base: "3rem", md: 0 }}
                textAlign={"justify"}
              >
                Bookinghero er skabt for at forenkle din vej til selvpleje og
                øget velvære!
              </Text>
              <Text textAlign={"left"}>
                Vores kerneoverbevisning er, at når vi værner om os selv og
                investerer i vores velvære og trivsel, har vi det bedre i os
                selv og i samværet med andre. Derfor samarbejder vi med
                virksomheder inden for skønhed, sundhed, terapi og wellness med
                missionen om at bringe dem ét skridt nærmere deres klienter.
                Genvejen er vores app, hvor virksomheder opretter deres tilbud,
                og klienter nemt og hurtigt finder netop det tilbud, der passer
                til deres behov.
                <br />
                Vi samarbejder med førende frisører, massører, kosmetologer,
                neglesaloner, terapeuter og mange andre inden for behandling og
                velvære. Bliv en del af vores markedsplads allerede i dag ved at
                kontakte os på telefon +45 93 600 925 eller mail
                support@bookinghero.io. Vi ved alle, at det hektiske tempo i
                dagligdagen kan gøre selvpleje til en udfordring – men
                Bookinghero gør nemt for dig. Øg dit velvære ved at downloade
                vores gratis app.
                <br />
                Vi har allerede flere end 700 virksomheders tilbud at søge
                iblandt, og der kommer flere til dagligt. Tip gerne de steder,
                du kommer, så vi kan få alle med.
              </Text>
            </Box>
          </Flex>

          <Flex
            justifyContent={"center"}
            alignItems={"center"}
            marginTop={{ base: "3rem", md: 0 }}
            position={"relative"}
            top={{ base: 0, md: "-60px" }}
            flexDirection={{ base: "column-reverse", md: "row" }}
          >
            <Text
              textAlign={{ base: "center", md: "right" }}
              marginRight={{ base: "auto", md: 14 }}
              w={{ base: "100%", md: "34%" }}
            >
              <Fade left duration={1500}>
                <Text fontSize={"5rem"} fontWeight={"500"} marginBottom={0}>
                  700+
                </Text>
                <Text marginBottom={"2rem"}>virksomheder</Text>
                <Text>
                  Bookinghero har omkring 700 virksomheder på vores markedsplads
                  i Danmark, og der kommer hele tiden nye til.
                </Text>
              </Fade>
            </Text>
            <Box
              position="relative"
              overflow={"hidden"}
              w={{ base: "98%", md: "500px" }}
              h={{ base: "300px", md: "400px" }}
              borderRadius={"25px"}
            >
              <Image
                objectFit={"cover"}
                w={"100%"}
                filter="grayscale(80%)"
                h={"100%"}
                src={process.env.PUBLIC_URL + "/assets/about2.jpg"}
              />
            </Box>
          </Flex>

          <Flex
            justifyContent={"center"}
            alignItems={"center"}
            marginTop={20}
            flexDirection={{ base: "column", md: "row" }}
          >
            <Box
              position="relative"
              overflow={"hidden"}
              w={{ base: "98%", md: "500px" }}
              h={{ base: "300px", md: "400px" }}
              borderRadius={"25px"}
            >
              <Image
                objectFit={"cover"}
                w={"100%"}
                h={"100%"}
                filter="grayscale(80%)"
                src="https://images.pexels.com/photos/11118765/pexels-photo-11118765.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
              />
            </Box>
            <Text
              textAlign={{ base: "center", md: "left" }}
              marginLeft={{ base: "auto", md: 14 }}
              w={{ base: "100%", md: "34%" }}
            >
              <Fade right duration={1500}>
                <Text fontSize={"5rem"} fontWeight={"500"} marginBottom={0}>
                  20
                </Text>
                <Text marginBottom={"2rem"}>års erfaring</Text>
                <Text>
                  Vi har allieret os med de dygtigste i branchen, og tilsammen
                  har vi over 20 års erfaring inden for et bredt spektrum af
                  selvpleje og velvære.
                </Text>
              </Fade>
            </Text>
          </Flex>

          <Flex
            justifyContent={"center"}
            alignItems={"center"}
            marginTop={{ base: 20, md: 36 }}
            flexDirection={{ base: "column-reverse", md: "row" }}
          >
            <Text
              textAlign={{ base: "center", md: "right" }}
              marginRight={{ base: "auto", md: 14 }}
              w={{ base: "100%", md: "34%" }}
            >
              <Fade left duration={1500}>
                <Text fontSize={"5rem"} fontWeight={"500"} marginBottom={0}>
                  1000+
                </Text>
                <Text marginBottom={"2rem"}>downloads af vores app</Text>
                <Text>
                  Vores app blev lanceret august 2023 og vokser dagligt med
                  20-30 nye brugere, der med blot få klik kan finde tilbud til
                  øget velvære.
                </Text>
              </Fade>
            </Text>
            <Box
              position="relative"
              overflow={"hidden"}
              w={{ base: "98%", md: "500px" }}
              h={{ base: "380px", md: "480px" }}
              borderRadius={"25px"}
            >
              <Image
                objectFit={"cover"}
                w={"100%"}
                h={"100%"}
                filter="grayscale(80%)"
                src={process.env.PUBLIC_URL + "/assets/about3.JPG"}
              />
            </Box>
          </Flex>

          <Box>
            <Text
              w={"fit-content"}
              margin={"auto"}
              fontSize={"3rem"}
              fontWeight={"500"}
              marginTop={"8rem"}
            >
              Holdet.
            </Text>
            <Flex
              flexWrap={"wrap"}
              w={{ base: "fit-content", xl: "1260px" }}
              justifyContent={"center"}
              margin={"auto"}
            >
              <Box
                margin={{ base: "1.5rem", "2xl": "2.2rem" }}
                w={"15rem"}
                alignItems={"center"}
                as={"a"}
                href={"https://www.linkedin.com/in/rafikbouanik/"}
                target={"blank"}
              >
                <Box
                  position="relative"
                  overflow={"hidden"}
                  w={{ base: 56, lg: 56 }}
                  h={{ base: 56, lg: 56 }}
                  borderRadius={"18px"}
                  marginBottom={"14px"}
                >
                  <Image
                    objectFit={"cover"}
                    w={"100%"}
                    h={"100%"}
                    filter="grayscale(80%)"
                    src="https://res.cloudinary.com/dlhjutjbp/image/upload/v1696432708/1670801361960_mhpzpd.jpg"
                  />
                </Box>
                <Text>Rafik Bouanik</Text>
                <Text fontSize={"13px"}>Founder</Text>
              </Box>

              <Box
                margin={{ base: "1.5rem", "2xl": "2.2rem" }}
                w={"15rem"}
                alignItems={"center"}
                as={"a"}
                href={"https://www.linkedin.com/in/sebastianmathilli/"}
                target={"blank"}
              >
                <Box
                  position="relative"
                  overflow={"hidden"}
                  w={{ base: 56, lg: 56 }}
                  h={{ base: 56, lg: 56 }}
                  borderRadius={"18px"}
                  marginBottom={"14px"}
                >
                  <Image
                    objectFit={"cover"}
                    w={"100%"}
                    h={"100%"}
                    filter="grayscale(80%)"
                    src="https://res.cloudinary.com/da3vey0ch/image/upload/v1715608553/sebastian_cty1ea.jpg"
                  />
                </Box>
                <Text>Sebastian Mathilli</Text>
                <Text fontSize={"13px"}>Co-founder & CCO</Text>
              </Box>

              <Box
                margin={{ base: "1.5rem", "2xl": "2.2rem" }}
                w={"15rem"}
                alignItems={"center"}
                as={"a"}
                href={"https://www.linkedin.com/in/imed-eddine-kabouya/"}
                target={"blank"}
              >
                <Box
                  position="relative"
                  overflow={"hidden"}
                  w={{ base: 56, lg: 56 }}
                  h={{ base: 56, lg: 56 }}
                  borderRadius={"18px"}
                  marginBottom={"14px"}
                >
                  <Image
                    objectFit={"cover"}
                    w={"100%"}
                    h={"100%"}
                    filter="grayscale(80%)"
                    src="https://res.cloudinary.com/dlhjutjbp/image/upload/v1697889913/1655593392433_fgywwp.jpg"
                  />
                </Box>
                <Text>Imed Eddine kabouya</Text>
                <Text fontSize={"13px"}>Co-founder & Full-Stack udvikler</Text>
              </Box>

              <Box
                margin={{ base: "1.5rem", "2xl": "2.2rem" }}
                w={"15rem"}
                alignItems={"center"}
                as={"a"}
                href={"https://www.linkedin.com/in/kasper-holst-hansen/"}
                target={"blank"}
              >
                <Box
                  position="relative"
                  overflow={"hidden"}
                  w={{ base: 56, lg: 56 }}
                  h={{ base: 56, lg: 56 }}
                  borderRadius={"18px"}
                  marginBottom={"14px"}
                >
                  <Image
                    objectFit={"cover"}
                    w={"100%"}
                    h={"100%"}
                    filter="grayscale(80%)"
                    src="https://res.cloudinary.com/dlhjutjbp/image/upload/v1696432787/1601320057282_p6zcoy.jpg"
                  />
                </Box>
                <Text>Kasper Holst Hansen</Text>
                <Text fontSize={"13px"}>Partner & Forretningsudvikler</Text>
              </Box>

              <Box
                margin={{ base: "1.5rem", "2xl": "2.2rem" }}
                w={"15rem"}
                alignItems={"center"}
                as={"a"}
                href={"https://www.linkedin.com/in/tobias-truelsen-a953a1171/"}
                target={"blank"}
              >
                <Box
                  position="relative"
                  overflow={"hidden"}
                  w={{ base: 56, lg: 56 }}
                  h={{ base: 56, lg: 56 }}
                  borderRadius={"18px"}
                  marginBottom={"14px"}
                >
                  <Image
                    objectFit={"cover"}
                    w={"100%"}
                    h={"100%"}
                    filter="grayscale(80%)"
                    src="https://res.cloudinary.com/dlhjutjbp/image/upload/v1696432886/1648839668815_dpvsia.jpg"
                  />
                </Box>
                <Text>Tobias Truelsen</Text>
                <Text fontSize={"13px"}>Social Media Manager</Text>
              </Box>

              <Box
                margin={{ base: "1.5rem", "2xl": "2.2rem" }}
                w={"15rem"}
                alignItems={"center"}
                as={"a"}
                href={"https://www.linkedin.com/in/alexdz1994/"}
                target={"blank"}
              >
                <Box
                  position="relative"
                  overflow={"hidden"}
                  w={{ base: 56, lg: 56 }}
                  h={{ base: 56, lg: 56 }}
                  borderRadius={"18px"}
                  marginBottom={"14px"}
                >
                  <Image
                    objectFit={"cover"}
                    w={"100%"}
                    h={"100%"}
                    filter="grayscale(80%)"
                    src="https://res.cloudinary.com/da3vey0ch/image/upload/v1715610398/Untitled_bs7kiu.jpg"
                  />
                </Box>
                <Text>Skander Hamdi</Text>
                <Text fontSize={"13px"}>Mobile & AI udvikler</Text>
              </Box>

              <Box
                margin={{ base: "1.5rem", "2xl": "2.2rem" }}
                w={"15rem"}
                alignItems={"center"}
                as={"a"}
                href={"https://www.linkedin.com/in/oss-messaoudi/"}
                target={"blank"}
              >
                <Box
                  position="relative"
                  overflow={"hidden"}
                  w={{ base: 56, lg: 56 }}
                  h={{ base: 56, lg: 56 }}
                  borderRadius={"18px"}
                  marginBottom={"14px"}
                >
                  <Image
                    objectFit={"cover"}
                    w={"100%"}
                    h={"100%"}
                    filter="grayscale(80%)"
                    src="https://res.cloudinary.com/dlhjutjbp/image/upload/v1696432950/1619831875707_oyi8mu.jpg"
                  />
                </Box>
                <Text>Oussama Messaoudi</Text>
                <Text fontSize={"13px"}>Mobile & AI udvikler</Text>
              </Box>

              <Box
                margin={{ base: "1.5rem", "2xl": "2.2rem" }}
                w={"15rem"}
                alignItems={"center"}
                as={"a"}
                href={"https://www.linkedin.com/in/nidhaleddine-chenni/"}
                target={"blank"}
              >
                <Box
                  position="relative"
                  overflow={"hidden"}
                  w={{ base: 56, lg: 56 }}
                  h={{ base: 56, lg: 56 }}
                  borderRadius={"18px"}
                  marginBottom={"14px"}
                >
                  <Image
                    objectFit={"cover"}
                    w={"100%"}
                    h={"100%"}
                    filter="grayscale(80%)"
                    src="https://res.cloudinary.com/dlhjutjbp/image/upload/v1696433009/1695796385052_eaxdlf.jpg"
                  />
                </Box>
                <Text>Nidhal Eddine Chenni</Text>
                <Text fontSize={"13px"}>Softwareudvikler</Text>
              </Box>

              <Box
                margin={{ base: "1.5rem", "2xl": "2.2rem" }}
                w={"15rem"}
                alignItems={"center"}
                as={"a"}
                href={
                  "https://www.linkedin.com/in/khadidja-moghrani-996470181/"
                }
                target={"blank"}
              >
                <Box
                  position="relative"
                  overflow={"hidden"}
                  w={{ base: 56, lg: 56 }}
                  h={{ base: 56, lg: 56 }}
                  borderRadius={"18px"}
                  marginBottom={"14px"}
                >
                  <Image
                    objectFit={"cover"}
                    w={"100%"}
                    h={"100%"}
                    filter="grayscale(80%)"
                    src="https://res.cloudinary.com/da3vey0ch/image/upload/v1724420178/female-placeholder_thtiqe.png"
                  />
                </Box>
                <Text>Khadidja Moghran</Text>
                <Text fontSize={"13px"}>UX/UI designer</Text>
              </Box>

              <Box
                margin={{ base: "1.5rem", "2xl": "2.2rem" }}
                w={"15rem"}
                alignItems={"center"}
                as={"a"}
                href={
                  "https://www.linkedin.com/in/maria-bouyahiaoui-ab62451b5/"
                }
                target={"blank"}
              >
                <Box
                  position="relative"
                  overflow={"hidden"}
                  w={{ base: 56, lg: 56 }}
                  h={{ base: 56, lg: 56 }}
                  borderRadius={"18px"}
                  marginBottom={"14px"}
                >
                  <Image
                    objectFit={"cover"}
                    w={"100%"}
                    h={"100%"}
                    filter="grayscale(80%)"
                    src="https://res.cloudinary.com/da3vey0ch/image/upload/v1724420178/female-placeholder_thtiqe.png"
                  />
                </Box>
                <Text>Maria Bouyahiaoui</Text>
                <Text fontSize={"13px"}>Grafisk designer</Text>
              </Box>
            </Flex>
          </Box>

          <Box>
            <Text
              w={"fit-content"}
              margin={"auto"}
              fontSize={"3rem"}
              fontWeight={"500"}
              marginTop={"8rem"}
            >
              Advisory Board.
            </Text>
            <Flex
              flexWrap={"wrap"}
              w={{ base: "fit-content", xl: "1260px" }}
              justifyContent={"center"}
              margin={"auto"}
            >
              <Box
                margin={{ base: "1.5rem", "2xl": "2.2rem" }}
                w={"15rem"}
                alignItems={"center"}
                as={"a"}
                href={"https://www.linkedin.com/in/silkegrane/"}
                target={"blank"}
              >
                <Box
                  position="relative"
                  overflow={"hidden"}
                  w={{ base: 56, lg: 56 }}
                  h={{ base: 56, lg: 56 }}
                  borderRadius={"18px"}
                  marginBottom={"14px"}
                >
                  <Image
                    objectFit={"cover"}
                    w={"100%"}
                    h={"100%"}
                    filter="grayscale(80%)"
                    src="https://res.cloudinary.com/da3vey0ch/image/upload/v1715610283/1714396268128_t2usw6.jpg"
                  />
                </Box>
                <Text>Silke Grane</Text>
                <Text fontSize={"13px"}>Beauty profil og TV kendis</Text>
              </Box>

              <Box
                margin={{ base: "1.5rem", "2xl": "2.2rem" }}
                w={"15rem"}
                alignItems={"center"}
                as={"a"}
                href={"https://www.linkedin.com/in/giampiero-marino/"}
                target={"blank"}
              >
                <Box
                  position="relative"
                  overflow={"hidden"}
                  w={{ base: 56, lg: 56 }}
                  h={{ base: 56, lg: 56 }}
                  borderRadius={"18px"}
                  marginBottom={"14px"}
                >
                  <Image
                    objectFit={"cover"}
                    w={"100%"}
                    h={"100%"}
                    filter="grayscale(80%)"
                    src="https://res.cloudinary.com/da3vey0ch/image/upload/v1715610186/1663591512645_a9j8ds.jpg"
                  />
                </Box>
                <Text>Giampiero Marinò</Text>
                <Text fontSize={"13px"}>Ex CEO Treatwell</Text>
              </Box>

              <Box
                margin={{ base: "1.5rem", "2xl": "2.2rem" }}
                w={"15rem"}
                alignItems={"center"}
                as={"a"}
                href={"https://www.linkedin.com/in/christianbeck/"}
                target={"blank"}
              >
                <Box
                  position="relative"
                  overflow={"hidden"}
                  w={{ base: 56, lg: 56 }}
                  h={{ base: 56, lg: 56 }}
                  borderRadius={"18px"}
                  marginBottom={"14px"}
                >
                  <Image
                    objectFit={"cover"}
                    w={"100%"}
                    h={"100%"}
                    filter="grayscale(80%)"
                    src="https://res.cloudinary.com/da3vey0ch/image/upload/v1715610002/1698316969354_vtjtyu.jpg"
                  />
                </Box>
                <Text>Christian K.Beck</Text>
                <Text fontSize={"13px"}>Director Trend Micro</Text>
              </Box>
            </Flex>
          </Box>

          <Box alignSelf={"center"}>
            <Text
              w={"fit-content"}
              margin={"auto"}
              fontSize={"3rem"}
              fontWeight={"500"}
              marginTop={"8rem"}
              textAlign={{ base: "center" }}
            >
              Samarbejde.
            </Text>
            <Flex
              flexWrap={"wrap"}
              w={{ base: "fit-content", xl: "1260px" }}
              justifyContent={"center"}
              margin={"auto"}
            >
              <Flex
                margin={{ base: "1.5rem", "2xl": "2.2rem" }}
                w={"fit-content"}
                alignItems={"center"}
                justifyContent={"center"}
                flexDirection={"column"}
                gap={"8px"}
              >
                <Box
                  position="relative"
                  overflow={"hidden"}
                  w={{ base: 56, lg: 56 }}
                  h={{ base: 56, lg: 56 }}
                  borderRadius={"18px"}
                  marginBottom={"14px"}
                >
                  <Image
                    objectFit={"cover"}
                    w={"100%"}
                    h={"100%"}
                    filter="grayscale(80%)"
                    src="https://res.cloudinary.com/dlhjutjbp/image/upload/v1696433093/1965c9_a6da6513cf8f416196139b1e971aecb3_mv2_zspb1u.webp"
                  />
                </Box>
                <Text textAlign={"center"} fontSize={"20px"} fontWeight={"500"}>
                  Zac Celinder
                </Text>
                <Text textAlign={"center"} fontSize={"14px"}>
                  Musiker / Producer / Sangskriver
                </Text>
                <Text textAlign={"center"} width={"75%"}>
                  Zac Celinder (født Zacharias William Celinder) er én af
                  Danmarks skjulte kæmpetalenter. Udover at have spillet med
                  artister som Burhan G, Julias Moon, Noah, Cisilia, Jørgen
                  Klubien og Larry Graham, producerer Zac også sine egne sange.
                  Trods sin unge alder er han allerede en eftertragtet
                  studiemusiker og sangskriver, der blandt andet har arbejdet
                  med store internationale navne som MonoNeon.
                </Text>
                <Stack direction={"row"} spacing={6} marginTop={"5px"}>
                  <Link
                    href="https://www.linkedin.com/in/zac-celinder-6588351a/"
                    target="blank"
                  >
                    <Icon as={FaLinkedin} w={"6"} h={"6"} />
                  </Link>
                  <Link href="https://www.zaccelinder.com/ " target="blank">
                    <Icon as={FaGlobe} w={"6"} h={"6"} />
                  </Link>
                  <Link
                    href="https://www.youtube.com/channel/UC6m_nDsfnoQZA5oZIVnQ5xQ"
                    target="blank"
                  >
                    <Icon as={FaYoutube} w={"6"} h={"6"} />
                  </Link>
                  <Link
                    href="https://wontune.com/da/artists/zac-celinder/"
                    target="blank"
                  >
                    <Icon as={FaItunes} w={"6"} h={"6"} />
                  </Link>
                </Stack>
              </Flex>
            </Flex>
          </Box>
        </Box>

        <MarketplaceFooter />
      </div>
    </>
  );
}

export default AboutUs;
