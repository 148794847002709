import React from "react";
import "./MarketplaceSecurity.css";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  useToast,
} from "@chakra-ui/react";
import MarketplaceNavbar from "./MarketplaceNavbar.tsx";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import MarketplaceFooter from "./MarketplaceFooter.tsx";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import axios from "axios";

function MarketplaceSecurity() {
  const [isLoading, setisLoading] = useState(false);

  const navigate = useNavigate();

  const toast = useToast();

  const [oldshow, setoldshow] = useState(false);

  const [newshow, setnewshow] = useState(false);

  const [repeatshow, setrepeatshow] = useState(false);

  const [inputs, setInputs] = useState({
    old_password: "",
    new_password: "",
    repeat_password: "",
  });

  const handlechange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handle_update = async (e) => {
    e.preventDefault();
    setisLoading(true);
    const Data = {
      old_password: inputs.old_password,
      new_password: inputs.new_password,
    };
    try {
      if (
        inputs.old_password === "" ||
        inputs.new_password === "" ||
        inputs.repeat_password === ""
      ) {
        toast({
          title: "Skriv fejl",
          description: "Alle inputfelter er obligatoriske",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
        setisLoading(false);
      } else {
        if (inputs.new_password !== inputs.repeat_password) {
          toast({
            title: "Skriv fejl",
            description: "Gentag kodeord felt er forkert",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
          setisLoading(false);
        } else {
          const res = await axios.put(`/client/web/profile/reset/`, Data);

          if (res.status === 200) {
            navigate("/");
            setisLoading(false);
          }
        }
      }
    } catch (err) {
      if (err.response.status === 404) {
        toast({
          title: "Login fejl",
          description: err.response.data.message,
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      } else if (err.response.status === 500) {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et øjeblik",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      setisLoading(false);
    }
  };

  return (
    <>
      <div className="security">
        <Box position="sticky" top={0} zIndex={3}>
          <MarketplaceNavbar />
        </Box>
        <div className="profileContainer">
          <div className="previousContainer">
            <div className="Previous">
              <IconButton
                className="previousBtn"
                icon={<ChevronLeftIcon className="previousIcon" />}
                onClick={() => {
                  navigate(-1);
                }}
              />
            </div>
            <div className="Header">Opdater dit kodeord</div>
          </div>
          <FormControl>
            <FormLabel>Gammelt kodeord</FormLabel>
            <InputGroup>
              <Input
                type={oldshow ? "text" : "password"}
                variant="filled"
                size="lg"
                name="old_password"
                onChange={handlechange}
              />
              <InputRightElement width="4.5rem">
                <Button
                  h="1.75rem"
                  size="sm"
                  className="ShowHide"
                  onClick={() => setoldshow(!oldshow)}
                >
                  <Icon
                    as={oldshow ? AiFillEye : AiFillEyeInvisible}
                    h={5}
                    w={5}
                  />
                </Button>
              </InputRightElement>
            </InputGroup>
            <FormHelperText>
              Hvis du har glemt dit kodeord, bedes du kontakte vores support
            </FormHelperText>
          </FormControl>
          <FormControl>
            <FormLabel>Nyt kodeord</FormLabel>
            <InputGroup>
              <Input
                type={newshow ? "text" : "password"}
                variant="filled"
                size="lg"
                name="new_password"
                onChange={handlechange}
              />
              <InputRightElement width="4.5rem">
                <Button
                  h="1.75rem"
                  size="sm"
                  className="ShowHide"
                  onClick={() => setnewshow(!newshow)}
                >
                  <Icon
                    as={newshow ? AiFillEye : AiFillEyeInvisible}
                    h={5}
                    w={5}
                  />
                </Button>
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <FormControl>
            <FormLabel>Gentag nyt kodeord</FormLabel>
            <InputGroup>
              <Input
                type={repeatshow ? "text" : "password"}
                variant="filled"
                size="lg"
                name="repeat_password"
                onChange={handlechange}
              />
              <InputRightElement width="4.5rem">
                <Button
                  h="1.75rem"
                  size="sm"
                  className="ShowHide"
                  onClick={() => setrepeatshow(!repeatshow)}
                >
                  <Icon
                    as={repeatshow ? AiFillEye : AiFillEyeInvisible}
                    h={5}
                    w={5}
                  />
                </Button>
              </InputRightElement>
            </InputGroup>
          </FormControl>

          {isLoading ? (
            <Button
              className="submitBtn"
              size="lg"
              isLoading
              disabled
              loadingText="Indlæser"
            >
              Konfirmere
            </Button>
          ) : (
            <Button
              className="submitBtn"
              size="lg"
              onClick={handle_update}
              style={{ transition: "all 300ms ease-in-out" }}
              _hover={{
                transform: "scale(1.03)",
              }}
            >
              Bekræft og godkendt
            </Button>
          )}
        </div>
        <MarketplaceFooter />
      </div>
    </>
  );
}

export default MarketplaceSecurity;
