import React from "react";
import "./Datahandeling.css";
import MarketplaceNavbar from "../components/MarketplaceNavbar.tsx";
import MarketplaceFooter from "../components/MarketplaceFooter.tsx";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Box } from "@chakra-ui/react";

function Datahandeling() {
  // roll page to top - On page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Bookinghero - Vores politik for håndtering af data</title>
        <meta
          name="description"
          content="Hos Bookinghero tager vi din datahåndtering alvorligt. Læs vores retningslinjer for at se, hvordan vi beskytter dine oplysninger."
        />
        <link rel="canonical" href="/datahandeling" />
      </Helmet>
      <div className="Datahandeling">
        <Box position="sticky" top={0} zIndex={3}>
          <MarketplaceNavbar />
        </Box>
        <div className="Content">
          <div className="Main_header">Databehandling</div>
          <div className="Subheader">Sidst opdateret den 19. Februar 2023</div>
          <div className="Header">Databehandlingsaftale</div>
          <div className="Text">
            Denne aftale fastsætter de rettigheder og forpligtelser, som finder
            anvendelse, når databehandleren foretager behandling af
            personoplysninger på vegne af den dataansvarlige.
            <br />
            <br />
            Aftalen er udformet med henblik på parternes efterlevelse af artikel
            28, stk. 3, i Europa-Parlamentets og Rådets forordning (EU) 2016/679
            af 27. april 2016 om beskyttelse af fysiske personer i forbindelse
            med behandling af personoplysninger og om fri udveksling af sådanne
            oplysninger og om ophævelse af direktiv 95/46/EF
            (Databeskyttelsesforordningen), som stiller specifikke krav til
            indholdet af en databehandleraftale.
            <br />
            <br />
            Databehandlerens behandling af personoplysninger sker med henblik på
            opfyldelse af parternes ”hovedaftale” som bruger af bookinghero.dk,
            som er indgået ved bruger-oprettelsesdatoen.
            <br />
            <br />
            Databehandleraftalen og ”hovedaftalen” er indbyrdes afhængige, og
            kan ikke opsiges særskilt. Databehandleraftalen kan dog – uden at
            opsige ”hovedaftalen” – erstattes af en anden gyldig
            databehandleraftale.
            <br />
            <br />
            Denne databehandleraftale har forrang i forhold til eventuelle
            tilsvarende bestemmelser i andre aftaler mellem parterne, herunder i
            ”hovedaftalen”.
            <br />
            <br />
            Til denne aftale hører fire bilag. Bilagene fungerer som en
            integreret del af databehandleraftalen.
            <br />
            <br />
            Databehandleraftalens Bilag A indeholder nærmere oplysninger om
            behandlingen, herunder om behandlingens formål og karakter, typen af
            personoplysninger, kategorierne af registrerede og varighed af
            behandlingen.
            <br />
            <br />
            Databehandleraftalens Bilag B indeholder den dataansvarliges
            betingelser for, at databehandleren kan gøre brug af eventuelle
            underdatabehandlere, samt en liste over de eventuelle
            underdatabehandlere, som den dataansvarlige har godkendt.
            <br />
            <br />
            Databehandleraftalens Bilag C indeholder en nærmere instruks om,
            hvilken behandling databehandleren skal foretage på vegne af den
            dataansvarlige (behandlingens genstand), hvilke
            sikkerhedsforanstaltninger, der som minimum skal iagttages, samt
            hvordan der føres tilsyn med databehandleren og eventuelle
            underdatabehandlere.
            <br />
            <br />
            Databehandleraftalens Bilag D indeholder parternes eventuelle
            regulering af forhold, som ikke ellers fremgår af
            databehandleraftalen eller parternes ”hovedaftale”.
            <br />
            <br />
            Databehandleraftalen med tilhørende bilag opbevares skriftligt,
            herunder elektronisk af begge parter.
            <br />
            <br />
            Denne databehandleraftale frigør ikke databehandleren for
            forpligtelser, som efter databeskyttelsesforordningen eller enhver
            anden lovgivning direkte er pålagt databehandleren.
          </div>
          <div className="Header">
            Den dataansvarliges forpligtelser og rettigheder
          </div>
          <div className="Text">
            Den dataansvarlige har overfor omverdenen (herunder den
            registrerede) som udgangspunkt ansvaret for, at behandlingen af
            personoplysninger sker indenfor rammerne af
            databeskyttelsesforordningen og databeskyttelsesloven.
            <br />
            <br />
            Den dataansvarlige har derfor både rettighederne og forpligtelserne
            til at træffe beslutninger om, til hvilke formål og med hvilke
            hjælpemidler der må foretages behandling.
            <br />
            <br />
            Den dataansvarlige er blandt andet ansvarlig for, at der foreligger
            hjemmel til den behandling, som databehandleren instrueres i at
            foretage.
          </div>
          <div className="Header">Databehandleren handler efter instruks</div>
          <div className="Text">
            Databehandleren må kun behandle personoplysninger efter dokumenteret
            instruks fra den dataansvarlige, medmindre det kræves i henhold til
            EU-ret eller medlemsstaternes nationale ret, som databehandleren er
            underlagt; i så fald underretter databehandleren den dataansvarlige
            om dette retlige krav inden behandling, medmindre den pågældende ret
            forbyder en sådan underretning af hensyn til vigtige samfundsmæssige
            interesser, jf. art 28, stk. 3, litra a.
            <br />
            <br />
            Databehandleren underretter omgående den dataansvarlige, hvis en
            instruks efter databehandlerens mening er i strid med
            databeskyttelsesforordningen eller databeskyttelsesbestemmelser i
            anden EU-ret eller medlemsstaternes nationale ret.
          </div>
          <div className="Header">Fortrolighed</div>
          <div className="Text">
            Databehandleren sikrer, at kun de personer, der aktuelt er
            autoriseret hertil, har adgang til de personoplysninger, der
            behandles på vegne af den dataansvarlige. Adgangen til oplysningerne
            skal derfor straks lukkes ned, hvis autorisationen fratages eller
            udløber.
            <br />
            <br />
            Der må alene autoriseres personer, for hvem det er nødvendigt at
            have adgang til personoplysningerne for at kunne opfylde
            databehandlerens forpligtelser overfor den dataansvarlige.
            <br />
            <br />
            Databehandleren sikrer, at de personer, der er autoriseret til at
            behandle personoplysninger på vegne af den dataansvarlige, har
            forpligtet sig til fortrolighed eller er underlagt en passende
            lovbestemt tavshedspligt.
            <br />
            <br />
            Databehandleren skal efter anmodning fra den dataansvarlige kunne
            påvise, at de relevante medarbejdere er underlagt ovennævnte
            tavshedspligt.
          </div>
          <div className="Header">Behandlingssikkerhed</div>
          <div className="Text">
            Databehandleren iværksætter alle foranstaltninger, som kræves i
            henhold til databeskyttelsesforordningens artikel 32, hvoraf det
            bl.a. fremgår, at der under hensyntagen til det aktuelle niveau,
            implementeringsomkostningerne og den pågældende behandlings
            karakter, omfang, sammenhæng og formål samt risiciene af varierende
            sandsynlighed og alvor for fysiske personers rettigheder og
            frihedsrettigheder skal gennemføres passende tekniske og
            organisatoriske foranstaltninger for at sikre et sikkerhedsniveau,
            der passer til disse risici.
            <br />
            <br />
            Ovenstående forpligtelse indebærer, at databehandleren skal foretage
            en risikovurdering, og herefter gennemføre foranstaltninger for at
            imødegå identificerede risici. Der kan herunder bl.a., alt efter
            hvad der er relevant, være tale om følgende foranstaltninger.
          </div>
          <div className="Header">
            Pseudonymisering og kryptering af personoplysninger
          </div>
          <div className="Text">
            Evne til at sikre vedvarende fortrolighed, integritet,
            tilgængelighed og robusthed af behandlingssystemer og – tjenester
            <br />
            <br />
            Evne til rettidigt at genoprette tilgængeligheden af og adgangen til
            personoplysninger i tilfælde af en fysisk eller teknisk hændelse
            <br />
            <br />
            En procedure for regelmæssig afprøvning, vurdering og evaluering af
            effektiviteten af de tekniske og organisatoriske foranstaltninger
            til sikring af behandlingssikkerhed
            <br />
            <br />
            Databehandleren skal i forbindelse med ovenstående – i alle tilfælde
            – som minimum iværksætte det sikkerhedsniveau og de
            foranstaltninger, som er specificeret nærmere i denne aftales Bilag
            C.
            <br />
            <br />
            Parternes eventuelle regulering/aftale om vederlæggelse eller lign.
            i forbindelse med den dataansvarliges eller databehandlerens
            efterfølgende krav om etablering af yderligere
            sikkerhedsforanstaltninger vil fremgå af parternes ”hovedaftale”
            eller af denne aftales bilag D.
          </div>
          <div className="Header">Anvendelse af underdatabehandlere</div>
          <div className="Text">
            Databehandleren skal opfylde de betingelser, der er omhandlet i
            databeskyttelsesforordningens artikel 28, stk. 2 og 4, for at gøre
            brug af en anden databehandler (underdatabehandler).
            <br />
            <br />
            Databehandleren må således ikke gøre brug af en anden databehandler
            (underdatabehandler) til opfyldelse af databehandleraftalen uden
            forudgående specifik eller generel skriftlig godkendelse fra den
            dataansvarlige.
            <br />
            <br />I tilfælde af generel skriftlig godkendelse skal
            databehandleren underrette den dataansvarlige om eventuelle
            planlagte ændringer vedrørende tilføjelse eller erstatning af andre
            databehandlere og derved give den dataansvarlige mulighed for at
            gøre indsigelse mod sådanne ændringer.
            <br />
            <br />
            Den dataansvarliges nærmere betingelser for databehandlerens brug af
            eventuelle underdatabehandlere fremgår af denne aftales Bilag B.
            <br />
            <br />
            Den dataansvarliges eventuelle godkendelse af specifikke
            underdatabehandlere er anført i denne aftales Bilag B.
            <br />
            <br />
            Når databehandleren har den dataansvarliges godkendelse til at gøre
            brug af en underdatabehandler, sørger databehandleren for at pålægge
            underdatabehandleren de samme databeskyttelsesforpligtelser som dem,
            der er fastsat i denne databehandleraftale, gennem en kontrakt eller
            andet retligt dokument i henhold til EU-retten eller
            medlemsstaternes nationale ret, hvorved der navnlig stilles de
            fornødne garantier for, at underdatabehandleren vil gennemføre de
            passende tekniske og organisatoriske foranstaltninger på en sådan
            måde, at behandlingen opfylder kravene i
            databeskyttelsesforordningen.
            <br />
            <br />
            Databehandleren er således ansvarlig for – igennem indgåelsen af en
            underdatabehandleraftale – at pålægge en eventuel underdatabehandler
            mindst de forpligtelser, som databehandleren selv er underlagt efter
            databeskyttelsesreglerne og denne databehandleraftale med tilhørende
            bilag.
            <br />
            <br />
            Underdatabehandleraftalen og eventuelle senere ændringer hertil
            sendes – efter den dataansvarliges anmodning herom - i kopi til den
            dataansvarlige, som herigennem har mulighed for at sikre sig, at der
            er indgået en gyldig aftale mellem databehandleren og
            underdatabehandleren. Eventuelle kommercielle vilkår, eksempelvis
            priser, som ikke påvirker det databeskyttelsesretlige indhold af
            underdatabehandleraftalen, skal ikke sendes til den dataansvarlige.
            <br />
            <br />
            Databehandleren skal i sin aftale med underdatabehandleren indføje
            den dataansvarlige som begunstiget tredjemand i tilfælde af
            databehandlerens konkurs, således at den dataansvarlige kan indtræde
            i databehandlerens rettigheder og gøre dem gældende over for
            underdatabehandleren, f.eks. så den dataansvarlige kan instruere
            underdatabehandleren om at foretage sletning eller tilbagelevering
            af oplysninger.
            <br />
            <br />
            Hvis underdatabehandleren ikke opfylder sine
            databeskyttelsesforpligtelser, forbliver databehandleren fuldt
            ansvarlig over for den dataansvarlige for opfyldelsen af
            underdatabehandlerens forpligtelser.
          </div>
          <div className="Header">
            Overførsel af oplysninger til tredjelande eller internationale
            organisationer
          </div>
          <div className="Text">
            Databehandleren må kun behandle personoplysninger efter dokumenteret
            instruks fra den dataansvarlige, herunder for så vidt angår
            overførsel (overladelse, videregivelse samt intern anvendelse) af
            personoplysninger til tredjelande eller internationale
            organisationer, medmindre det kræves i henhold til EU-ret eller
            medlemsstaternes nationale ret, som databehandleren er underlagt; i
            så fald underretter databehandleren den dataansvarlige om dette
            retlige krav inden behandling, medmindre den pågældende ret forbyder
            en sådan underretning af hensyn til vigtige samfundsmæssige
            interesser, jf. art 28, stk. 3, litra a.
            <br />
            <br />
            Uden den dataansvarliges instruks eller godkendelse kan
            databehandleren – indenfor rammerne af databehandleraftalen - derfor
            bl.a. ikke;
            <br />
            <br />
            - videregive personoplysningerne til en dataansvarlig i et
            tredjeland eller i en international organisation,
            <br />
            <br />
            - overlade behandlingen af personoplysninger til en
            underdatabehandler i et tredjeland,
            <br />
            <br />- lade oplysningerne behandle i en anden af databehandlerens
            afdelinger, som er placeret i et tredjeland.
            <br />
            <br />
            Den dataansvarliges eventuelle instruks eller godkendelse af, at der
            foretages overførsel af personoplysninger til et tredjeland, vil
            fremgå af denne aftales Bilag C.
          </div>
          <div className="Header">Bistand til den dataansvarlige</div>
          <div className="Text">
            Databehandleren bistår, under hensyntagen til behandlingens
            karakter, så vidt muligt den dataansvarlige ved hjælp af passende
            tekniske og organisatoriske foranstaltninger, med opfyldelse af den
            dataansvarliges forpligtelse til at besvare anmodninger om udøvelsen
            af de registreredes rettigheder som fastlagt i
            databeskyttelsesforordningens kapitel 3.
            <br />
            <br />
            Dette indebærer, at databehandleren så vidt muligt skal bistå den
            dataansvarlige i forbindelse med, at den dataansvarlige skal sikre
            overholdelsen af:
            <br />
            <br />
            1. oplysningspligten ved indsamling af personoplysninger hos den
            registrerede
            <br />
            <br />
            2. oplysningspligten, hvis personoplysninger ikke er indsamlet hos
            den registrerede
            <br />
            <br />
            3. den registreredes indsigtsret <br />
            <br />
            4. retten til berigtigelse <br />
            <br />
            5. retten til sletning (»retten til at blive glemt«) <br />
            <br />
            6. retten til begrænsning af behandling <br />
            <br />
            7. underretningspligt i forbindelse med berigtigelse eller sletning
            af personoplysninger eller begrænsning af behandling
            <br />
            <br />
            8. retten til dataportabilitet
            <br />
            <br />
            9. retten til indsigelse
            <br />
            <br />
            10. retten til at gøre indsigelse mod resultatet af automatiske
            individuelle afgørelser, herunder profilering
            <br />
            <br />
            Databehandleren bistår den dataansvarlige med at sikre overholdelse
            af den dataansvarliges forpligtelser i medfør af
            databeskyttelsesforordningens artikel 32-36 under hensynstagen til
            behandlingens karakter og de oplysninger, der er tilgængelige for
            databehandleren, jf. art 28, stk. 3, litra f.
            <br />
            <br />
            Dette indebærer, at databehandleren under hensynstagen til
            behandlingens karakter skal bistå den dataansvarlige i forbindelse
            med, at den dataansvarlige skal sikre overholdelsen af:
            <br />
            <br />
            1. forpligtelsen til at gennemføre passende tekniske og
            organisatoriske foranstaltninger for at sikre et sikkerhedsniveau,
            der passer til de risici, der er forbundet med behandlingen
            <br />
            <br />
            2. forpligtelsen til at anmelde brud på persondatasikkerheden til
            tilsynsmyndigheden (Datatilsynet) uden unødig forsinkelse og om
            muligt senest 72 timer, efter at den dataansvarlige er blevet
            bekendt med bruddet, medmindre at det er usandsynligt, at bruddet på
            persondatasikkerheden indebærer en risiko for fysiske personers
            rettigheder eller frihedsrettigheder.
            <br />
            <br />
            3. forpligtelsen til – uden unødig forsinkelse – at underrette
            den/de registrerede om brud på persondatasikkerheden, når et sådant
            brud sandsynligvis vil indebære en høj risiko for fysiske personers
            rettigheder og frihedsrettigheder
            <br />
            <br />
            4. forpligtelsen til at gennemføre en konsekvensanalyse vedrørende
            databeskyttelse, hvis en type behandling sandsynligvis vil indebære
            en høj risiko for fysiske personers rettigheder og
            frihedsrettigheder
            <br />
            <br />
            5. forpligtelsen til at høre tilsynsmyndigheden (Datatilsynet) inden
            behandling, såfremt en konsekvensanalyse vedrørende databeskyttelse
            viser, at behandlingen vil føre til høj risiko i mangel af
            foranstaltninger truffet af den dataansvarlige for at begrænse
            risikoen
            <br />
            <br />
            6. Parternes eventuelle regulering/aftale om vederlæggelse eller
            lignende i forbindelse med databehandlerens bistand til den
            dataansvarlige vil fremgå af parternes ”hovedaftale” eller af denne
            aftales bilag D.
          </div>
          <div className="Header">
            Underretning om brud på persondatasikkerheden
          </div>
          <div className="Text">
            Databehandleren underretter uden unødig forsinkelse den
            dataansvarlige efter at være blevet opmærksom på, at der er sket
            brud på persondatasikkerheden hos databehandleren eller en eventuel
            underdatabehandler.
            <br />
            <br />
            Databehandlerens underretning til den dataansvarlige skal om muligt
            ske senest 24 timer efter at denne er blevet bekendt med bruddet,
            sådan at den dataansvarlige har mulighed for at efterleve sin
            eventuelle forpligtelse til at anmelde bruddet til
            tilsynsmyndigheden indenfor 72 timer.
            <br />
            <br />I overensstemmelse med denne aftales afsnit 10.2., litra b,
            skal databehandleren - under hensynstagen til behandlingens karakter
            og de oplysninger, der er tilgængelige for denne – bistå den
            dataansvarlige med at foretage anmeldelse af bruddet til
            tilsynsmyndigheden.
            <br />
            <br />
            Det kan betyde, at databehandleren bl.a. skal hjælpe med at
            tilvejebringe nedenstående oplysninger, som efter
            databeskyttelsesforordningens artikel 33, stk. 3, skal fremgå af den
            dataansvarliges anmeldelse til tilsynsmyndigheden:
            <br />
            <br />
            Karakteren af bruddet på persondatasikkerheden, herunder, hvis det
            er muligt, kategorierne og det omtrentlige antal berørte
            registrerede samt kategorierne og det omtrentlige antal berørte
            registreringer af personoplysninger.
            <br />
            <br />
            Sandsynlige konsekvenser af bruddet på persondatasikkerheden.
            <br />
            <br />
            Foranstaltninger, som er truffet eller foreslås truffet for at
            håndtere bruddet på persondatasikkerheden, herunder hvis det er
            relevant, foranstaltninger for at begrænse dets mulige
            skadevirkninger.
          </div>
          <div className="Header">
            Sletning og tilbagelevering af oplysninger
          </div>
          <div className="Text">
            Ved ophør af tjenesterne vedrørende behandling forpligtes
            databehandleren til, efter den dataansvarliges valg, at slette eller
            tilbagelevere alle personoplysninger til den dataansvarlige, samt at
            slette eksisterende kopier, medmindre EU-retten eller national ret
            foreskriver opbevaring af personoplysningerne.
          </div>
          <div className="Header">Tilsyn og revision</div>
          <div className="Text">
            Databehandleren stiller alle oplysninger, der er nødvendige for at
            påvise databehandlerens overholdelse af
            databeskyttelsesforordningens artikel 28 og denne aftale, til
            rådighed for den dataansvarlige og giver mulighed for og bidrager
            til revisioner, herunder inspektioner, der foretages af den
            dataansvarlige eller en anden revisor, som er bemyndiget af den
            dataansvarlige.
            <br />
            <br />
            Den nærmere procedure for den dataansvarliges tilsyn med
            databehandleren fremgår af denne aftales Bilag C.
            <br />
            <br />
            Den dataansvarliges tilsyn med eventuelle underdatabehandlere sker
            som udgangspunkt gennem databehandleren. Den nærmere procedure
            herfor fremgår af denne aftales Bilag C.
            <br />
            <br />
            Databehandleren er forpligtet til at give myndigheder, der efter den
            til enhver tid gældende lovgivning har adgang til den
            dataansvarliges og databehandlerens faciliteter, eller
            repræsentanter, der optræder på myndighedens vegne, adgang til
            databehandlerens fysiske faciliteter mod behørig legitimation.
          </div>
          <div className="Header">Parternes aftaler om andre forhold</div>
          <div className="Text">
            En eventuel (særlig) regulering af konsekvenserne af parternes
            misligholdelse af databehandleraftalen vil fremgår af parternes
            ”hovedaftale” eller af denne aftales Bilag D.
            <br />
            <br />
            En eventuel regulering af andre forhold mellem parterne vil fremgå
            af parternes ”hovedaftale” eller af denne aftales Bilag D.
          </div>
          <div className="Header">Ikrafttræden og ophør</div>
          <div className="Text">
            Denne aftale træder i kraft ved begge parters underskrift heraf.
            <br />
            <br />
            Aftalen kan af begge parter kræves genforhandlet, hvis lovændringer
            eller uhensigtsmæssigheder i aftalen giver anledning hertil.
            <br />
            <br />
            Parternes eventuelle regulering/aftale om vederlæggelse, betingelser
            eller lignende i forbindelse med ændringer af denne aftale vil
            fremgå af parternes ”hovedaftale” eller af denne aftales bilag D.
            <br />
            <br />
            Opsigelse af databehandleraftalen kan ske i henhold til de
            opsigelsesvilkår, inkl. opsigelsesvarsel, som fremgår af
            ”hovedaftalen”.
            <br />
            <br />
            Aftalen er gældende, så længe behandlingen består. Uanset
            ”hovedaftalens” og/eller databehandleraftalens opsigelse, vil
            databehandleraftalen forblive i kraft frem til behandlingens ophør
            og oplysningernes sletning hos databehandleren og eventuelle
            underdatabehandlere.
          </div>
        </div>
        <Box>
          <MarketplaceFooter />
        </Box>
      </div>
    </>
  );
}

export default Datahandeling;
