import React, { useState } from "react";
import BookingPageHeader from "../components/BookingPageHeader";
import { Box } from "@chakra-ui/react";
import BookingPageCrumbs from "../components/BookingPageCrumbs";
import BookingPageServices from "../components/BookingPageServices";
import { useLocation, useParams } from "react-router-dom";

function BookingPage() {
  const { idSaloon } = useParams();
  const location = useLocation();
  const [appointment, setAppointment] = useState(
    location.state && location.state.appointment
      ? location.state.appointment
      : {
          step: 1,
          create: true,
          personal: true,
          sms: true,
          SalonSlug: idSaloon,
          schedule: {
            day: "",
            dayInText: "",
            year: "",
            month: "",
            startTime: "",
            endTime: "",
          },
          treatment: {
            Price: "",
            Duration: "",
            Name: "",
            Type: -1,
            DurationFrom: "",
            PriceFrom: "",
            ID: -1,
          },
          employee: { Name: "", Speciality: "", Image: "", ID: -1 },
          client: {
            Name: "",
            Address: "",
            Phone: "",
            Email: "",
            Note: "",
            Complete: false,
          },
        }
  );

  const [disable, setDisable] = useState(true);

  return (
    <>
      <Box
        width={"100%"}
        minH={"100vh"}
        position={"relative"}
        paddingX={{ xl: "0", "1920px": "11%" }}
      >
        <Box
          width={"100%"}
          height={"fit-content"}
          position={"sticky"}
          zIndex={2}
          top={0}
        >
          <BookingPageHeader
            appointment={appointment}
            setAppointment={setAppointment}
          />
        </Box>
        <Box width={"100%"} height={"fit-content"} zIndex={1}>
          <BookingPageCrumbs
            appointment={appointment}
            setAppointment={setAppointment}
          />
        </Box>
        <Box width={"100%"} height={"fit-content"} zIndex={1}>
          <BookingPageServices
            appointment={appointment}
            setAppointment={setAppointment}
            disable={disable}
            setDisable={setDisable}
          />
        </Box>
      </Box>
    </>
  );
}

export default BookingPage;
